import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UnidadeMedida } from './models/unidadeMedida';
import { UnidadeMedidaFiltro } from './models/unidade-medida-filtro';
import { BENEFICIO_URI, BENEFICIO_ADDRESS } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { InserirUnidadeMedidaRequest } from './models/inserirUnidadeMedidaRequest';
import { ResponseData } from '../comum/models/responseData';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { AlterarUnidadeMedidaRequest } from './models/alterarUnidadeMedidaRequest';
import { UnidadeMedidaFiltroRequest } from './models/unidade-medida-filtro-request';

@Injectable({ providedIn: 'root'})
export class UnidadeMedidaService {

  constructor(private http: HttpClient, 
    private queryParamsService: QueryParamsService,
    @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
    @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

  public recuperarPorFiltro(filtro: UnidadeMedidaFiltroRequest) {
    return this.http.get<ResponseData<UnidadeMedida[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/unidades-medidas`, {params: this.queryParamsService.gerarQuery(filtro) }).toPromise();
  }

  public recuperarPorId(id: string) {
    return this.http.get<UnidadeMedida>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/unidades-medidas/${id}`).toPromise();
  }

  public incluir(unidadesMedidas: InserirUnidadeMedidaRequest) {
    return this.http.post<ResponseData<UnidadeMedida>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/unidades-medidas`, unidadesMedidas).toPromise();
  }

  public alterar(id: any, unidadesMedidas: AlterarUnidadeMedidaRequest) {
    return this.http.put<ResponseData<UnidadeMedida>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/unidades-medidas/${id}`, unidadesMedidas).toPromise();
  }

  public deletar(id: any) {
    return this.http.delete<any>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/unidades-medidas/${id}`).toPromise();
  }

  public listarAutoComplete(contem: string, empresaId: string) {
    return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/unidades-medidas/combobox`, {params: {conteudoPesquisa : contem  , empresaId: empresaId}}).toPromise();
  }
}
