<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
  <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon>badge</mat-icon>
    <span class="p6n-action-bar-title">Cadastrar categoria</span>
  </div>
</mat-toolbar>
<mat-dialog-content>

  <form [formGroup]="this.categoriaFormGroup">
    <div fxLayout="column" fxLayout.lt-sm="column" fxLayoutAlign="space-between strech">
      <mat-form-field appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput type="text" #nome formControlName="nome">
        <mat-hint fxFlexAlign="end">{{nome.value.length}} / 200</mat-hint>
        <mat-error *ngIf="this.categoriaFormGroup.controls.nome.hasError('required')">O nome é obrigatório</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Empresa</mat-label>
        <mat-select placeholder="Selecione a empresa" #empresa formControlName="empresa" id="empresa">
          <mat-option *ngFor="let empresa of this.empresas" [value]="empresa.id">
            {{ empresa.descricao }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="1rem">
  <button mat-button color="warn" [mat-dialog-close]="false" id="buttonCancelar">
      <span>Cancelar</span>
  </button>
  <button mat-button color="primary" [disabled]="this.categoriaFormGroup.invalid" (click)="this.salvar()" id="buttonSalvar">
      <span>Salvar</span>
  </button>
</mat-dialog-actions>
