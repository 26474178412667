import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProdutoService } from './produto.service';
import { MaterialModule } from '../material/material.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ProdutoListaComponent } from './produto-lista/produto-lista.component';
import { ProdutoAlterarModalComponent } from './produto-alterar-modal/produto-alterar-modal.component';
import { ProdutoRoutingModule } from './produto.routing';
import { ProdutoListaResolver } from './produto-lista/produto-lista.resolver';
import { ComponentesModule } from '../comum/componentes/componentes.module';
import { ProdutoFiltroModalComponent } from './produto-filtro-modal/produto-filtro-modal.component';
import { ProdutoIncluirModalComponent } from './produto-incluir-modal/produto-incluir-modal.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatInputModule,
        RouterModule,
        FormsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatOptionModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        ProdutoRoutingModule,
        ComponentesModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatOptionModule,
        CurrencyMaskModule
    ],
    declarations: [ProdutoListaComponent, ProdutoIncluirModalComponent, ProdutoAlterarModalComponent, ProdutoFiltroModalComponent],
    providers: [ProdutoService, ProdutoListaResolver],
    exports: [
        ProdutoListaComponent,
        ProdutoIncluirModalComponent,
        ProdutoAlterarModalComponent
    ]
})
export class ProdutoModule { }
