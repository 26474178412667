import {
    trigger,
    transition,
    style,
    query,
    group,
    animateChild,
    animate,
    keyframes,
    stagger
} from '@angular/animations';

export const fader =
    trigger('routeAnimations', [
        transition('* <=> *', [
            // Set a default  style for enter and leave
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    left: 0,
                    width: '100%',
                    opacity: 0,
                    transform: 'scale(0) translateY(100%)',
                }),
            ], { optional: true }),
            // Animate the new page in
            query(':enter', [
                animate('300ms ease', style({ opacity: 1, transform: 'scale(1) translateY(0)' })),
            ], { optional: true })
        ]),
    ]);



export const slider =
    trigger('routeAnimations', [
        //IDA
        transition('home => movements', slideRoute('right')),
        transition('home => pix-cashout', slideRoute('right')),
        transition('home => pix-qrcode', slideRoute('right')),
        transition('home => pix-keys', slideRoute('right')),
        transition('pix-cashout => pix-transfers', slideRoute('right')),
        transition('movements => pix-transfers', slideRoute('right')),
        transition('pix-transfers => pix-proofs', slideRoute('right')),
        transition('pix-qrcode => pix-transfers-payload', slideRoute('right')),        
        transition('pix-transfers-payload =>  pix-success', slideRoute('right')),
        transition('pix-payload => pix-transfers-payload', slideRoute('right')),

        
        //VOLTA
        transition('pix-cashout => home', slideRoute('left')),
        transition('movements => home', slideRoute('left')),
        transition('pix-qrcode => home', slideRoute('left')),
        transition('pix-keys => home', slideRoute('left')),
        transition('pix-transfers => pix-cashout', slideRoute('left')),
        transition('pix-transfers => movements', slideRoute('left')),
        transition('proofs => pix-transfers', slideRoute('left')),
        transition('pix-transfers-payload => pix-qrcode', slideRoute('left')),
        transition('pix-success => pix-transfers-payload', slideRoute('left')),
        transition('pix-transfers-payload => pix-payload', slideRoute('left')),

    ]);

function slideRoute(direction) {
    const optional = { optional: true };
    return [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                [direction]: 0,
                width: '100%'
            })
        ], optional),
        query(':enter', [
            style({ [direction]: '-100%' })
        ]),
        group([
            query(':leave', [
                animate('300ms ease', style({ [direction]: '100%' }))
            ], optional),
            query(':enter', [
                animate('300ms ease', style({ [direction]: '0%' }))
            ])
        ]),
        // Normalize the page style... Might not be necessary

        // Required only if you have child animations on the page
        // query(':leave', animateChild()),
        // query(':enter', animateChild()),
    ];
}


export const slideInBottom = trigger('slideInBottom', [
    transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease', style({ transform: 'translateY(0%)', opacity: '1' }))
    ]),
    transition(':leave', [
        style({ transform: 'translateY(0%)'  }),
        animate('300ms ease', style({ transform: 'translateY(100%)', opacity: '0' }))
    ])
]);

export const slideInTop = trigger('slideInTop', [
    transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease', style({ transform: 'translateY(0%)', opacity: '1' }))
    ])
]);