import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageService } from '../../comum/services/local-storage.service';
import { NOME_FILTRO_PRODUTO } from '../../app.config';
import { MovimentoConst } from '../../comum/consts/movimento.const';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { GrupoService } from 'src/app/grupo/grupo.service';
import { SubGrupoService } from 'src/app/sub-grupo/sub-grupo.service';
import { FabricanteService } from 'src/app/fabricante/fabricante.service';
import { UnidadeMedidaService } from 'src/app/unidade-medida/unidade-medida.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoriaService } from 'src/app/categoria/categoria.service';

@Component({
  selector: 'app-produto-filtro-modal',
  templateUrl: './produto-filtro-modal.component.html',
  styleUrls: ['./produto-filtro-modal.component.scss']
})
export class ProdutoFiltroModalComponent implements OnInit {


  filtroProduto: UntypedFormGroup;
  movimentoConst = MovimentoConst;
  empresas: ComboBoxItem[];
  categorias: ComboBoxItem[];
  grupos: ComboBoxItem[];
  subGrupos: ComboBoxItem[];
  unidadesMedidas: ComboBoxItem[];
  fabricantes: ComboBoxItem[];

  constructor(public dialogRef: MatDialogRef<ProdutoFiltroModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private empresaService: EmpresaService,
    private categoriaService: CategoriaService,
    private grupoService: GrupoService,
    private subGrupoService: SubGrupoService,
    private fabricanteService: FabricanteService,
    private unidadeMedidaService: UnidadeMedidaService,) {
    this.filtroProduto = this.formBuilder.group({
      nome: [data ? data.nome : null],
      codigo: [data ? data.codigo : null],
      referencia: [data ? data.referencia : null],
      categoria: [data ? data.categoria : null],
      grupo: [data ? data.grupo : null],
      subGrupo: [data ? data.subGrupo : null],
      fabricante: [data ? data.fabricante : null],
      unidadeMedida: [data ? data.unidadeMedida : null],
      ncm: [data ? data.ncm : null],
      empresa: [data ? data.empresa : null]
    });
  }

  ngOnInit() {
    this.empresaService.listarVinculados()
      .then
      (response => {
        this.empresas = response.data;
        this.filtroProduto.get("empresa").setValue(this.empresas.length == 1 ? this.empresas[0].id : null);
      });

    this.filtroProduto
      .get('categoria')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.filtroProduto.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.categoriaService.listarAutoComplete(value, this.filtroProduto.value.empresa)
          .then
          (response => {
            this.categorias = response.data;
          });
      });

    this.filtroProduto
      .get('grupo')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.filtroProduto.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.grupoService.listarAutoComplete(value, this.filtroProduto.value.empresa)
          .then
          (response => {
            this.grupos = response.data;
          });
      });

    this.filtroProduto
      .get('subGrupo')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.filtroProduto.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.subGrupoService.listarAutoComplete(value, this.filtroProduto.value.empresa)
          .then
          (response => {
            this.subGrupos = response.data;
          });
      });

    this.filtroProduto
      .get('fabricante')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.filtroProduto.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.fabricanteService.listarAutoComplete(value, this.filtroProduto.value.empresa)
          .subscribe
          (response => {
            this.fabricantes = response.data;
          });
      });

    this.filtroProduto
      .get('unidadeMedida')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.filtroProduto.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.unidadeMedidaService.listarAutoComplete(value, this.filtroProduto.value.empresa)
          .then
          (response => {
            this.unidadesMedidas = response.data;
          });
      });
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  salvar() {
    this.localStorageService.salvarItem(NOME_FILTRO_PRODUTO, this.filtroProduto.value);

    this.dialogRef.close(true);
  }

  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }

}
