<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
  <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon>filter_list</mat-icon>
    <span class="p6n-action-bar-title">Filtros</span>
  </div>
</mat-toolbar>
<form [formGroup]="this.filtroExtrato">
  <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-evenly stretch">
    
      <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFlex>
              <mat-label>Inicio</mat-label>
              <input type="text" matInput placeholder="Insira o inicio"
                  [matDatepicker]="iniciPicker" formControlName="inicio">
              <mat-datepicker-toggle matSuffix [for]="iniciPicker"></mat-datepicker-toggle>
              <mat-datepicker #iniciPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex>
              <mat-label>Fim</mat-label>
              <input type="text" matInput placeholder="Insira o fim"
                  [matDatepicker]="fimPicker" formControlName="fim">
              <mat-datepicker-toggle matSuffix [for]="fimPicker"></mat-datepicker-toggle>
              <mat-datepicker #fimPicker></mat-datepicker>
          </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <button mat-button (click)="this.cancelar()">
          Cancelar</button>
        <button mat-button color="primary" [disabled]="this.filtroExtrato.invalid" (click)="this.salvar()">
          Aplicar filtro</button>
      </div>
  </div>
  
</form>

  
