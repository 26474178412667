<div class="app flex-container mat-typography loading" fxLayout="column">
    <ng-template #emptyLoadingTemplate></ng-template>
          <ngx-loading
            [show]="loading"
            [config]="config"
            [template]="emptyLoadingTemplate"
          ></ngx-loading>
    <mat-toolbar  @slideInTop  color="primary" class="mat-elevation-z4" auto-hide *ngIf="this.isAuthenticated && (this.drawerMenuItemAtual != null && this.drawerMenuItemAtual.ocultarToolbar == false)">
        <mat-toolbar-row fxLayout="row" fxFlex fxLayoutAlign="space-between center">
            <div fxLayoutAlign="start center" fxFlex fxLayoutGap="20px">
                <a *ngIf="this.drawerMenuItemAtual != null && this.drawerMenuItemAtual.rotaBackdrop != null" matTooltip="Voltar para listagem" mat-icon-button [routerLink]="this.drawerMenuItemAtual.rotaBackdrop" [queryParams]="this.drawerMenuItemAtual.queryParams">
                    <mat-icon>arrow_back_ios_new</mat-icon>
                  </a>
                <span *ngIf="this.drawerMenuItemAtual != null"> {{this.drawerMenuItemAtual.descricao}} </span>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-menu #profile="matMenu">
        <button mat-menu-item fxFlex *ngIf="this.userProfile != null" routerLink="/profile">
            <mat-icon>account_circle</mat-icon>
            <span>{{this.userProfile.nome}}</span>
        </button>
        <button mat-menu-item (click)="alterarSenha()" fxFlex>
            <mat-icon>vpn_key</mat-icon>
            <span>Alterar senha</span>
        </button>
        <button mat-menu-item (click)="logout()" fxFlex>
            <mat-icon>exit_to_app</mat-icon>
            <span>Sair</span>
        </button>
    </mat-menu>
    <mat-sidenav-container autosize>
        <mat-sidenav-content fxFlex="auto" class="main-content"  [@routeAnimations]="prepareRoute(outlet)">
            <router-outlet #outlet="outlet"></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <div @slideInBottom fxLayout="row" fxLayoutAlign="center center" *ngIf="this.isAuthenticated && ((this.drawerMenuItemAtual == null) || (this.drawerMenuItemAtual != null && this.drawerMenuItemAtual.ocultarTab == false))">
        <mat-tab-group headerPosition="below" mat-align-tabs="center" mat-stretch-tabs
            [selectedIndex]="this.menuSelecionadoIndex" (selectedIndexChange)="this.selecionarMenu($event)" 
            [fxFlex]="(this.isMobile.matches == true || !this.menuFixado) ? '100': '50'"
            fxFlex.gt-md="40">
            <mat-tab *ngFor="let menuTab of this.menuTabs; let index = index"
                (click)="this.selecionarMenu(menuTab.rota)" [disabled]="menuTab.desabilitada">
                <ng-template mat-tab-label>
                    <mat-icon matBadge="15" matBadgeColor="primary">{{menuTab.icone}}</mat-icon>
                  </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>


</div>