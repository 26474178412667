import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/usuario/usuario.service';
import { RouterService } from 'src/app/seguranca/router-service';
import { DrawerMenuItemAtual } from 'src/app/comum/componentes/drawer-menu/drawer-menu-item-atual';
import { LocalStorageService } from 'src/app/comum/services/local-storage.service';
import { NOME_FILTRO_CHAVES_PIX, NOME_FILTRO_EXTRATO_BANCARIO, NOME_FILTRO_EXTRATO_BANCARIO_EXPORTACAO } from 'src/app/app.config';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UsuarioCarteiraDigitalService } from '../usuario-carteira-digital.service';
import { ExtratoBancarioResponse } from '../models/extratoBancarioResponse';
import { FiltroExtratoBancarioRequest } from '../models/filtroExtratoBancarioRequest';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, filter, map, Observable, pairwise, Subscription, throttleTime } from 'rxjs';
import { PaginacaoResponse } from 'src/app/comum/models/paginacaoResponse';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { UsuarioCarteiraDigitalExtratoFiltroModalComponent } from '../usuario-carteira-digital-extrato-filtro-modal/usuario-carteira-digital-extrato-filtro-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-usuario-carteira-digital-extrato',
    templateUrl: './usuario-carteira-digital-extrato.component.html',
    styleUrls: ['./usuario-carteira-digital-extrato.component.scss']
})
export class UsuarioCarteiraDigitalExtratoComponent implements OnInit {

    @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

    extratosBancarios: ExtratoBancarioResponse[] = [];
    searchPageNumber: number = 0;
    possuiMaisRegistros: boolean;
    carregandoRegistros: boolean;
    public filtroRequest: FiltroExtratoBancarioRequest;
    itemSize = 200;

    extratosBancarios$ = new BehaviorSubject<ExtratoBancarioResponse[]>([]);
    loading$ = new BehaviorSubject(false);

    constructor(private readonly formBuilder: UntypedFormBuilder,
        private usuarioCarteiraDigitalService: UsuarioCarteiraDigitalService,
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private usuarioService: UsuarioService,
        private routerService: RouterService,
        private router: Router,
        public localStorageService: LocalStorageService,
        private bottomSheet: MatBottomSheet,
        public scrollDispatcher: ScrollDispatcher,
        public changeDetectorRef: ChangeDetectorRef,
        private snackBar: MatSnackBar) {
    }

    async ngOnInit() {
        setTimeout(() => { this.routerService.alterarRota(new DrawerMenuItemAtual("Extrato bancário", "home", "", true, false, null))}, 0);

        let filtroRequest: FiltroExtratoBancarioRequest;
        filtroRequest = this.localStorageService.recuperarItem<FiltroExtratoBancarioRequest>(NOME_FILTRO_EXTRATO_BANCARIO);
        this.filtroRequest = new FiltroExtratoBancarioRequest(filtroRequest ? filtroRequest.inicio : undefined,
            filtroRequest ? filtroRequest.fim : undefined,
            0, 10);

        this.activatedRoute.data.subscribe((data) => {
            this.possuiMaisRegistros = data.extratosBancarios.data.existemMaisRegistros;

            this.extratosBancarios$.next(data.extratosBancarios.data.registros);
            this.extratosBancarios = data.extratosBancarios.data.registros;
            this.searchPageNumber++;
        });


    }

    recarregarExtrato() {
        this.usuarioCarteiraDigitalService.recuperarExtratoBancario(this.filtroRequest).then(registro => {
            this.extratosBancarios = registro.data.registros;
        });
    }

    abrirExtrato(extratoBancarioResponse: ExtratoBancarioResponse): void {
        if(extratoBancarioResponse.transferenciaId != null){
            this.router.navigate(['/pix/transfers'], { queryParams: { transferenciaId: extratoBancarioResponse.transferenciaId, rotaOrigem: 'movimentacoes' }, queryParamsHandling: 'merge' });
        }
    }

    filtrar() {

        this.filtroRequest.alterarPular(0);
        this.filtroRequest.alterarQuantidade(10);
    
        this.buscarPorFiltro(this.filtroRequest, false);
      }
    
      buscarMais() {
    
        this.filtroRequest.alterarPular(this.extratosBancarios.length);
        this.filtroRequest.alterarQuantidade(10);
    
        this.buscarPorFiltro(this.filtroRequest, true);
    
      }
    
      buscarPorFiltro(filtroRequest: FiltroExtratoBancarioRequest, acrescentar: boolean) {
        this.usuarioCarteiraDigitalService.recuperarExtratoBancario(filtroRequest).then(response => {
          if (acrescentar) {
            this.extratosBancarios = this.extratosBancarios.concat(response.data.registros);    
            return;
          }
          this.extratosBancarios = response.data.registros ? response.data.registros : [];
        });
      }

    async solicitarExtrato(filtroRequest: FiltroExtratoBancarioRequest) {
        this.usuarioCarteiraDigitalService.solicitarExtrato(filtroRequest)
            .subscribe(response => {
                var contentDisposition = response.headers.get('content-disposition');
                var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                var file = new Blob([response.body], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL); // if you want to open it in new tab
                var a = document.createElement('a');
                a.href = fileURL;
                a.target = '_blank';
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            });
    }

    abrirFiltro() {
        const dialogRef = this.dialog.open(UsuarioCarteiraDigitalExtratoFiltroModalComponent, {
          width: '600px',
          data:  { filtro: this.localStorageService.recuperarItem(NOME_FILTRO_EXTRATO_BANCARIO), nomeFiltro: NOME_FILTRO_EXTRATO_BANCARIO}
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            const filtro = this.localStorageService.recuperarItem<FiltroExtratoBancarioRequest>(NOME_FILTRO_EXTRATO_BANCARIO);
            this.filtroRequest.inicio = filtro.inicio;
            this.filtroRequest.fim = filtro.fim;
            this.filtrar();
          } else {
            this.snackBar.open(`Filtro cancelado!`,
              `Ok`, { duration: 3000 });
          }
        });
      }

      abrirFiltroExtrato() {
        const dialogRef = this.dialog.open(UsuarioCarteiraDigitalExtratoFiltroModalComponent, {
          width: '600px',
          data:  { filtro: this.localStorageService.recuperarItem(NOME_FILTRO_EXTRATO_BANCARIO_EXPORTACAO), nomeFiltro: NOME_FILTRO_EXTRATO_BANCARIO_EXPORTACAO}
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            const filtro = this.localStorageService.recuperarItem<FiltroExtratoBancarioRequest>(NOME_FILTRO_EXTRATO_BANCARIO_EXPORTACAO);
            this.solicitarExtrato(filtro);
          } else {
            this.snackBar.open(`Filtro cancelado!`,
              `Ok`, { duration: 3000 });
          }
        });
      }
}