import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UsuarioContaDigitalService } from '../usuario-conta-digital.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Usuario } from 'src/app/usuario/models/usuario';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { TipoEmpresaConst } from 'src/app/comum/consts/tipoEmpresa.const';
import { TipoPessoaConst } from 'src/app/comum/consts/tipoPessoa.const';
import { ViaCepService } from 'src/app/comum/services/viacep.service';
import { Endereco } from 'src/app/comum/models/cep';
import { UsuarioContaDigitalDocumento } from '../models/usuarioContaDigitalDocumento';
import { ContaDigital } from '../models/contaDigital';
import { TipoPessoaEnum } from 'src/app/comum/enuns/tipoPessoa.enum';
import { StatusContaDigitalEnum } from 'src/app/comum/enuns/statusContaDigital.enum';
import { StatusContaDigitalConst } from 'src/app/comum/consts/statusContaDigital.const';
import { DialogComponent } from 'src/app/comum/componentes/upload/dialog/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuarioContaDigitalDocumentacao } from '../models/usuarioContaDigitalDocumentacao';
import { TipoDocumentoConst } from 'src/app/comum/consts/tipoDocumento.const';
import { StatusDocumentoConst } from 'src/app/comum/consts/statusDocumento.const';
import { InserirContaDigitalDocumentoRequest } from '../models/inserirContaDigitalDocumentoRequest';
import { TipoDocumentoEnum } from 'src/app/comum/enuns/tipoDocumento.enum';

@Component({
  selector: 'app-usuario-conta-digital-documento',
  templateUrl: './usuario-conta-digital-documento.component.html',
  styleUrls: ['./usuario-conta-digital-documento.component.scss']
})
export class UsuarioContaDigitalDocumentoComponent implements OnInit {

  usuarioContaDigitalForm: UntypedFormGroup;
  usuarios: ComboBoxItem[];
  tiposDocumentos: Array<object>;
  statusDocumentos: Array<object>;
  documentacoes: UsuarioContaDigitalDocumentacao[];
  arquivos: Set<File>;

  constructor(private readonly formBuilder: UntypedFormBuilder,
    private readonly usuarioContaDigitalService: UsuarioContaDigitalService,
    private readonly viaCepService: ViaCepService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private readonly ref: MatDialogRef<UsuarioContaDigitalDocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: UsuarioContaDigitalDocumentacao[]) {

    this.tiposDocumentos = TipoDocumentoConst;
    this.statusDocumentos = StatusDocumentoConst;
    this.documentacoes = data;
  }

  ngOnInit() {
  }

  anexarArquivos(arquivos: Set<File>, documentacao: UsuarioContaDigitalDocumentacao) {
    let inserirContaDigitalDocumentoRequest: InserirContaDigitalDocumentoRequest;

    if (arquivos.size > 0) {
      arquivos.forEach(async (file: File) => {
        inserirContaDigitalDocumentoRequest = new InserirContaDigitalDocumentoRequest(documentacao.id, file.name, TipoDocumentoEnum[documentacao.tipoDocumento.value], file);
        await this.usuarioContaDigitalService.inserirDocumento(inserirContaDigitalDocumentoRequest).subscribe(response => {
          this.snackBar.open(`O documento foi salvo com sucesso!`, `Ok`, { duration: 2000 });
          this.recarregarDocumentos();
          this.arquivos = new Set();
        });
      })
    }
  }

  recarregarDocumentos() {
    this.usuarioContaDigitalService.recuperarDocumentos().subscribe(response => {
      this.documentacoes = response.data;
    });
  }

  async salvar() {
  }
}
