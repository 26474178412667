import { SegurancaModule } from './../seguranca/seguranca.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChartModule } from 'angular-highcharts';
import { UsuarioCarteiraDigitalModule } from '../usuario-carteira-digital/usuario-carteira-digital.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CampanhaEmpresaModule } from '../campanha-empresa/campanha-empresa.module';
import { CampanhaFuncionarioMetaModule } from '../campanha-funcionario-meta/campanha-funcionario-meta.module';
import { CampanhaFuncionarioSaldoModule } from '../campanha-funcionario-saldo/campanha-funcionario-saldo.module';
import { CampanhaFuncionarioModule } from '../campanha-funcionario/campanha-funcionario.module';
import { CampanhaRemuneracaoModule } from '../campanha-remuneracao/campanha-remuneracao.module';
import { CampanhaTotalizacaoModule } from '../campanha-totalizacao/campanha-totalizacao.module';
import { CampanhaRoutingModule } from '../campanha/campanha.routing';
import { ComponentesModule } from '../comum/componentes/componentes.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatTabsModule,
    FlexLayoutModule,
    MatIconModule,
    ChartModule,
    SegurancaModule,
    UsuarioCarteiraDigitalModule,
    MatIconModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    RouterModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    CampanhaRoutingModule,
    ComponentesModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatOptionModule,
    CurrencyMaskModule,
    CampanhaRemuneracaoModule,
    CampanhaFuncionarioModule,
    CampanhaFuncionarioMetaModule,
    MatTableModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CampanhaTotalizacaoModule,
    MatStepperModule,
    MatCheckboxModule,
    CampanhaEmpresaModule,
    CampanhaFuncionarioSaldoModule,
    MatChipsModule,
    MatDividerModule,
    MatProgressSpinnerModule
  ],
  declarations: [HomeComponent],
  exports: [HomeComponent]
})
export class HomeModule { }
