export const DialogActionYesAndNo = 'YesAndNo';
export const DialogActionOkAndCancel  = 'YesAndCancel';
export const DialogAcirionOkAndCancel  = 'OkAndCancel';
export const DialogActionOkAndNo  = 'OkAndNo';
export const DialogActionDefault  = 'Default';
export const DialogActionProgress = 'Progress';
export const DialogResponseOk = 'Ok';
export const DialogResponseYes = 'Yes';
export const DialogResponseNo = 'No';
export const DialogResposeCancel = 'Cancel';
