import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelefoneListaComponent } from './telefone-lista/telefone-lista.component';
import { TelefoneNovoComponent } from './telefone-novo/telefone-novo.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatToolbarModule,
        MatCardModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule
    ],
    declarations: [TelefoneListaComponent, TelefoneNovoComponent],
    exports: [TelefoneListaComponent, TelefoneNovoComponent]
})
export class TelefoneModule { }
