import { InjectionToken } from '@angular/core';

export const NOME_FILTRO_LANCAMENTO = 'filtroLancamento';
export const NOME_FILTRO_BANCO = 'filtroBanco';
export const NOME_FILTRO_CARGO = 'filtroCargo';
export const NOME_FILTRO_PRODUTO = 'filtroProduto';
export const NOME_FILTRO_USUARIO = 'filtroUsuario';
export const NOME_FILTRO_REMUNERACAO = 'filtroRemuneracao';
export const NOME_FILTRO_VENDA = 'filtroVenda';
export const NOME_FILTRO_CAMPANHA = 'filtroCampanha';
export const NOME_FILTRO_CATEGORIA = 'filtroCategoria';
export const NOME_FILTRO_FABRICANTE = 'filtroFabricante';
export const NOME_FILTRO_DOCUMENTO = 'filtroDocumento';
export const NOME_FILTRO_CENTRO_CUSTO = 'filtroCentroCusto';
export const NOME_FILTRO_BOLETO = 'filtroBoleto';
export const NOME_FILTRO_PARCEIRO_COMERCIAL = 'filtroParceiroComercial';
export const NOME_FILTRO_EMPRESA = 'filtroEmpresa';
export const NOME_FILTRO_GRAFICO = 'filtroGrafico';
export const NOME_FILTRO_NFE_RESUMO = 'filtroNfeResumo';
export const NOME_FILTRO_NOTAS_FISCAIS = 'filtroNotasFiscais';
export const NOME_FILTRO_CHAVES_PIX = 'filtroChavesPix';
export const NOME_FILTRO_EXTRATO_BANCARIO = 'filtroExtratoBancario';
export const NOME_FILTRO_EXTRATO_BANCARIO_EXPORTACAO = 'filtroExtratoBancarioExportacao';
export const NOME_FILTRO_PAYLOAD_PIX = 'filtroPayloadPix';

export const NOME_FILTRO_USUARIOS = 'filtroUsuario';


export const BENEFICIO_ADDRESS = new InjectionToken('beneficioAddress');
export const BENEFICIO_URI = new InjectionToken('beneficioUri');
export const BENEFICIO_URL = new InjectionToken('beneficioUrl');
