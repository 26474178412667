import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UsuarioContaDigitalService } from '../usuario-conta-digital.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Usuario } from 'src/app/usuario/models/usuario';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { TipoEmpresaConst } from 'src/app/comum/consts/tipoEmpresa.const';
import { TipoPessoaConst } from 'src/app/comum/consts/tipoPessoa.const';
import { ViaCepService } from 'src/app/comum/services/viacep.service';
import { Endereco } from 'src/app/comum/models/cep';
import { UsuarioContaDigitalDetalhe } from '../models/usuarioContaDigitalDetalhe';
import { ContaDigital } from '../models/contaDigital';
import { TipoPessoaEnum } from 'src/app/comum/enuns/tipoPessoa.enum';
import { StatusContaDigitalEnum } from 'src/app/comum/enuns/statusContaDigital.enum';
import { StatusContaDigitalConst } from 'src/app/comum/consts/statusContaDigital.const';
import { DialogComponent } from 'src/app/comum/componentes/upload/dialog/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuarioContaDigitalDocumentoComponent } from '../usuario-conta-digital-documento/usuario-conta-digital-documento.component';
import { ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/usuario/usuario.service';
import { UsuarioDetalhado } from 'src/app/usuario/models/usuarioDetalhado';
import { AuthenticationService } from 'src/app/seguranca/authentication-service';
import { TermoAceiteService } from 'src/app/termo-aceite/termo-aceite.service';
import { TipoTermoAceiteEnum } from 'src/app/comum/enuns/tipoTermoAceite.enum';
import { TermoAceiteDataModel } from 'src/app/termo-aceite/models/termoAceiteDataModel';
import { TermoAceiteAnexoModalComponent } from 'src/app/termo-aceite/termo-aceite-anexo-modal/termo-aceite-anexo-modal.component';
import { UsuarioContaBancariaService } from 'src/app/usuario-conta-bancaria/usuario-conta-bancaria.service';
import { ContaBancaria } from 'src/app/usuario-conta-bancaria/models/contaBancaria';
import { RouterService } from 'src/app/seguranca/router-service';
import { DrawerMenuItemAtual } from 'src/app/comum/componentes/drawer-menu/drawer-menu-item-atual';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UsuarioContaDigitalSenhaComponent } from '../usuario-conta-digital-senha/usuario-conta-digital-senha.component';
import { UsuarioContaDigitalAlterarSenhaComponent } from '../usuario-conta-digital-alterar-senha/usuario-conta-digital-alterar-senha.component';
import { MessageDialogService } from 'src/app/message-dialog/message-dialog.service';
import { DialogActionYesAndNo, DialogResponseYes } from 'src/app/message-dialog/message-dialog-action';
import { AlterarSenhaEletronicaRequest } from '../models/alterarSenhaEletronicaRequest';
import { UsuarioModel } from 'src/app/dominio/modelos/UsuarioModel';

@Component({
    selector: 'app-usuario-conta-digital-detalhe',
    templateUrl: './usuario-conta-digital-detalhe.component.html',
    styleUrls: ['./usuario-conta-digital-detalhe.component.scss']
})
export class UsuarioContaDigitalDetalheComponent implements OnInit {

    public contaDigital: ContaDigital;
    public usuario: UsuarioDetalhado;
    usuarioContaDigitalForm: UntypedFormGroup;
    usuariosContasDigital: ComboBoxItem[];
    usuarios: ComboBoxItem[];
    tiposPessoas: Array<object>;
    statusContasDigitais: Array<object>;
    TipoPessoa = TipoPessoaEnum;
    StatusContaDigital = StatusContaDigitalEnum;
    contasBancarias: ContaBancaria[];
    usuarioModel: UsuarioModel;

    constructor(private readonly formBuilder: UntypedFormBuilder,
        private readonly usuarioContaDigitalService: UsuarioContaDigitalService,
        private readonly viaCepService: ViaCepService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private usuarioService: UsuarioService,
        private authenticationService: AuthenticationService,
        private termoAceiteService: TermoAceiteService,
        private usuarioContaBancariaService: UsuarioContaBancariaService,
        private routerService: RouterService,
        private dialogService: MessageDialogService,
        private bottomSheet: MatBottomSheet) {

        this.tiposPessoas = TipoPessoaConst;
        this.statusContasDigitais = StatusContaDigitalConst;

    }

    ngOnInit() {
        setTimeout(() => { this.routerService.alterarRota(new DrawerMenuItemAtual("Conta digital", null, "", false, false, null))}, 0);

        this.activatedRoute.data.subscribe((data) => {
            this.contaDigital = data.contaDigital.data;
        });
        this.usuarioService.recuperarDadosPessoais().then(registro => {
            this.usuario = registro.data;
        });
        this.usuarioContaBancariaService.recuperar().subscribe(registro => {
            this.contasBancarias = registro.data;
        });

        this.usuarioModel = this.authenticationService.getSession();

        if(this.usuarioModel.modalidadeRetirada == 1)
          {
            setTimeout(() => { this.routerService.desabilitarRota(false) }, 0);
          }
          else{
            setTimeout(() => { this.routerService.desabilitarRota(true) }, 0);
          }
    }

    recarregarContaDigital() {
        this.usuarioContaDigitalService.recuperarMinhaContaDigital().then(registro => {
            this.contaDigital = registro.data;
        });
    }

    verificarStatus() {
        this.usuarioContaDigitalService.verificaStatus().subscribe(response => {
            this.contaDigital = response.data;
        });
    }

    criarContaDigital($event) {
        var usuarioModel = this.authenticationService.getSession();

        this.termoAceiteService.recuperarPendentePorTipo(TipoTermoAceiteEnum.AberturaContaPagamento).then(response => {

            var termoAceiteDataModel = new TermoAceiteDataModel(usuarioModel.token, null);
            if (response.data != null) {
                termoAceiteDataModel.termoAceiteId = response.data.id;
            }

            const ref = this.dialog.open(TermoAceiteAnexoModalComponent, {
                width: '500px',
                disableClose: true,
                data: termoAceiteDataModel
            });

            ref.afterClosed().subscribe(async value => {
                if (value) {
                    this.senhaEletronicaModal();
                } else {
                    this.snackBar.open(`Termos não foram aceitos!`,
                        `Ok`, { duration: 3000 });
                }
            });
        });
    }

    senhaEletronicaModal(): void {
        const ref = this.bottomSheet.open(UsuarioContaDigitalSenhaComponent, {
            hasBackdrop: true,
            disableClose: true,
        });

        ref.afterDismissed().subscribe(value => {
            this.recarregarContaDigital();
        });
    }

    alterarSenhaEletronicaModal(): void {
        const ref = this.bottomSheet.open(UsuarioContaDigitalAlterarSenhaComponent, {
            hasBackdrop: true,
            disableClose: true,
        });

        ref.afterDismissed().subscribe(value => {
            this.recarregarContaDigital();
        });
    }

    resetarSenhaEletronica() {
        const dialog = this.dialogService.open('Atenção', `Ao prosseguir, uma nova senha provisória será enviada para o seu e-mail. Deseja continuar?`, DialogActionYesAndNo);
        dialog.afterClosed().subscribe(dialogResponse => {
            if (dialogResponse === DialogResponseYes) {
                this.usuarioContaDigitalService.resetarSenhaEletronica().subscribe(response => {
                    this.snackBar.open(`A nova senha foi enviada para o seu e-mail.`, `Ok`, { duration: 3000 });
                });
            }
        });
    }

    abrirModalDocumentos() {
        this.usuarioContaDigitalService.recuperarDocumentos().subscribe(response => {
            const ref = this.dialog.open(UsuarioContaDigitalDocumentoComponent, {
                width: '700px',
                disableClose: true,
                data: response.data
            });

            ref.afterClosed().subscribe(value => {
                if (value) {
                    this.recarregarContaDigital();
                    this.snackBar.open(`Documento inserido com sucesso!`,
                        `Ok`, { duration: 3000 });

                } else {
                    this.snackBar.open(`Inserção cancelado!`,
                        `Ok`, { duration: 3000 });
                }
            });
        });
    }

    verificarStatusContaBancaria($event) {
        if ($event) {
            this.usuarioContaDigitalService.verificaStatus().subscribe(response => {
                this.contaDigital = response.data;
            })
        } else {
            this.snackBar.open(`Filtro cancelado!`,
                `Ok`, { duration: 3000 });
        }
    }


    adicionarContaBancaria($event) {
        if ($event) {
            this.usuarioContaBancariaService.recuperar().subscribe(registro => {
                this.contasBancarias = registro.data;
            });
        } else {
            this.snackBar.open(`Filtro cancelado!`,
                `Ok`, { duration: 3000 });
        }
    }

}
