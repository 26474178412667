import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Produto } from './models/produto';
import { ProdutoFiltro } from './models/produto-filtro';
import { BENEFICIO_URI, BENEFICIO_ADDRESS } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { InserirProdutoRequest } from './models/inserirProdutoRequest';
import { ResponseData } from '../comum/models/responseData';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { AlterarProdutoRequest } from './models/alterarProdutoRequest';
import { ProdutoFiltroRequest } from './models/produto-filtro-request';

@Injectable({ providedIn: 'root'})
export class ProdutoService {

  constructor(private http: HttpClient, 
    private queryParamsService: QueryParamsService,
    @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
    @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

  public recuperarPorFiltro(filtro: ProdutoFiltroRequest) {
    return this.http.get<ResponseData<Produto[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/produtos`, {params: this.queryParamsService.gerarQuery(filtro) }).toPromise();
  }

  public recuperarPorId(id: string) {
    return this.http.get<Produto>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/produtos/${id}`).toPromise();
  }

  public incluir(produto: InserirProdutoRequest) {
    return this.http.post<ResponseData<Produto>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/produtos`, produto).toPromise();
  }

  public alterar(id: any, produto: AlterarProdutoRequest) {
    return this.http.put<ResponseData<Produto>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/produtos/${id}`, produto).toPromise();
  }

  public deletar(id: any) {
    return this.http.delete<any>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/produtos/${id}`).toPromise();
  }

  public listarAutoComplete(contem: string, empresaId: string) {
    return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/produtos/combobox`, {params: {conteudoPesquisa : contem  , empresaId: empresaId}}).toPromise();
  }
}
