<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxFlex fxLayoutAlign="center center">
      <h3 fxAlign="center">Termos e condições de uso</h3> 
    </div>
    <p>Para reforçar o compromisso com nossos usuários e <br>
    garantir o controle em relação ao uso de dados <br>
    pessoais, em decorrência da Lei Geral de Proteção de<br>
    Dados (n°b13.709/2018), estamos disponibilizando os <br>
    Termo e Politias de Privacidade para os usuários da <br>
    nossa plataforma.<br>
    <br>
    Para garantir a continuidade do uso dos nossos serviços,<br>
    por favor, revise essas informações e confirme estar de <br>
    acordo com nossos termos.<br>
  </div>

  <!-- <app-pdf-viewer [pdf]="pdf"></app-pdf-viewer> -->
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-checkbox [(ngModel)]="termoAceito" [checked]="false"></mat-checkbox>
    <span>Declaro que li e concordo com os <i>Termos de uso, Privacidade, Cookies, Cancelamento e Proteção de
        dados</i>.</span>
  </div>

  <mat-dialog-actions fxLayoutAlign="space-between center" fxLayoutGap="1rem">
    <div fxLayoutAlign="start center">
      <button mat-button id="buttonCancelar" fxLayoutAlign="start" (click)="this.baixar()">
        <span>Baixar</span>
      </button>
      <button mat-button color="warn" [mat-dialog-close]="false" id="buttonCancelar">
        <span>Não aceito</span>
      </button>
    </div>

    <button mat-raised-button color="primary" (click)="this.aceitarTermos()" id="buttonSalvar" [disabled]="!termoAceito">
      <span>Aceito termos</span>
    </button>
  </mat-dialog-actions>
</div>