<div fxLayout="column" fxLayoutAlign="center strech" fxLayoutGap="25px">
  <mat-card fxFlex
    *ngFor="let campanhaFuncionarioMetaTotalizacao of campanhasFuncionariosMetasTotalizacoes; let index = index"
    [ngClass]="{'mat-card-previa': campanhaFuncionarioMetaTotalizacao.tipoTotalizacao!= null && campanhaFuncionarioMetaTotalizacao.tipoTotalizacao.value == 'Previa', 'mat-card-encerramento': campanhaFuncionarioMetaTotalizacao.tipoTotalizacao!= null && campanhaFuncionarioMetaTotalizacao.tipoTotalizacao.value == 'Encerramento'}">
    <mat-card-header>
      <div mat-card-avatar
        [ngClass]="{'faixa-desempenho-image': campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Desempenho', 'ranqueado-image': campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Ranqueado'}">

      </div>
      <mat-card-title>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
          <div fxFlex>
            {{campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.description}}
          </div>
        </div>
      </mat-card-title>
      <mat-card-subtitle>
        <div fxLayout="column">
          <span>A partir do(a) {{campanhaFuncionarioMetaTotalizacao.tipoCalculoRemuneracao.description}}</span>
          <span *ngIf="campanhaFuncionarioMetaTotalizacao.origemRemuneracao.value == 'Grupo'">Do Grupo:
            {{campanhaFuncionarioMetaTotalizacao.grupoNome}}</span>
          <span *ngIf="campanhaFuncionarioMetaTotalizacao.origemRemuneracao.value == 'Produto'">Do Produto:
            {{campanhaFuncionarioMetaTotalizacao.produtoNome}}</span>
          <span *ngIf="campanhaFuncionarioMetaTotalizacao.origemRemuneracao.value == 'Fabricante'">Do Fabricante:
            {{campanhaFuncionarioMetaTotalizacao.fabricanteNome}}</span>
          <span *ngIf="campanhaFuncionarioMetaTotalizacao.origemRemuneracao.value == 'SubGrupo'">Do Sub grupo:
            {{campanhaFuncionarioMetaTotalizacao.subGrupoNome}}</span>
          <span *ngIf="campanhaFuncionarioMetaTotalizacao.origemRemuneracao.value == 'Categoria'">Da Categoria:
            {{campanhaFuncionarioMetaTotalizacao.categoriaNome}}</span>
          <span
            *ngIf="campanhaFuncionarioMetaTotalizacao.origemRemuneracao.value == 'CodigoBarras'">{{campanhaFuncionarioMetaTotalizacao.categoriaNome}}</span>
        </div>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" fxLayoutAlign="start strech" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div><i>"{{campanhaFuncionarioMetaTotalizacao.nomeRemuneracao }}"</i></div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <div fxLayout="column" fxLayoutAlign="start center">
            <div>Empresa</div>
            <div>
              <strong>{{campanhaFuncionarioMetaTotalizacao.empresaNomeRazaoSocial  }}</strong>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <div fxLayout="column" fxLayoutAlign="start center">
            <div>Meta mínima</div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMeta.value == 'TotalVendido'" class="mat-orange-color">
              <strong>{{campanhaFuncionarioMetaTotalizacao.metaMinima | currency: 'BRL' }}</strong>
            </div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMeta.value == 'QuantidadeVendida'"
              class="mat-orange-color"><strong>{{campanhaFuncionarioMetaTotalizacao.metaMinima | currency: ' ': 'symbol'
                : '1.2-2' }} UND</strong></div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start center">
            <div>Meta</div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMeta.value == 'TotalVendido'" class="mat-green-color">
              <strong>{{campanhaFuncionarioMetaTotalizacao.meta | currency: 'BRL' }}</strong>
            </div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMeta.value == 'QuantidadeVendida'"
              class="mat-green-color"><strong>{{campanhaFuncionarioMetaTotalizacao.meta | currency: ' ': 'symbol' :
                '1.2-2' }} UND</strong></div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <div fxLayout="column" fxLayoutAlign="start center">
            <div>Super meta</div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMeta.value == 'TotalVendido'" class="mat-blue-color">
              <strong>{{campanhaFuncionarioMetaTotalizacao.superMeta | currency: 'BRL' }}</strong>
            </div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMeta.value == 'QuantidadeVendida'"
              class="mat-blue-color"><strong>{{campanhaFuncionarioMetaTotalizacao.superMeta | currency: ' ': 'symbol' :
                '1.2-2' }} UND</strong></div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start center"
            *ngIf="campanhaFuncionarioMetaTotalizacao.tipoBonus != null">
            <div>Bonus Super Meta</div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoBonus.value == 'Percentual'" class="mat-green-color">
              <strong>{{campanhaFuncionarioMetaTotalizacao.percentualBonusSuperMeta | currency: ' ': 'symbol' : '1.2-2'
                }}
                %</strong>
            </div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoBonus.value == 'Valor'" class="mat-green-color">
              <strong>{{campanhaFuncionarioMetaTotalizacao.valorBonusSuperMeta | currency: 'BRL'
                }}</strong>
            </div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoBonus.value == 'ValorPorUnidade'"
              class="mat-green-color">
              <strong>{{campanhaFuncionarioMetaTotalizacao.valorBonusSuperMeta | currency: 'BRL'
                }}</strong>
            </div>
          </div>
        </div>
        <!-- <mat-divider *ngIf="campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Ranqueado'"></mat-divider>
        <div fxLayout="row" fxLayoutAlign="space-around start"
          *ngIf="campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Ranqueado'">
          <div fxLayout="column" fxLayoutAlign="start center">
            <div>Orçamento</div>
            <div><strong>{{campanhaFuncionarioMetaTotalizacao.orcamento | currency: 'BRL'
                }}</strong></div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start center" *ngIf="campanhaFuncionarioMetaTotalizacao.tipoProporcionalidade != null">
            <div>Divisão orçamento</div>
            <div><strong>{{campanhaFuncionarioMetaTotalizacao.tipoProporcionalidade.description }}</strong>
              <mat-icon class="mat-icon-help" matTooltip="{{this.informacoesDivisaoOrcamentoTooltip()}}">help</mat-icon>
            </div>
          </div>
        </div> -->
        <mat-divider
          *ngIf="campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Ranqueado' && campanhaFuncionarioMetaTotalizacao.tipoApuracaoMeta != null">
        </mat-divider>
        <div fxLayout="row" fxLayoutAlign="space-around start"
          *ngIf="campanhaFuncionarioMetaTotalizacao.tipoApuracaoMeta != null">
          <div fxLayout="column" fxLayoutAlign="start center">
            <div>Tipo de meta</div>
            <div class="mat-blue-color">
              <strong>{{campanhaFuncionarioMetaTotalizacao.tipoMetaRemuneracao.description}}</strong>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start center">
            <div>Meta do ranking</div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMetaRemuneracao.value == 'TotalVendido'"
              class="mat-blue-color">
              <strong>{{campanhaFuncionarioMetaTotalizacao.valorMeta | currency: 'BRL' }}</strong>
            </div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMetaRemuneracao.value == 'QuantidadeVendida'"
              class="mat-blue-color"><strong>{{campanhaFuncionarioMetaTotalizacao.quantidadeMeta | currency: ' ':
                'symbol' : '1.2-2' }} UND</strong></div>
            <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMetaRemuneracao.value == 'TicketMedio'"
              class="mat-blue-color">
              <strong>{{campanhaFuncionarioMetaTotalizacao.valorMeta | currency: 'BRL' }}</strong>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start center">
            <div>Forma de apuração meta</div>
            <div> <strong>{{campanhaFuncionarioMetaTotalizacao.tipoApuracaoMeta.description }}</strong>
              <mat-icon class="mat-icon-help" matTooltip="{{this.informacoesApuracaoMetaTooltip()}}">help</mat-icon>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>

        <div fxLayout="column" fxLayoutAlign="start strech" fxLayoutGap="10px" fxFex
          *ngIf="campanhaFuncionarioMetaTotalizacao.tipoTotalizacao != null">
          <div fxLayout="row" fxLayoutAlign="space-around center">
            <mat-chip-list>
              <mat-chip color="primary" selected
                *ngIf="campanhaFuncionarioMetaTotalizacao.tipoTotalizacao.value == 'Encerramento'">
                {{campanhaFuncionarioMetaTotalizacao.tipoTotalizacao.description}}
              </mat-chip>
              <mat-chip color="accent" selected
                *ngIf="campanhaFuncionarioMetaTotalizacao.tipoTotalizacao.value == 'Previa'">
                {{campanhaFuncionarioMetaTotalizacao.tipoTotalizacao.description}}
              </mat-chip>
            </mat-chip-list>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <div fxLayout="column" fxLayoutAlign="start center">
              <div [ngClass]="this.identificarMetaAtingida(campanhaFuncionarioMetaTotalizacao)">Resultado apurado
              </div>
              <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMeta.value == 'TotalVendido'"
                [ngClass]="this.identificarMetaAtingida(campanhaFuncionarioMetaTotalizacao)">
                <strong>{{campanhaFuncionarioMetaTotalizacao.valorTotalApuradoFuncionario | currency: 'BRL':
                  'symbol' : '1.2-2'
                  }}</strong>
              </div>
              <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMeta.value == 'QuantidadeVendida'"
                [ngClass]="this.identificarMetaAtingida(campanhaFuncionarioMetaTotalizacao)">
                <strong>{{campanhaFuncionarioMetaTotalizacao.quantidadeTotalApuradoFuncionario | currency: ' ':
                  'symbol' : '1.2-2' }} UND</strong>
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start center">
              <div [ngClass]="this.identificarMetaAtingida(campanhaFuncionarioMetaTotalizacao)">Meta atingida
              </div>
              <div [ngClass]="this.identificarMetaAtingida(campanhaFuncionarioMetaTotalizacao)">
                <strong>{{campanhaFuncionarioMetaTotalizacao.percentualMetaApurada | currency: ' ': 'symbol' :
                  '1.2-2' }} %</strong>
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start center">
              <div>Representatividade</div>
              <div><strong>{{campanhaFuncionarioMetaTotalizacao.representatividadeApurada | currency: ' ':
                  'symbol' :
                  '1.2-2' }} %</strong></div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <div fxLayout="column" fxLayoutAlign="start center">
              <div>Meta mínima</div>
              <div *ngIf="campanhaFuncionarioMetaTotalizacao.atingiuMetaMinima"
                [ngClass]="{'mat-darkorange-color': campanhaFuncionarioMetaTotalizacao.atingiuMetaMinima} ">
                <mat-icon>thumb_up</mat-icon>
              </div>
              <div *ngIf="!campanhaFuncionarioMetaTotalizacao.atingiuMetaMinima"
                [ngClass]="{'mat-red-color': !campanhaFuncionarioMetaTotalizacao.atingiuMetaMinima} ">
                <mat-icon>thumb_down</mat-icon>
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start center">
              <div>Meta</div>
              <div *ngIf="campanhaFuncionarioMetaTotalizacao.atingiuMeta"
                [ngClass]="{'mat-green-color': campanhaFuncionarioMetaTotalizacao.atingiuMeta} ">
                <mat-icon>thumb_up</mat-icon>
              </div>
              <div *ngIf="!campanhaFuncionarioMetaTotalizacao.atingiuMeta"
                [ngClass]="{'mat-red-color': !campanhaFuncionarioMetaTotalizacao.atingiuMetaMinima} ">
                <mat-icon>thumb_down</mat-icon>
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start center">
              <div>Super meta</div>
              <div *ngIf="campanhaFuncionarioMetaTotalizacao.atingiuSuperMeta"
                [ngClass]="{'mat-blue-color': campanhaFuncionarioMetaTotalizacao.atingiuSuperMeta} ">
                <mat-icon>thumb_up</mat-icon>
              </div>
              <div *ngIf="!campanhaFuncionarioMetaTotalizacao.atingiuSuperMeta"
                [ngClass]="{'mat-red-color': !campanhaFuncionarioMetaTotalizacao.atingiuMetaMinima} ">
                <mat-icon>thumb_down</mat-icon>
              </div>
            </div>
          </div>
          <!-- <mat-divider  *ngIf="campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Ranqueado'"></mat-divider>
                <div fxLayout="row" fxLayoutAlign="space-around start"  *ngIf="campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Ranqueado'">
                  <div fxLayout="column" fxLayoutAlign="start center">
                    <div>Atingiu meta?</div>
                    <div *ngIf="campanhaFuncionarioMetaTotalizacao.metaAlcancada != null"
                      [ngClass]="{'mat-darkorange-color': campanhaFuncionarioMetaTotalizacao.metaAlcancada == true} ">
                      <mat-icon>thumb_up</mat-icon>
                    </div>
                    <div *ngIf="campanhaFuncionarioMetaTotalizacao.metaAlcancada != null"
                      [ngClass]="{'mat-red-color': campanhaFuncionarioMetaTotalizacao.metaAlcancada == false} ">
                      <mat-icon>thumb_down</mat-icon>
                    </div>
                    <div *ngIf="campanhaFuncionarioMetaTotalizacao.metaAlcancada == null" matTooltip="Sem meta configurada">
                      <mat-icon>block</mat-icon>
                    </div>
                  </div>
                  <div fxLayout="column" fxLayoutAlign="start center">
                    <div>Resultado do Ranking</div>
                    <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMeta.value == 'TotalVendido'">
                      <strong>{{campanhaFuncionarioMetaTotalizacao.valorMetaAlcancada | currency: 'BRL' }}</strong>
                    </div>
                    <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoMeta.value == 'QuantidadeVendida'"><strong>{{campanhaFuncionarioMetaTotalizacao.quantidadeMetaAlcancada | currency: ' ':
                        'symbol' : '1.2-2' }} UND</strong></div>
                  </div>
                </div> -->
          <mat-divider *ngIf="campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Desempenho'">
          </mat-divider>
          <div fxLayout="row" fxLayoutAlign="space-around start"
            *ngIf="campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Desempenho'">
            <div fxLayout="column" fxLayoutAlign="start center">
              <div>Performance</div>
              <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoFaixaDesempenho.value == 'Percentual'">
                <strong>{{campanhaFuncionarioMetaTotalizacao.minimoDesempenhoApurado | currency: ' ':
                  'symbol' : '1.2-2' }} a {{campanhaFuncionarioMetaTotalizacao.maximoDesempenhoApurado | currency:
                  ' ':
                  'symbol' : '1.2-2' }} %</strong>
              </div>
              <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoFaixaDesempenho.value == 'Quantidade'">
                <strong>{{campanhaFuncionarioMetaTotalizacao.minimoDesempenhoApurado | currency: ' ':
                  'symbol' : '1.2-2' }} a {{campanhaFuncionarioMetaTotalizacao.maximoDesempenhoApurado | currency:
                  ' ':
                  'symbol' : '1.2-2' }} UND</strong>
              </div>
              <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoFaixaDesempenho.value == 'Valor'">
                <strong>{{campanhaFuncionarioMetaTotalizacao.minimoDesempenhoApurado | currency: 'BRL':
                  'symbol' : '1.2-2' }} a {{campanhaFuncionarioMetaTotalizacao.maximoDesempenhoApurado | currency:
                  'R ':
                  'symbol' : '1.2-2' }}</strong>
              </div>
            </div>
            <!-- <div fxLayout="column" fxLayoutAlign="start center">
                    <div>Pagando</div>
                    <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoCalculoRemuneracao.value == 'VendaTotal'">
                      <strong>{{campanhaFuncionarioMetaTotalizacao.porcentagemRemuneracaoApurado | currency: ' ':
                        'symbol' : '1.2-2' }}% do apurado</strong>
                    </div>
                    <div *ngIf="campanhaFuncionarioMetaTotalizacao.tipoCalculoRemuneracao.value == 'QuantidadeTotal'">
                      <strong>{{campanhaFuncionarioMetaTotalizacao.valorRemuneracaoApurado | currency: 'BRL':
                        'symbol' : '1.2-2' }} por UND </strong>
                    </div>
                  </div> -->
            <!-- <div fxLayout="column" fxLayoutAlign="start center"
                    *ngIf="campanhaFuncionarioMetaTotalizacao.valorBonusApurado > 0">
                    <div>Bonus</div>
                    <div>
                      <strong>{{campanhaFuncionarioMetaTotalizacao.valorBonusApurado | currency: 'BRL':
                        'symbol' : '1.2-2' }}</strong>
                    </div>
                  </div> -->
          </div>
          <mat-divider *ngIf="campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Ranqueado'">
          </mat-divider>
          <div fxLayout="row" fxLayoutAlign="space-around start"
            *ngIf="campanhaFuncionarioMetaTotalizacao.tipoRemuneracao.value == 'Ranqueado'">
            <div fxLayout="column" fxLayoutAlign="start center">
              <div>Colocação</div>
              <div>
                <strong>{{campanhaFuncionarioMetaTotalizacao.colocacao | currency: ' ': 'symbol' : '1.0'
                  }} º</strong>
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start center">
              <div>Seu % no ranking</div>
              <div>
                <strong>{{campanhaFuncionarioMetaTotalizacao.percentualRankingApurado | currency: ' ':
                  'symbol' : '1.2-2' }} %</strong>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <div fxLayout="column" fxLayoutAlign="start center" class="mat-red-color"
              *ngIf="campanhaFuncionarioMetaTotalizacao.valorGanhoAnulado > 0">
              <h4>Prêmio anulado</h4>
              <h3>
                <strong>{{campanhaFuncionarioMetaTotalizacao.valorGanhoAnulado | currency: 'BRL':
                  'symbol' : '1.2-2' }}</strong>
              </h3>
            </div>
            <div fxLayout="column" fxLayoutAlign="start center"
              [ngClass]="{'mat-green-color': campanhaFuncionarioMetaTotalizacao.valorGanhoLiberado > 0}"
              *ngIf="campanhaFuncionarioMetaTotalizacao.valorGanhoLiberado > 0 || (campanhaFuncionarioMetaTotalizacao.valorGanhoLiberado == 0 && campanhaFuncionarioMetaTotalizacao.valorGanhoCalculado == campanhaFuncionarioMetaTotalizacao.valorGanhoLiberado) ">
              <h4>Prêmio liberado</h4>
              <h3>
                <strong>{{campanhaFuncionarioMetaTotalizacao.valorGanhoLiberado | currency: 'BRL':
                  'symbol' : '1.2-2' }}</strong>
              </h3>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start"
            *ngIf="campanhaFuncionarioMetaTotalizacao.valorGanhoAnulado > 0">
            <div fxLayout="column" fxLayoutAlign="start center">
              <div>Origem da anulação</div>
              <div class="mat-red-color">
                <strong>{{campanhaFuncionarioMetaTotalizacao.origemAnulacaoGanho != null ? campanhaFuncionarioMetaTotalizacao.origemAnulacaoGanho.description : ''}}</strong>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!--<mat-table #table [(dataSource)]="this.campanhaFuncionarioMetaDataSource" fxLayout="column">
        <ng-container matColumnDef="funcionario">
          <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex>Funcionário</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let campanhaFuncionarioMeta" fxFlex>
            {{campanhaFuncionarioMeta.campanhaFuncionario.funcionario.nome}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="codigoRemuneracao">
          <mat-header-cell mat-header-cell *matHeaderCellDef>Remuneração</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let campanhaFuncionarioMeta"
            matTooltip="{{this.informacoesTooltip(campanhaFuncionarioMeta)}}" matTooltipClass="multiline-tooltip">
            {{campanhaFuncionarioMeta.campanhaRemuneracao.codigoRemuneracao}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tipoMeta">
          <mat-header-cell mat-header-cell *matHeaderCellDef>Tipo meta</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let campanhaFuncionarioMeta">{{campanhaFuncionarioMeta.tipoMeta ?
            campanhaFuncionarioMeta.tipoMeta.description : null}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="totalApuradoGeral">
          <mat-header-cell *matHeaderCellDef mat-sort-header="totalApuradoGeral" fxLayoutAlign="end center">
            Apurado geral
          </mat-header-cell>
          <mat-cell *matCellDef="let campanhaFuncionarioMeta" fxLayoutAlign="end center">
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.tipoMeta.value == 'TotalVendido' ? 'R$ ':' ') :
            null}}
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.totalApuradoGeral | currency: ' ') : null
            }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="totalApuradoFuncionario">
          <mat-header-cell *matHeaderCellDef mat-sort-header="totalApuradoFuncionario" fxLayoutAlign="end center">
            Apurado funcionário
          </mat-header-cell>
          <mat-cell *matCellDef="let campanhaFuncionarioMeta" fxLayoutAlign="end center">
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.tipoMeta.value == 'TotalVendido' ? 'R$ ':' ') :
            null}}
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.totalApuradoFuncionario | currency: ' ') : null
            }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="representatividade">
          <mat-header-cell *matHeaderCellDef mat-sort-header="representatividade" fxLayoutAlign="end center">
            Representatividade
          </mat-header-cell>
          <mat-cell *matCellDef="let campanhaFuncionarioMeta" fxLayoutAlign="end center">
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.representatividade | currency: ' ') : null}} %
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="meta">
          <mat-header-cell *matHeaderCellDef mat-sort-header="meta">
            Meta
          </mat-header-cell>
          <mat-cell *matCellDef="let campanhaFuncionarioMeta">
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.tipoMeta.value == 'TotalVendido' ? 'R$ ':'QTD.
            ')
            : null}}
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.meta |currency: ' ': 'symbol' : '1.2-2') : null
            }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="metaMinima">
          <mat-header-cell *matHeaderCellDef mat-sort-header="meta">
            Meta mínima
          </mat-header-cell>
          <mat-cell *matCellDef="let campanhaFuncionarioMeta">
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.tipoMeta.value == 'TotalVendido' ? 'R$ ':'QTD.
            ')
            : null}}
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.metaMinima |currency: ' ': 'symbol' : '1.2-2') :
            null }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="superMeta">
          <mat-header-cell *matHeaderCellDef mat-sort-header="meta">
            Super meta
          </mat-header-cell>
          <mat-cell *matCellDef="let campanhaFuncionarioMeta">
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.tipoMeta.value == 'TotalVendido' ? 'R$ ':'QTD.
            ')
            : null}}
            {{campanhaFuncionarioMeta.tipoMeta ? (campanhaFuncionarioMeta.superMeta |currency: ' ': 'symbol' : '1.2-2') :
            null }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="acoes">
          <mat-header-cell *matHeaderCellDef fxFlex="60px"></mat-header-cell>
          <mat-cell *matCellDef="let campanhaFuncionarioMeta" fxFlex="60px">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="this.adicionarCampanhaFuncionarioMeta(campanhaFuncionarioMeta)"
                matTooltip="Editar" color="primary" id="buttonEditar"
                [disabled]="this.campanha != null && this.campanha.statusCampanha.value != 'Rascunho' && this.campanha.statusCampanha.value != 'Interrompida'"
                *ngIf="campanhaFuncionarioMeta.metaConfigurada == false">
                <mat-icon>add</mat-icon> Adicionar
              </button>
              <button mat-menu-item (click)="this.alterarCampanhaFuncionarioMeta(campanhaFuncionarioMeta)"
                matTooltip="Editar" color="primary" id="buttonEditar"
                [disabled]="this.campanha != null && this.campanha.statusCampanha.value != 'Rascunho' && this.campanha.statusCampanha.value != 'Interrompida'"
                *ngIf="campanhaFuncionarioMeta.metaConfigurada == true">
                <mat-icon>edit</mat-icon> Editar
              </button>
              <button mat-menu-item (click)="this.deletarCampanhaFuncionarioMeta(campanhaFuncionarioMeta)"
                matTooltip="Excluir" color="warn" id="buttonExcluir"
                [disabled]="this.campanha != null && this.campanha.statusCampanha.value != 'Rascunho' && this.campanha.statusCampanha.value != 'Interrompida'"
                *ngIf="campanhaFuncionarioMeta.metaConfigurada == true">
                <mat-icon>delete</mat-icon> Excluir
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="colunas"></mat-header-row>
        <mat-row *matRowDef="let row; columns: colunas;"></mat-row>
      </mat-table> -->
    </mat-card-content>
  </mat-card>
</div>