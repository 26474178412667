import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from './message-dialog.component';

@Injectable()
export class MessageDialogService {

  constructor(private dialogController: MatDialog) { }

  public open(title: string, message: string, buttons?: string, icon: string = null) {
    return this.dialogController.open(MessageDialogComponent, {
      data: {
        message: message,
        title: title,
        buttons: buttons,
        icon: icon
      }
    });
  }

}
