
export class InserirCampanhaFuncionarioMetaRequest {
    constructor(
        public tipoMeta: number,
        public meta: number,
        public metaMinima: number,
        public campanhaRemuneracaoId: string) {
    }
}


