import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication-service';

@Injectable()
export class LoginRouteGuard implements CanActivate {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        const authenticated = this.authenticationService.authenticated();

        this.authenticationService.authenticatedEvent.emit(authenticated);

        if (authenticated){
            this.router.navigate(['/home']);
            return false;
        }  

        return true;
    }
    

    constructor(private readonly authenticationService: AuthenticationService, private readonly router: Router) {
    }
}

