import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators } from '@angular/forms';
import { UnidadeMedidaService } from '../unidade-medida.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MovimentoConst } from 'src/app/comum/consts/movimento.const';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { InserirUnidadeMedidaRequest } from '../models/inserirUnidadeMedidaRequest';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unidade-medida-incluir-modal',
  templateUrl: './unidade-medida-incluir-modal.component.html',
  styleUrls: ['./unidade-medida-incluir-modal.component.scss']
})
export class UnidadeMedidaIncluirModalComponent implements OnInit {

  unidadeMedidaFormGroup: UntypedFormGroup;
  empresas: ComboBoxItem[];

  constructor(private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private unidadeMedidaService: UnidadeMedidaService,
    private empresaService: EmpresaService,
    public location: Location,
    private readonly ref: MatDialogRef<UnidadeMedidaIncluirModalComponent>) {
  }


  ngOnInit() {

    this.unidadeMedidaFormGroup = this.formBuilder.group({
      nome: [null, Validators.required],
      abreviacao: [null, Validators.required],
      empresa: [null, Validators.required]
    });

    this.empresaService.listarVinculados()
      .then
      (response => {
        this.empresas = response.data;
        this.unidadeMedidaFormGroup.get("empresa").setValue(this.empresas.length == 1 ? this.empresas[0].id : null);
      });
  }

  async salvar() {
    if (!this.unidadeMedidaFormGroup.valid) {
      throw new Error('Dados inválidos!');
    }

    let inserirUnidadeMedidaRequest = new InserirUnidadeMedidaRequest(this.unidadeMedidaFormGroup.value.nome, this.unidadeMedidaFormGroup.value.abreviacao, 
      this.unidadeMedidaFormGroup.value.empresa)

    await this.unidadeMedidaService.incluir(inserirUnidadeMedidaRequest).then(response => {
      this.snackBar.open(`UnidadeMedida salva com sucesso!`, `Ok`, { duration: 2000 });
      this.ref.close(response.data);
    });
  }

  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }
}
