import { HttpParams } from '@angular/common/http';
import { TipoOrdenacaoEnum } from 'src/app/comum/enuns/tipoOrdenacao.enum';

export class CampanhaFiltroRequest {

    constructor(public nome: string,
        public statusCampanha: number,
        public empresaId: string,
        public tipoOrdenacao: TipoOrdenacaoEnum,
        public campoOrdenacao: string,
        public pular: number,
        public quantidade: number) { }

    alterarNome(nome: string) {
        this.nome = nome;
    }
    alterarStatusCampanha(statusCampanha: number) {
        this.statusCampanha = statusCampanha;
    }
    alterarEmpresaId(empresaId: string) {
        this.empresaId = empresaId;
    }
    alterarPular(pular: number) {
        this.pular = pular;
    }
    alterarQuantidade(quantidade: number) {
        this.quantidade = quantidade;
    }
    queryString() {
        return new HttpParams({ fromString: JSON.stringify(this) });
    }

        
}
