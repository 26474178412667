import { EnumValue } from "src/app/comum/models/enumValue";
import { Empresa } from "src/app/empresa/models/empresa";

export class TermoAceiteCampanhaDataModel {

    constructor(public campanhaId: string, public campanhaFuncionarioId: string)
    {

    }
}
