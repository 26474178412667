export enum TipoLogradouroEnum {
    Alameda = 1,
    Avenida = 2,
    Chacara = 3,
    Colonia = 4,
    Condominio = 5,
    Eqnp = 6,
    Estancia = 7,
    Estrada = 8,
    Fazenda = 9,
    Praca = 9,
    Prolongamento = 10,
    Rodovia = 11,
    Rua = 12,
    Sitio = 13,
    Travessa = 14,
    Vicinal = 15,
}
