import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MessageDialogComponent } from './message-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MessageDialogService } from './message-dialog.service';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressBarModule,
        FlexLayoutModule,
        MatIconModule
    ],
    providers: [MessageDialogService],
    declarations: [MessageDialogComponent],
    exports: [MessageDialogComponent]
})
export class MessageDialogModule { }
