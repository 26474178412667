import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CampanhaTotalizacaoService } from 'src/app/campanha-totalizacao/campanha-totalizacao.service';
import { CampanhaTotalizacaoAgrupada } from 'src/app/campanha-totalizacao/models/campanhaTotalizacaoAgrupada';
import { DrawerMenuItemAtual } from 'src/app/comum/componentes/drawer-menu/drawer-menu-item-atual';
import { TipoOrdenacaoEnum } from 'src/app/comum/enuns/tipoOrdenacao.enum';
import { RouterService } from 'src/app/seguranca/router-service';
import { TermoAceiteCampanhaDataModel } from 'src/app/termo-aceite/models/termoAceiteCampanhaDataModel';
import { TermoAceiteAnexoCampanhaModalComponent } from 'src/app/termo-aceite/termo-aceite-anexo-campanha-modal/termo-aceite-anexo-campanha-modal.component';

import { NOME_FILTRO_CAMPANHA } from '../../app.config';
import { MovimentoEnum } from '../../comum/enuns/movimento.enum';
import { LocalStorageService } from '../../comum/services/local-storage.service';
import { DialogActionYesAndNo, DialogResponseYes } from '../../message-dialog/message-dialog-action';
import { MessageDialogService } from '../../message-dialog/message-dialog.service';
import { CampanhaFiltroModalComponent } from '../campanha-filtro-modal/campanha-filtro-modal.component';
import { CampanhaService } from '../campanha.service';
import { Campanha } from '../models/campanha';
import { CampanhaFiltro } from '../models/campanha-filtro';
import { CampanhaFiltroRequest } from '../models/campanha-filtro-request';
import { CampanhaListaUsuario } from '../models/campanha-lista-usuario';

@Component({
    selector: 'app-campanha-lista',
    templateUrl: './campanha-lista.component.html',
    styleUrls: ['./campanha-lista.component.scss']
})
export class CampanhaListaComponent implements OnInit, AfterViewInit {

    @ViewChild(MatSort) sort: MatSort;
    colunas = ['nome', 'empresa', 'statusCampanha', 'inicioCampanha', 'fimCampanha', 'saldoDisponivel', 'saldoProcessandoRetirada', 'saldoRetirado', 'acoes'];
    campanhaDataSource: MatTableDataSource<CampanhaListaUsuario>;
    campanhas: CampanhaListaUsuario[];
    campanhaFiltro: CampanhaFiltroRequest;
    MovimentoEnum = MovimentoEnum;
    campanhasTotalizacoesAgrupadas: CampanhaTotalizacaoAgrupada;
    permiteBuscarTotalizador: boolean = false;
    isMobile: MediaQueryList;
    private mobileListener: () => void;
    visaoMobile: boolean;

    constructor(private campanhaService: CampanhaService,
        private snackBar: MatSnackBar,
        private dialogService: MessageDialogService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private campanhaTotalizacaoService: CampanhaTotalizacaoService,
        private router: Router,
        private changeDetector: ChangeDetectorRef, 
        matcher: MediaMatcher,
        private routerService: RouterService) {
        this.isMobile = matcher.matchMedia('(max-width: 800px)');
        this.mobileListener = () => changeDetector.detectChanges();
        this.isMobile.addEventListener('change', this.mobileListener);
        this.isMobile.onchange = (ev: MediaQueryListEvent) => {
            if (!ev.matches) {
                this.visaoMobile = false;
            }
            else {
                this.visaoMobile = true;
            }
        };
        this.visaoMobile = this.isMobile.matches;
    }

    ngOnInit() {

        this.activatedRoute.data.subscribe(data => {
            this.campanhaDataSource = new MatTableDataSource<CampanhaListaUsuario>(data.campanhas.data);
            this.campanhaDataSource.sort = this.sort;
            this.campanhas = data.campanhas.data;
        });

        var filtro = this.localStorageService.recuperarItem<CampanhaFiltro>(NOME_FILTRO_CAMPANHA);
        this.campanhaFiltro = new CampanhaFiltroRequest(filtro ? filtro.nome : undefined,
            filtro ? filtro.statusCampanha : undefined,
            filtro && filtro.empresa ? filtro.empresa : undefined,
            TipoOrdenacaoEnum.Decrescente, "FimCampanha", 0, 50);
    }

    ngAfterViewInit() {
        this.campanhaDataSource.sort = this.sort;
        this.permiteBuscarTotalizador = true;
        this.campanhaDataSource = new MatTableDataSource<CampanhaListaUsuario>(this.campanhas);
        setTimeout(() => { this.routerService.alterarRota(new DrawerMenuItemAtual("Suas campanhas", null, "", false, false, null))}, 0);

    }

    deletar(campanha: Campanha) {
        const dialog = this.dialogService.open('Excluir', `Deseja realmente excluir o campanha '${campanha.nome}'?`, DialogActionYesAndNo);
        dialog.afterClosed().subscribe(dialogResponse => {
            if (dialogResponse === DialogResponseYes) {
                this.campanhaService.deletar(campanha.id).then(response => {
                    this.snackBar.open(`O campanha ${campanha.nome} foi excluido com sucesso!`, `Ok`, { duration: 3000 });
                    this.campanhaFiltro.alterarPular(0);
                    this.campanhaFiltro.alterarQuantidade(this.campanhas.length);
                    this.buscarPorFiltro(this.campanhaFiltro, false);
                });
            }
        });
    }

    filtrar() {

        this.campanhaFiltro.alterarPular(0);
        this.campanhaFiltro.alterarQuantidade(5);

        this.buscarPorFiltro(this.campanhaFiltro, false);
    }

    buscarMais() {

        this.campanhaFiltro.alterarPular(this.campanhas.length);
        this.campanhaFiltro.alterarQuantidade(5);

        this.buscarPorFiltro(this.campanhaFiltro, true);

    }

    buscarPorFiltro(campanhaFiltro: CampanhaFiltroRequest, acrescentar: boolean) {
        this.campanhaService.recuperarPorFiltro(campanhaFiltro).then(response => {
            if (acrescentar) {
                response.data.forEach(banco => {
                    this.campanhas.push(banco);
                });
                this.campanhaDataSource.data = this.campanhas;

                return;
            }
            this.campanhas = response.data ? response.data : [];
            this.campanhaDataSource = new MatTableDataSource(this.campanhas ? this.campanhas : []);
        });
    }


    abrirFiltro() {

        const dialogRef = this.dialog.open(CampanhaFiltroModalComponent, {
            width: '600px',
            data: this.localStorageService.recuperarItem(NOME_FILTRO_CAMPANHA)
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const filtro = this.localStorageService.recuperarItem<CampanhaFiltro>(NOME_FILTRO_CAMPANHA);
                this.campanhaFiltro.alterarNome(filtro.nome);
                this.campanhaFiltro.alterarStatusCampanha(filtro.statusCampanha);
                this.campanhaFiltro.alterarEmpresaId(filtro.empresa ? filtro.empresa : null);
                this.filtrar();
            } else {
                this.snackBar.open(`Filtro cancelado!`,
                    `Ok`, { duration: 3000 });
            }
        });
    }

    getStatusIcon(campanha: CampanhaListaUsuario) {

        switch (campanha.statusCampanha.value) {

            case 'Rascunho':
                return "receipt";
            case 'Vigente':
                return "play_circle";
            case 'Interrompida':
                return "pause";
            case 'Encerrando':
                return "pending_actions";
            case 'Totalizada':
                return "calculate";
            case 'AguardandoFundos':
                return "pending_actions";
            case 'AguardandoLiberacao':
                return "lock_open";
            case 'LiquidacaoLiberada':
                return "qr_code_scanner";
            case 'Liquidado':
                return "price_check";
            case 'Finalizada':
                return "done_all";
            case 'Cancelada':
                return "cancel"
        }
    }

    getStatusColor(campanha: CampanhaListaUsuario) {

        switch (campanha.statusCampanha.value) {

            case 'Rascunho':
                return "";
            case 'Vigente':
                return "primary";
            case 'Interrompida':
                return "accent";
            case 'Encerrando':
                return "primary";
            case 'Totalizada':
                return "primary";
            case 'AguardandoFundos':
                return "primary";
            case 'AguardandoLiberacao':
                return "primary";
            case 'LiquidacaoLiberada':
                return "primary";
            case 'Liquidado':
                return "primary";
            case 'Finalizada':
                return "primary";
            case 'Cancelada':
                return "warn"
        }
    }

    exibirTermoAceite(campanha: CampanhaListaUsuario) {

        switch (campanha.statusCampanha.value) {

            case 'Rascunho':
                return false;
            case 'Vigente':
                return true;
            case 'Interrompida':
                return true;
            case 'Encerrando':
                return false;
            case 'Totalizada':
                return false;
            case 'AguardandoFundos':
                return false;
            case 'AguardandoLiberacao':
                return false;
            case 'LiquidacaoLiberada':
                return false;
            case 'Liquidado':
                return false;
            case 'Finalizada':
                return false;
            case 'Cancelada':
                return false
        }
    }

    // async aceitarTermo(campanha: CampanhaListaUsuario) {

    //     this.campanhaService.recuperarTermoAceiteCampanhaPorUsuario(campanha.id, campanha.campanhaFuncionarioId)
    //         .subscribe(response => {
    //             var contentDisposition = response.headers.get('content-disposition');
    //             var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
    //             var file = new Blob([response.body], { type: 'application/pdf' });
    //             var fileURL = URL.createObjectURL(file);
    //             window.open(fileURL); // if you want to open it in new tab
    //             var a = document.createElement('a');
    //             a.href = fileURL;
    //             a.target = '_blank';
    //             a.download = filename;
    //             document.body.appendChild(a);
    //             a.click();
    //         });
    // }

    async abrirTermoAceite(campanha: CampanhaListaUsuario) {

        const ref = this.dialog.open(TermoAceiteAnexoCampanhaModalComponent, {
            width: '95vw',
            disableClose: false,
            hasBackdrop: true,
            data: new TermoAceiteCampanhaDataModel(campanha.id, campanha.campanhaFuncionarioId)
        });

        ref.afterClosed().subscribe(async value => {
            if (value) {
                this.filtrar();
            } else {
                this.snackBar.open(`Termos não foram aceitos!`,
                    `Ok`, { duration: 3000 });
            }
        });
    }

    abrirCampanha(campanhaListaUsuario: CampanhaListaUsuario) {
        if (campanhaListaUsuario.termoAceito == true) {
            this.router.navigate(['/campanhas/compose'], { queryParams: { campanhaId: campanhaListaUsuario.id, campanhaFuncionarioId: campanhaListaUsuario.campanhaFuncionarioId }, queryParamsHandling: 'merge' });
        }
    }

}
