<div fxLayout="column" fxLayoutAlign="space-between stret" class="mat-boas-vindas-fundo"
  [ngClass]="{'mat-boas-vindas-fundo-mobile': isMobile.matches == true}">
  
  <div fxLayout="column" fxLayoutAlign="space-between stretch" class="mat-boas-vindas-background">
    <mat-toolbar color="primary" class="mat-elevation-z4" auto-hide>
      <mat-toolbar-row fxLayout="row" fxFlex fxLayoutAlign="space-between center">
        
        <div fxFlex fxLayoutAlign="end center">
          <button mat-icon-button [routerLink]="'/profile'">
            <mat-icon class="material-icons-round md-32">account_circle</mat-icon>
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div fxLayout="column" fxLayoutAlign="center center" class="mat-boas-vindas">
      <span>Olá, </span>
      <h1>{{this.usuarioModel != null ? this.usuarioModel.nome : ""}} </h1>
    </div>
    <mat-card class="mat-card-saldo" fxFlex>
      <div fxLayout="row" fxLayoutAlign="center space-around" fxLayoutGap="10px">
        <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" *ngIf="!this.contaDigitalInexistente">
          <h3 fxFlexAlign="center">Saldo disponivel</h3>
          <div fxLayout="row" fxLayoutAlign="center stretch">
            <span fxFlexAlign="center" style="color: #013729;font-size: 30px;"
              *ngIf="this.saldoCarteiraResponse != null">
              <strong>{{this.saldoCarteiraResponse.saldo
                |currency: 'BRL': 'symbol' :
                '1.2-2'}}</strong></span>
            <span fxFlexAlign="center" style="color: #013729;font-size: 30px;"
              *ngIf="this.saldoCarteiraResponse == null && this.carregandoSaldo == false">
              <strong>R$ --,--</strong></span>
            <mat-spinner *ngIf="this.saldoCarteiraResponse == null && this.carregandoSaldo == true" [strokeWidth]="3"
              [diameter]="40" fxFlexAlign="center"></mat-spinner>
            <button fxFlexAlign="center" mat-icon-button (click)="this.atualizarSaldo(true)" color="primary">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" >
            <button mat-stroked-button color="primary" routerLink="/movements">
              Extrato
            </button>
          </div>
          <mat-divider fxFlex></mat-divider>
          <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-lg="center start" fxLayoutGap="20px">
              <button mat-stroked-button class="button-pix" color="primary" routerLink="/pix/cashout">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <h2>Transferir</h2>
                  <mat-icon class="button-pix-icon">currency_exchange</mat-icon>
                </div>
              </button>
              <button mat-stroked-button class="button-pix" color="primary" routerLink="/pix/qrcode" *ngIf="this.usuarioModel.modalidadeRetirada == 1">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <h2>Pagar QrCode</h2>
                  <mat-icon class="button-pix-icon">qr_code_scanner</mat-icon>
                </div>
              </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-lg="center start" fxLayoutGap="20px" *ngIf="this.usuarioModel.modalidadeRetirada == 1">
              <button mat-stroked-button class="button-pix" color="primary" routerLink="/pix/payload">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <h2>Pix copia e cola</h2>
                  <mat-icon class="button-pix-icon">file_copy</mat-icon>
                </div>
              </button>
              <button mat-stroked-button class="button-pix" color="primary" routerLink="/pix-keys">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <h2>Minhas chaves</h2>
                  <mat-icon class="button-pix-icon">key</mat-icon>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div fxLayout="column" *ngIf="this.contaDigitalInexistente">
          <div fxLayout="column" fxLayoutAlign="start center">
            <div fxLayout="row" fxFlexAlign="center">
              <img fxAlign="start" class="img-responsive" src="assets/cartoes-removebg.png">
            </div>
            <div fxLayout="column" fxLayoutAlign="center strech">
              <h4 style="text-align: center;">Com a conta digital Monnera, você poderá receber seus pagamentos de
                desempenhos extraordinários das campanhas em que participa.</h4>
            </div>
            <button matTooltip="Criar conta" mat-raised-button color="primary" (click)="this.criarContaDigital()">
              Criar conta digital Monnera
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>