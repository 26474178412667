import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class QueryParamsService {

  constructor() { }

  gerarQuery(objeto: any): HttpParams {
    let params = new HttpParams();
    const keys = Object.keys(objeto);

    keys.forEach((key) => {
      const valor = objeto[key];
      if (valor !== null && valor !== undefined && valor.toString() !== '') {
        params = params.append(key, valor.toString());
      }
    });

    return params;
  }
}
