import { TermoAceite } from '../models/termoAceite';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';
import { MessageDialogService } from '../../message-dialog/message-dialog.service';
import { Validators } from '@angular/forms';
import { TermoAceiteService } from '../termo-aceite.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MovimentoConst } from '../../comum/consts/movimento.const';
import { DialogResponseYes, DialogActionYesAndNo } from '../../message-dialog/message-dialog-action';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { AlterarTermoAceiteRequest } from '../models/alterarTermoAceiteRequest';
import { TipoTermoAceiteEnum } from 'src/app/comum/enuns/tipoTermoAceite.enum';
import { LoginService } from 'src/app/login/login.service';
import { UsuarioModel } from 'src/app/dominio/modelos/UsuarioModel';
import { TermoAceiteCampanhaDataModel } from '../models/termoAceiteCampanhaDataModel';
import { CampanhaService } from 'src/app/campanha/campanha.service';

@Component({
  selector: 'app-termo-aceite-anexo-campanha-modal',
  templateUrl: './termo-aceite-anexo-campanha-modal.component.html',
  styleUrls: ['./termo-aceite-anexo-campanha-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermoAceiteAnexoCampanhaModalComponent implements OnInit {


  termoAceito: boolean = false;
  termoAceiteCampanhaDataModel: TermoAceiteCampanhaDataModel;
  pdf: Uint8Array;
  nomeAnexo: string;

  constructor(private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private termoAceiteService: TermoAceiteService,
    public location: Location,
    public loginService: LoginService,
    private readonly ref: MatDialogRef<TermoAceiteAnexoCampanhaModalComponent>,
    private campanhaService: CampanhaService,
    @Inject(MAT_DIALOG_DATA) private readonly data: TermoAceiteCampanhaDataModel,
    private dialogService: MessageDialogService) {
  }

  ngOnInit() {
    this.termoAceiteCampanhaDataModel = this.data;



  }

  aceitarTermos() {
    this.campanhaService.aceitarTermo(this.termoAceiteCampanhaDataModel.campanhaId, this.termoAceiteCampanhaDataModel.campanhaFuncionarioId)
      .subscribe(async response => {
        this.ref.close(true);
      })
  }

  recusarTermos() {
    const dialog = this.dialogService.open('Recusar termos', `Ao recusar os termos, você não poderá mais fazer parte desta campanha, deseja continuar?`, DialogActionYesAndNo);
    dialog.afterClosed().subscribe(dialogResponse => {
      if (dialogResponse === DialogResponseYes) {
        this.campanhaService.recusarTermo(this.termoAceiteCampanhaDataModel.campanhaId, this.termoAceiteCampanhaDataModel.campanhaFuncionarioId)
          .subscribe(async response => {
            this.ref.close(true);
          })
      }
    });
  }


  async baixar() {
    this.campanhaService.recuperarTermoAceiteCampanhaPorUsuario(this.termoAceiteCampanhaDataModel.campanhaId, this.termoAceiteCampanhaDataModel.campanhaFuncionarioId)
      .subscribe(response => {
        var contentDisposition = response.headers.get('content-disposition');
        this.nomeAnexo = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        this.pdf = new Uint8Array(response.body);

        var file = new Blob([this.pdf], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL); // if you want to open it in new tab
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = this.nomeAnexo;
        document.body.appendChild(a);
        a.click();
      });
  }

  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }
}
