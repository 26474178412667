import { Injectable, EventEmitter } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DrawerMenuItem } from "../comum/componentes/drawer-menu/drawer-menu-item";
import { DrawerMenuItemAtual } from "../comum/componentes/drawer-menu/drawer-menu-item-atual";
import { UsuarioModel } from "../dominio/modelos/UsuarioModel";
import { LoginModalComponent } from "../login/login-modal/login-modal.component";

@Injectable()
export class RouterService {
    
    routeEvent : EventEmitter<DrawerMenuItemAtual>;
    disableDigitalAccountEvent : EventEmitter<boolean>;
    isOpened: boolean;

    constructor(private router : Router,
        private dialog: MatDialog) {
        this.routeEvent = new EventEmitter<DrawerMenuItemAtual>();
        this.disableDigitalAccountEvent = new EventEmitter<boolean>();
    }

    alterarRota(drawerMenuItemAtual: DrawerMenuItemAtual){
        this.routeEvent.emit(drawerMenuItemAtual)
    }

    desabilitarRota(desabilitar: boolean){
        this.disableDigitalAccountEvent.emit(desabilitar)
    }
}

