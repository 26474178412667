
import { Routes, RouterModule } from '@angular/router';
import { GeneralRouteGuard } from '../seguranca/general-route-guard';
import { NgModule } from '@angular/core';
import { UsuarioProfileComponent } from './usuario-profile/usuario-profile.component';
import { UsuarioProfileResolver } from './usuario-profile/usuario-profile.resolver';

const routes: Routes =
    [
        {
            path: '',
            component: UsuarioProfileComponent,
            canActivate: [GeneralRouteGuard],
            resolve:
            {
                usuarioDetalhado: UsuarioProfileResolver
            }
        }
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class UsuarioRoutingModule { }
