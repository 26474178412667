import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ProdutoService } from '../produto.service';
import { ProdutoFiltro } from '../models/produto-filtro';
import { LocalStorageService } from '../../comum/services/local-storage.service';
import { NOME_FILTRO_PRODUTO } from '../../app.config';
import { MovimentoEnum } from '../../comum/enuns/movimento.enum';
import { ProdutoFiltroRequest } from '../models/produto-filtro-request';

@Injectable()
export class ProdutoListaResolver implements Resolve<any> {
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let produtoFiltro: ProdutoFiltro;
        produtoFiltro = this.localStorageService.recuperarItem<ProdutoFiltro>(NOME_FILTRO_PRODUTO);
        let produtoFiltroRequest = new ProdutoFiltroRequest(produtoFiltro ? produtoFiltro.nome : undefined, 
                                      produtoFiltro ? produtoFiltro.codigo : undefined,
                                      produtoFiltro ? produtoFiltro.referencia : undefined,
                                      produtoFiltro && produtoFiltro.categoria ? produtoFiltro.categoria.id : undefined,
                                      produtoFiltro && produtoFiltro.grupo ? produtoFiltro.grupo.id : undefined,
                                      produtoFiltro && produtoFiltro.subGrupo ? produtoFiltro.subGrupo.id : undefined,
                                      produtoFiltro && produtoFiltro.fabricante ? produtoFiltro.fabricante.id : undefined,
                                      produtoFiltro ? produtoFiltro.ncm : undefined,
                                      produtoFiltro && produtoFiltro.unidadeMedida ? produtoFiltro.unidadeMedida.id : undefined,
                                      produtoFiltro && produtoFiltro.empresa ? produtoFiltro.empresa : undefined,
                                      undefined, undefined, 0, 10);

        return this.produtoService.recuperarPorFiltro(produtoFiltroRequest).then(registros => {
            return registros;
        });
    }

    constructor(private produtoService: ProdutoService,
                private localStorageService: LocalStorageService) {

    }
}

