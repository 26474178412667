import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { ComponentesModule } from '../comum/componentes/componentes.module';
import { UsuarioContaBancariaModule } from '../usuario-conta-bancaria/usuario-conta-bancaria.module';
import { UsuarioContaDigitalAlterarSenhaComponent } from './usuario-conta-digital-alterar-senha/usuario-conta-digital-alterar-senha.component';
import { UsuarioContaDigitalAutenticacaoComponent } from './usuario-conta-digital-autenticacao/usuario-conta-digital-autenticacao.component';
import { UsuarioContaDigitalDetalheComponent } from './usuario-conta-digital-detalhe/usuario-conta-digital-detalhe.component';
import { UsuarioContaDigitalDetalheResolver } from './usuario-conta-digital-detalhe/usuario-conta-digital-detalhe.resolver';
import { UsuarioContaDigitalDocumentoComponent } from './usuario-conta-digital-documento/usuario-conta-digital-documento.component';
import { UsuarioContaDigitalSenhaComponent } from './usuario-conta-digital-senha/usuario-conta-digital-senha.component';
import { UsuarioContaDigitalRoutingModule } from './usuario-conta-digital.routing';
import { UsuarioContaDigitalService } from './usuario-conta-digital.service';

@NgModule({
    declarations: [UsuarioContaDigitalDetalheComponent, UsuarioContaDigitalDocumentoComponent, UsuarioContaDigitalSenhaComponent, UsuarioContaDigitalAlterarSenhaComponent, UsuarioContaDigitalAutenticacaoComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatInputModule,
        FlexLayoutModule,
        MatTooltipModule,
        MatToolbarModule,
        MatMenuModule,
        NgxMaskModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatListModule,
        ComponentesModule,
        UsuarioContaDigitalRoutingModule,
        UsuarioContaBancariaModule,
        MatBottomSheetModule
    ],
    providers: [
        UsuarioContaDigitalService,
        UsuarioContaDigitalDetalheResolver
    ],
    exports: [UsuarioContaDigitalDetalheComponent, UsuarioContaDigitalDocumentoComponent, UsuarioContaDigitalSenhaComponent, UsuarioContaDigitalAlterarSenhaComponent, UsuarioContaDigitalAutenticacaoComponent]
})
export class UsuarioContaDigitalModule { }
