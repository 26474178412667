import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication-service';

@Injectable()
export class GeneralRouteGuard implements CanActivate {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> { 
        const authenticated = this.authenticationService.authenticated();  
        this.authenticationService.authenticatedEvent.emit(authenticated);
        if(!authenticated){
            this.authenticationService.deauthenticate();
            return false;
        }
        return true;
    }

    constructor(private authenticationService: AuthenticationService, private readonly router: Router) {
    }
}
