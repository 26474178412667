import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { CampanhaRemuneracaoDesempenho } from './models/campanhaRemuneracaoDesempenho'
import { InserirCampanhaRemuneracaoDesempenhoRequest } from './models/inserirCampanhaRemuneracaoDesempenhoRequest';
import { AlterarCampanhaRemuneracaoDesempenhoRequest } from './models/alterarCampanhaRemuneracaoDesempenhoRequest';

@Injectable({
  providedIn: 'root'
})
export class CampanhaRemuneracaoDesempenhoService {

    constructor(private readonly http: HttpClient, 
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    async recuperarPorCampanhaRemuneracao(idCampanha: string, idCampanhaRemuneracao: string) {
        return await this.http.get<ResponseData<CampanhaRemuneracaoDesempenho[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/desempenhos`).toPromise();
    }
    
    incluir(idCampanha: string, idCampanhaRemuneracao: string, inserirCampanhaRemuneracaoDesempenhoRequest: InserirCampanhaRemuneracaoDesempenhoRequest) {
        return this.http.post<ResponseData<CampanhaRemuneracaoDesempenho>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/desempenhos`, inserirCampanhaRemuneracaoDesempenhoRequest);
    }

    alterar(idCampanha: string, idCampanhaRemuneracao: string, idCampanhaRemuneracaoDesempenho: string, alterarCampanhaRemuneracaoDesempenhoRequest: AlterarCampanhaRemuneracaoDesempenhoRequest) {
        return this.http.put<ResponseData<CampanhaRemuneracaoDesempenho>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/desempenhos/${idCampanhaRemuneracaoDesempenho}`, alterarCampanhaRemuneracaoDesempenhoRequest);
    }

    excluir(idCampanha: string, idCampanhaRemuneracao: string, idCampanhaRemuneracaoDesempenho: string) {
        return this.http.delete(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/desempenhos/${idCampanhaRemuneracaoDesempenho}`);
    }

}
