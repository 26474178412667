import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { UsuarioFuncionario } from './models/usuarioFuncionario';
import { InserirUsuarioFuncionarioRequest } from './models/inserirUsuarioFuncionarioRequest';

@Injectable({
  providedIn: 'root'
})
export class UsuarioFuncionarioService {

    constructor(private readonly http: HttpClient, 
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    recuperarPorUsuario(idUsuario: string) {
        return this.http.get<ResponseData<UsuarioFuncionario[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/${idUsuario}/funcionarios`);
    }

    incluir(idUsuario: string, inserirUsuarioFuncionarioRequest: InserirUsuarioFuncionarioRequest) {
        return this.http.post<ResponseData<UsuarioFuncionario>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/${idUsuario}/funcionarios`, inserirUsuarioFuncionarioRequest);
    }

    excluir(idUsuario: string, idUsuarioFuncionario: string) {
        return this.http.delete(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/${idUsuario}/funcionarios/${idUsuarioFuncionario}`);
    }
}
