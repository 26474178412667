import { Component, OnInit, Input, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@Component({
  selector: 'app-busca-mais',
  templateUrl: './busca-mais.component.html',
  styleUrls: ['./busca-mais.component.scss'],
  inputs : ['descricao']
})
export class BuscaMaisComponent implements OnInit {

  @Input('descricao')
  public descricao: string;

  constructor() { }

  ngOnInit() {
  }

}
