<div fxLayout="column" fxLayoutAlign="start strech" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="center center">
    <h2><strong>{{this.campanhaListaUsuario.nome}}</strong></h2>
  </div>
  <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.campanhaListaUsuario.saldoBloqueado > 0">
      <h4 style="color: red">Saldo bloqueado</h4>
      <h3 style="color: red"><strong>{{this.campanhaListaUsuario.saldoBloqueado |currency: 'BRL': 'symbol' :
          '1.2-2'}}</strong></h3>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.campanhaListaUsuario.saldoDisponivel > 0 || (this.campanhaListaUsuario.saldoBloqueado == 0 && this.campanhaListaUsuario.saldoProcessandoRetirada == 0 && this.campanhaListaUsuario.saldoRetirado == 0)">
      <h4 style="color: green">Saldo disponivel</h4>
      <h3 style="color: green"><strong>{{this.campanhaListaUsuario.saldoDisponivel |currency: 'BRL': 'symbol' :
          '1.2-2'}}</strong></h3>
      <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.campanhaListaUsuario.saldoDisponivel > 0">
        <button mat-raised-button color="primary" (click)="this.sacar(campanhaListaUsuario)">
          Retirar saldo <mat-icon>attach_money</mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.campanhaListaUsuario.saldoProcessandoRetirada > 0">
      <h4 style="color: orange">Saldo em retirada</h4>
      <h3 style="color: orange"><strong>{{this.campanhaListaUsuario.saldoProcessandoRetirada |currency: 'BRL': 'symbol'
          : '1.2-2'}}</strong></h3>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.campanhaListaUsuario.saldoRetirado > 0">
      <h4 style="color: blue">Saldo retirado</h4>
      <h3 style="color: blue"><strong>{{this.campanhaListaUsuario.saldoRetirado |currency: 'BRL': 'symbol' :
          '1.2-2'}}</strong></h3>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center start">
    <div fxLayout="row" fxFlex="95">
      <app-campanha-funcionario-meta-lista
        [campanhasFuncionariosMetasTotalizacoes]="this.campanhasFuncionariosMetasTotalizacoes"
        [campanha]="this.campanhaListaUsuario" fxFlex>
      </app-campanha-funcionario-meta-lista>
    </div>
  </div>
  <br />
</div>