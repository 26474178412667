import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, Inject, TemplateRef, AfterContentInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { BENEFICIO_URL } from './app.config';
import { DrawerMenuItem } from './comum/componentes/drawer-menu/drawer-menu-item';
import { UsuarioSenhaModalComponent } from './usuario/usuario-senha-modal/usuario-senha-modal.component';
import { UsuarioService } from './usuario/usuario.service';
import { debounceTime, delay, map } from 'rxjs/operators';
import { AuthenticationService } from './seguranca/authentication-service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, pipe } from 'rxjs';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { LoaderService } from './comum/services/loader.service';
import { RouterService } from './seguranca/router-service';
import { DrawerMenuItemAtual } from './comum/componentes/drawer-menu/drawer-menu-item-atual';
import { debounce } from 'rxjs-compat/operator/debounce';
import { slideInBottom, slideInTop, slider } from './app.animating';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [slider, slideInBottom, slideInTop]

})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {

    @ViewChild('sidenav') sidenav: MatSidenav;
    isMobile: MediaQueryList;
    mobileListener: () => void;
    filiais: any[];
    rotaDescricao: string;
    rota: string;
    showItemDescription: boolean;
    menuItens: DrawerMenuItem[];
    menuTabs: DrawerMenuItem[];
    userProfile: any;
    modulos: any[];
    menus: any[];
    moduloSelecionado: any;
    menuSelecionado: string;
    public menuSelecionadoIndex: number;
    loading: boolean = false;
    isAuthenticated: boolean;
    menuFixado: boolean = false;
    isOpened: boolean;
    background: ThemePalette = undefined;
    public loadingTemplate!: TemplateRef<any>;
    public config = {
        animationType: ngxLoadingAnimationTypes.doubleBounce,
        primaryColour: "#013729",
        secondaryColour: "#013729",
        tertiaryColour: "#013729",
        backdropBorderRadius: '10px',
    };
    drawerMenuItemAtual: DrawerMenuItemAtual;


    constructor(public authService: AuthenticationService,
        public routerService: RouterService,
        private usuarioService: UsuarioService,
        private _bottomSheet: MatBottomSheet,
        changeDetector: ChangeDetectorRef,
        matcher: MediaMatcher,
        private dialog: MatDialog,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private readonly snackBar: MatSnackBar,
        private _loading: LoaderService
    ) {
        this.isMobile = matcher.matchMedia('(max-width: 800px)');
        this.mobileListener = () => changeDetector.detectChanges();
        this.isMobile.addEventListener('change', this.mobileListener);
        this.isMobile.onchange = (ev: MediaQueryListEvent) => {
            if (!ev.matches && this.menuFixado == true) {
                //this.sidenav.open();
            }
            else {
                //this.sidenav.close();
            }
        };
    }

    menuToggle() {
        //this.sidenav.toggle();
    }

    carregarMenus() {
        this.menuItens = [
            new DrawerMenuItem("Remunerações", null, null, [
                new DrawerMenuItem("Inicio", "/home", "home", [], false),
                new DrawerMenuItem("Campanhas", "/campanhas", "campaign", [], false)
            ], false)
        ]

        this.menuTabs = [
            new DrawerMenuItem("Inicio", "/home", "home", [], false),
            new DrawerMenuItem("Campanhas", "/campanhas", "campaign", [], false),
            new DrawerMenuItem("Carteira", "/digitalAccount", "wallet", [], false)
        ]
    }

    ngOnInit(): void {
        this.carregarMenus();
        this.listenToLoading();
        this.authService.authenticatedEvent.subscribe(response => {
            this.isAuthenticated = response;
            if (this.isAuthenticated) {
                this.userProfile = this.authService.getSession();
                this.menuFixado = this.userProfile.menuFixado;
                if (!this.isMobile.matches && this.menuFixado == true) {
                    this.isOpened = true;
                }
                else {
                    this.isOpened = false;
                }
            }
        });

        this.routerService.routeEvent.subscribe(response => {
            setTimeout(() => { this.drawerMenuItemAtual = response; }, 0);
        });

        this.routerService.disableDigitalAccountEvent.subscribe(response => {
            setTimeout(() => { this.menuTabs[2].desabilitada = response }, 0);
        });

        this.router.events
            .subscribe(
                (event) => {
                    if (event instanceof NavigationStart) {
                        this.selecionarRota(event.url);
                    }
                });
    }

    ngAfterContentInit() {

    }
    public logout() {
        this.authService.deauthenticate();
        //this.sidenav.close();
    }

    ngOnDestroy(): void {
        this.isMobile.removeEventListener('change', this.mobileListener);
    }

    listenToLoading(): void {
        this._loading.isLoading
            .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
            .subscribe((loading) => {
                this.loading = loading;
            });
        //this.loading = true;
    }

    alterarSenha() {
        const dialogRef = this.dialog.open(UsuarioSenhaModalComponent, {
            width: '300px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.logout();
            } else {
                this.snackBar.open(`Alteração cancelado!`,
                    `Ok`, { duration: 3000 });
            }
        });
    }

    selecionarRota(rota: string) {
        for (let index = 0; index < this.menuTabs.length; index++) {
            if (rota.indexOf(this.menuTabs[index].rota) > -1) {
                this.menuSelecionadoIndex = index;
                this.menuSelecionado = this.menuTabs[index].rota;
            }
        }
    }

    selecionarMenu(index: number) {
        this.menuSelecionadoIndex = index;
        this.menuSelecionado = this.menuTabs[index].rota;
        this.router.navigate([this.menuSelecionado]);
    }

    fixarMenu(event: boolean) {
        let usuarioSessao = this.authService.getSession();
        usuarioSessao.menuFixado = event;
        this.menuFixado = event;
        this.authService.authenticate(usuarioSessao);
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']
    }
}
