import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     }

  public ngOnInit(): void {
  }

  onPositiveButtonClick(rep: string) {
    this.data.response = rep;
    this.dialogRef.close();
  }
}
