
export class InserirContaDigitalDocumentoRequest {
    constructor(
        public documentoId: string,
        public nome: string,
        public tipoDocumento: number,
        public documento: Blob)
    {

    }
}