import { LoginRouteGuard } from './seguranca/login-route-guard';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { GeneralRouteGuard } from './seguranca/general-route-guard';

const appRoutes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent, canActivate: [GeneralRouteGuard], data: { animation: 'home'}},
    {
        path: 'login', loadChildren: () => import('./login/login.module')
            .then(m => m.LoginModule), canActivate: [LoginRouteGuard]
    },
    {
        path: 'empresas', loadChildren: () => import('./empresa/empresa.module')
            .then(m => m.EmpresaModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'contratantes', loadChildren: () => import('./contratante/contratante.module')
            .then(m => m.ContratanteModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'categorias', loadChildren: () => import('./categoria/categoria.module')
            .then(m => m.CategoriaModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'cargos', loadChildren: () => import('./cargo/cargo.module')
            .then(m => m.CargoModule), canActivate: [GeneralRouteGuard]
    },

    {
        path: 'profile', loadChildren: () => import('./usuario/usuario.module')
            .then(m => m.UsuarioModule), canActivate: [GeneralRouteGuard], canLoad: []
    },
    {
        path: 'bankAccount', loadChildren: () => import('./usuario-conta-bancaria/usuario-conta-bancaria.module')
            .then(m => m.UsuarioContaBancariaModule), canActivate: [GeneralRouteGuard], canLoad: []
    },
    {
        path: 'digitalAccount', loadChildren: () => import('./usuario-conta-digital/usuario-conta-digital.module')
            .then(m => m.UsuarioContaDigitalModule), canActivate: [GeneralRouteGuard], canLoad: []
    },
    {
        path: 'pix-keys', loadChildren: () => import('./chave-pix/chave-pix.module')
            .then(m => m.ChavePixModule), canActivate: [GeneralRouteGuard], canLoad: []
    },
    {
        path: 'pix', loadChildren: () => import('./pix/pix.module')
            .then(m => m.PixModule), canActivate: [GeneralRouteGuard], canLoad: []
    },
    {
        path: 'movements', loadChildren: () => import('./usuario-carteira-digital/usuario-carteira-digital.module')
            .then(m => m.UsuarioCarteiraDigitalModule), canActivate: [GeneralRouteGuard], canLoad: []
    },
    {
        path: 'unidades-medidas', loadChildren: () => import('./unidade-medida/unidade-medida.module')
            .then(m => m.UnidadeMedidaModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'grupos', loadChildren: () => import('./grupo/grupo.module')
            .then(m => m.GrupoModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'sub-grupos', loadChildren: () => import('./sub-grupo/sub-grupo.module')
            .then(m => m.SubGrupoModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'produtos', loadChildren: () => import('./produto/produto.module')
            .then(m => m.ProdutoModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'fabricantes', loadChildren: () => import('./fabricante/fabricante.module')
            .then(m => m.FabricanteModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'funcionarios', loadChildren: () => import('./funcionario/funcionario.module')
            .then(m => m.FuncionarioModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'remuneracoes', loadChildren: () => import('./remuneracao/remuneracao.module')
            .then(m => m.RemuneracaoModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'campanhas', loadChildren: () => import('./campanha/campanha.module')
            .then(m => m.CampanhaModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'vendas', loadChildren: () => import('./venda/venda.module')
            .then(m => m.VendaModule), canActivate: [GeneralRouteGuard]
    },
    {
        path: 'erro-onboarding', loadChildren: () => import('./pagina-feedback/pagina-erro/pagina-erro.module')
            .then(m => m.PaginaErroModule), canActivate: []
    },
    {
        path: 'sucesso-onboarding', loadChildren: () => import('./pagina-feedback/pagina-sucesso/pagina-sucesso.module')
            .then(m => m.PaginaSucessoModule), canActivate: []
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' })
    ], exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
    constructor() {
    }
}
