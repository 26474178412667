import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuscaMaisComponent } from './busca-mais/busca-mais.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FiltroDataComponent } from './filtro-data/filtro-data.component';
import { FiltroDataModalComponent } from './filtro-data-modal/filtro-data-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UploadComponent } from './upload/upload.component';
import { DialogComponent } from './upload/dialog/dialog.component';
import { UploadService } from './upload/upload.service';
import { DrawerMenuComponent } from './drawer-menu/drawer-menu.component';
import { RouterModule, RouterLink } from '@angular/router';
import { LoaderComponent } from './loading/loader.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatOptionModule,
        MatDividerModule,
        MatSelectModule,
        MatIconModule,
        MatMenuModule,
        MatDatepickerModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatChipsModule,
        MatToolbarModule,
        MatDialogModule,
        MatListModule,
        MatProgressBarModule,
        RouterModule,
        MatBottomSheetModule,
        RouterModule,
        PdfViewerModule,
        MatProgressSpinnerModule, 
        MatTabsModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: "rgba(0,0,0,0.1)",
            backdropBorderRadius: "4px",
            primaryColour: "#ffffff",
            secondaryColour: "#ffffff",
            tertiaryColour: "#ffffff",
        }),
    ],
    declarations: [BuscaMaisComponent, FiltroDataComponent, FiltroDataModalComponent, UploadComponent, DialogComponent, DrawerMenuComponent, LoaderComponent, PdfViewerComponent],
    exports: [BuscaMaisComponent, FiltroDataComponent, FiltroDataModalComponent, UploadComponent, DialogComponent, DrawerMenuComponent, LoaderComponent, PdfViewerComponent],
    providers: [UploadService]
})
export class ComponentesModule { }
