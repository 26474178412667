import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { CampanhaTotalizacao } from './models/campanhaTotalizacao';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { CampanhaTotalizacaoAgrupada } from './models/campanhaTotalizacaoAgrupada';
import { CampanhaTotalizacaoItem } from './models/campanhaTotalizacaoItem';

@Injectable({
    providedIn: 'root'
})
export class CampanhaTotalizacaoService {

    constructor(private readonly http: HttpClient,
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    recuperarPorCampanha(idCampanha: string) {
        return this.http.get<ResponseData<CampanhaTotalizacao[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/totalizacoes/agrupadas-remuneracoes`);
    }
}
