import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TermoAceiteService } from './termo-aceite.service';
import { MaterialModule } from '../material/material.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ComponentesModule } from '../comum/componentes/componentes.module';
import { TermoAceiteAnexoModalComponent } from './termo-aceite-anexo-modal/termo-aceite-anexo-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TermoAceiteAnexoCampanhaModalComponent } from './termo-aceite-anexo-campanha-modal/termo-aceite-anexo-campanha-modal.component';

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatInputModule,
        RouterModule,
        FormsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatOptionModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatDialogModule,
        PdfViewerModule,
        ComponentesModule,
        MatCheckboxModule
    ],
    declarations: [TermoAceiteAnexoModalComponent, TermoAceiteAnexoCampanhaModalComponent],
    providers: [TermoAceiteService],
    exports: [
        TermoAceiteAnexoModalComponent,
        TermoAceiteAnexoCampanhaModalComponent
    ]
})
export class TermoAceiteModule { }
