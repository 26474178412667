import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonpClientBackend } from '@angular/common/http';

@Injectable()
export class ViaCepService {
  constructor(private http: HttpClient) { }

  public recuperar(cep: string) {
    return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`).toPromise();
  }
}
