import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FabricanteService } from '../fabricante.service';
import { InserirFabricanteRequest } from '../models/inserirFabricanteRequest';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { Empresa } from 'src/app/empresa/models/empresa';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';

@Component({
    selector: 'app-fabricante-novo-modal',
    templateUrl: './fabricante-novo-modal.component.html',
    styleUrls: ['./fabricante-novo-modal.component.scss']
})
export class FabricanteNovoModalComponent implements OnInit {

    fabricanteForm: UntypedFormGroup;
    empresas: ComboBoxItem[];

    constructor(private readonly formBuilder: UntypedFormBuilder,
        private readonly fabricanteService: FabricanteService,
        private readonly empresaService: EmpresaService,
        private readonly ref: MatDialogRef<FabricanteNovoModalComponent>,
        private snackBar: MatSnackBar) {

    }

    ngOnInit() {

        this.fabricanteForm = this.formBuilder.group({
            nomeRazaoSocial: [null, [Validators.required]],
            nomeFantasia: [null],
            email: [null, [Validators.email]],
            cpfCnpj: [null, [Validators.maxLength(14)]],
            empresa: [null, [Validators.required]],
        });

        this.empresaService.listarVinculados()
            .then
            (response => {
                this.empresas = response.data;
                this.fabricanteForm.get("empresa").setValue(this.empresas.length == 1 ? this.empresas[0].id : null);
            });
    }

    salvar() {
        let inserirFabricanteRequest = new InserirFabricanteRequest(this.fabricanteForm.value.nomeRazaoSocial,
            this.fabricanteForm.value.nomeFantasia,
            this.fabricanteForm.value.cpfCnpj,
            this.fabricanteForm.value.email,
            this.fabricanteForm.value.empresa);

        this.fabricanteService
            .incluir(inserirFabricanteRequest)
            .subscribe(response => {
                this.snackBar.open(`Fabricante salvo com sucesso!`, `Ok`, { duration: 2000 });
                this.ref.close(response.data);
            });
    }

    displayFn(option: any): string {
        if (option === null || option === undefined) {
            return;
        }
        if (option.valor === undefined) {
            return option.descricao;
        }
        return option.valor;
    }
}
