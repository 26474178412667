export const TipoBairroConst =
    [
        { descricao: 'Bairro', value: 1 },
        { descricao: 'Bosque', value: 2 },
        { descricao: 'Chácara', value: 3 },
        { descricao: 'Conjunto', value: 4 },
        { descricao: 'Desmembramento', value: 5 },
        { descricao: 'Distrito', value: 6 },
        { descricao: 'Favela', value: 7 },
        { descricao: 'Fazenda', value: 8 },
        { descricao: 'Gleba', value: 9 },
        { descricao: 'Horto', value: 10 },
        { descricao: 'Jardim', value: 11 },
        { descricao: 'Loteamento', value: 12 },
        { descricao: 'Núcleo', value: 13 },
        { descricao: 'Parque', value: 14 },
        { descricao: 'Residencial', value: 15 },
        { descricao: 'Sítio', value: 16 },
        { descricao: 'Tropical', value: 17 },
        { descricao: 'Vila', value: 18 },
        { descricao: 'Zona', value: 19 },
        { descricao: 'Centro', value: 20 },
        { descricao: 'Setor', value: 11 },
    ]
