import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { CampanhaRemuneracaoExclusao } from './models/campanhaRemuneracaoExclusao';
import { InserirCampanhaRemuneracaoExclusaoRequest } from './models/inserirCampanhaRemuneracaoExclusaoRequest';

@Injectable({
    providedIn: 'root'
})
export class CampanhaRemuneracaoExclusaoService {

    constructor(private readonly http: HttpClient,
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    async recuperarPorCampanhaRemuneracao(idCampanha: string, idCampanhaRemuneracao: string) {
        return await this.http.get<ResponseData<CampanhaRemuneracaoExclusao[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/exclusoes`).toPromise();
    }

    async recuperar(idCampanha: string, idCampanhaRemuneracao: string, idCampanhaRemuneracaoExclusao: string) {
        return await this.http.get<ResponseData<CampanhaRemuneracaoExclusao[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/exclusoes/${idCampanhaRemuneracaoExclusao}`).toPromise();
    }

    incluir(idCampanha: string, idCampanhaRemuneracao: string, inserirCampanhaRemuneracaoExclusaoRequest: InserirCampanhaRemuneracaoExclusaoRequest) {
        return this.http.post<ResponseData<CampanhaRemuneracaoExclusao>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/exclusoes`, inserirCampanhaRemuneracaoExclusaoRequest);
    }

    excluir(idCampanha: string,idCampanhaRemuneracao: string, idCampanhaRemuneracaoExclusao: string) {
        return this.http.delete(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/exclusoes/${idCampanhaRemuneracaoExclusao}`);
    }
}
