import { Component, OnInit, Inject } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UsuarioContaBancariaService } from '../usuario-conta-bancaria.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InserirUsuarioContaBancariaRequest } from '../models/inserirUsuarioContaBancariaRequest';
import { Usuario } from 'src/app/usuario/models/usuario';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { TipoContaBancariaConst } from 'src/app/comum/consts/tipoContaBancaria.const';
import { BancoConst } from 'src/app/comum/consts/banco.const';

@Component({
    selector: 'app-usuario-conta-bancaria-novo-modal',
    templateUrl: './usuario-conta-bancaria-novo-modal.component.html',
    styleUrls: ['./usuario-conta-bancaria-novo-modal.component.scss']
})
export class UsuarioContaBancariaNovoModalComponent implements OnInit {

    usuarioContaBancariaForm: UntypedFormGroup;
    usuario: Usuario;
    bancos: Array<object>;
    tiposContasBancarias: Array<object>;


    constructor(private readonly formBuilder: UntypedFormBuilder,
        private readonly usuarioContaBancariaService: UsuarioContaBancariaService,
        private readonly ref: MatDialogRef<UsuarioContaBancariaNovoModalComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: Usuario) {

        this.usuario = data;
        this.usuarioContaBancariaForm = this.formBuilder.group({
            banco: [null, [Validators.required]],
            agencia: [null, [Validators.required]],
            agenciaDigito: [null],
            conta: [null, [Validators.required]],
            contaDigito: [null],
            tipoContaBancaria: [null, [Validators.required]],
            contaTerceiros: [null, [Validators.required]],
            nomeTitular: [null, [Validators.required]],
            cpfCnpj: [null, [Validators.required]]
        });

        this.tiposContasBancarias = TipoContaBancariaConst;
        this.bancos = BancoConst
    }

    ngOnInit() {

        this.usuarioContaBancariaForm
            .get('banco')
            .valueChanges
            .subscribe(value => {
                if (!value || typeof (value) !== 'string') {
                    return;
                }
                this.bancos = BancoConst.filter(data => data.nome.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                    data.descricao.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                    data.value.indexOf(value) > -1);
            });

        this.usuarioContaBancariaForm.get('contaTerceiros').valueChanges.subscribe(res => {
            this.aplicarValidacao(res);
        });
    }

    salvar() {
        let inserirUsuarioContaBancariaRequest = new InserirUsuarioContaBancariaRequest(
            this.usuarioContaBancariaForm.value.banco.value,
            this.usuarioContaBancariaForm.value.agencia,
            this.usuarioContaBancariaForm.value.agenciaDigito,
            this.usuarioContaBancariaForm.value.conta,
            this.usuarioContaBancariaForm.value.contaDigito,
            this.usuarioContaBancariaForm.value.tipoContaBancaria,
            this.usuarioContaBancariaForm.value.contaTerceiros,
            this.usuarioContaBancariaForm.value.nomeTitular,
            this.usuarioContaBancariaForm.value.cpfCnpj);

        this.usuarioContaBancariaService
            .incluir(inserirUsuarioContaBancariaRequest)
            .subscribe(response => {
                if (!response) {
                    return;
                }

                if (!response.success) {
                    return;
                }

                this.ref.close(true);
            });
    }

    displayFn(option: any): string {
        if (option === null || option === undefined) {
            return;
        }
        if (option.valor === undefined) {
            return option.descricao;
        }
        return option.valor;
    }

    displayBanco(option: any): string {
        if (option === null || option === undefined) {
            return;
        }

        return option.value + " - " + option.nome;
    }

    aplicarValidacao(contaTerceiros: number) {

        this.usuarioContaBancariaForm['controls'].nomeTitular.clearValidators();
        this.usuarioContaBancariaForm['controls'].cpfCnpj.clearValidators();

        if (contaTerceiros) {
            this.usuarioContaBancariaForm['controls'].nomeTitular.setValidators([Validators.required]);
            this.usuarioContaBancariaForm['controls'].cpfCnpj.setValidators([Validators.required]);
        }

        this.usuarioContaBancariaForm['controls'].nomeTitular.updateValueAndValidity();
        this.usuarioContaBancariaForm['controls'].cpfCnpj.updateValueAndValidity();

        this.usuarioContaBancariaForm.updateValueAndValidity();
    }
}
