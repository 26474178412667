import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CampanhaFuncionarioMetaAlterarModalComponent } from './campanha-funcionario-meta-alterar-modal/campanha-funcionario-meta-alterar-modal.component';
import { CampanhaFuncionarioMetaListaComponent } from './campanha-funcionario-meta-lista/campanha-funcionario-meta-lista.component';
import { CampanhaFuncionarioMetaNovoModalComponent } from './campanha-funcionario-meta-novo-modal/campanha-funcionario-meta-novo-modal.component';
import { CampanhaFuncionarioMetaService } from './campanha-funcionario-meta.service';
import * as HighchartsMore from 'highcharts/highcharts-more.src';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
    declarations: [CampanhaFuncionarioMetaListaComponent, CampanhaFuncionarioMetaAlterarModalComponent, CampanhaFuncionarioMetaNovoModalComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatInputModule,
        FlexLayoutModule,
        MatTooltipModule,
        MatToolbarModule,
        MatMenuModule,
        CurrencyMaskModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        ChartModule,
        MatDividerModule,
        MatChipsModule
    ],
    providers: [
        CampanhaFuncionarioMetaService,
        { 
            provide: HIGHCHARTS_MODULES, 
            useFactory: () => [HighchartsMore, HighchartsSolidGauge] 
        },
    ],
    exports: [CampanhaFuncionarioMetaListaComponent, CampanhaFuncionarioMetaNovoModalComponent, CampanhaFuncionarioMetaAlterarModalComponent]
})
export class CampanhaFuncionarioMetaModule { }
