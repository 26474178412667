<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxFlex fxLayoutAlign="center center">
      <h3 fxAlign="center">Termos e condições da campanha</h3> 
    </div>
  </div>

  <!-- <app-pdf-viewer [pdf]="pdf"></app-pdf-viewer> -->
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-checkbox [(ngModel)]="termoAceito" [checked]="false"></mat-checkbox>
    <span>Declaro que li e concordo com os <i>Termos de participação de campanha</i>.</span>
  </div>

  <mat-dialog-actions fxLayoutAlign="space-between center" fxLayoutGap="1rem">
    <div fxLayoutAlign="start center">
      <button mat-button id="buttonCancelar" fxLayoutAlign="start" (click)="this.baixar()">
        <span>Baixar</span>
      </button>
      <button mat-button color="warn" (click)="this.recusarTermos()" id="buttonCancelar">
        <span>Não aceito</span>
      </button>
    </div>

    <button mat-raised-button color="primary" (click)="this.aceitarTermos()" id="buttonSalvar" [disabled]="!termoAceito">
      <span>Aceito termos</span>
    </button>
  </mat-dialog-actions>
</div>