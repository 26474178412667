export class DrawerMenuItemAtual {
  constructor(
    public descricao: string,
    public rotaBackdrop: string,
    public icone: string,
    public ocultarTab: boolean,
    public ocultarToolbar: boolean,
    public queryParams: any) {
  }
}
