import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { NOME_FILTRO_PRODUTO } from '../../app.config';
import { MovimentoEnum } from '../../comum/enuns/movimento.enum';
import { LocalStorageService } from '../../comum/services/local-storage.service';
import { DialogActionYesAndNo, DialogResponseYes } from '../../message-dialog/message-dialog-action';
import { MessageDialogService } from '../../message-dialog/message-dialog.service';
import { ProdutoAlterarModalComponent } from '../produto-alterar-modal/produto-alterar-modal.component';
import { ProdutoFiltroModalComponent } from '../produto-filtro-modal/produto-filtro-modal.component';
import { ProdutoIncluirModalComponent } from '../produto-incluir-modal/produto-incluir-modal.component';
import { ProdutoService } from '../produto.service';
import { Produto } from '../models/produto';
import { ProdutoFiltro } from '../models/produto-filtro';
import { ProdutoFiltroRequest } from '../models/produto-filtro-request';

@Component({
    selector: 'app-produto-lista',
    templateUrl: './produto-lista.component.html',
    styleUrls: ['./produto-lista.component.scss']
})
export class ProdutoListaComponent implements OnInit, AfterViewInit {

    @ViewChild(MatSort) sort: MatSort;
    colunas = ['codigo', 'nome', 'categoria', 'grupo', 'subGrupo', 'unidade', 'fabricante', 'empresa', 'estoqueAtual', 'venda',  'acoes'];
    produtoDataSource: MatTableDataSource<Produto>;
    produtos: Produto[];
    produtoFiltro: ProdutoFiltroRequest;
    MovimentoEnum = MovimentoEnum;

    constructor(private produtoService: ProdutoService,
        private snackBar: MatSnackBar,
        private dialogService: MessageDialogService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService) { }

    ngOnInit() {
        this.activatedRoute.data.subscribe(data => {
            this.produtoDataSource = new MatTableDataSource<Produto>(data.produtos.data);
            this.produtoDataSource.sort = this.sort;
            this.produtos = data.produtos.data;
        });

        var filtro = this.localStorageService.recuperarItem<ProdutoFiltro>(NOME_FILTRO_PRODUTO);
        this.produtoFiltro = new ProdutoFiltroRequest(filtro ? filtro.nome : undefined, 
                                                      filtro ? filtro.codigo : undefined,
                                                      filtro ? filtro.referencia : undefined,
                                                      filtro &&  filtro.categoria ? filtro.categoria.id : undefined,
                                                      filtro &&  filtro.grupo ? filtro.grupo.id : undefined,
                                                      filtro &&  filtro.subGrupo ? filtro.subGrupo.id : undefined,
                                                      filtro &&  filtro.fabricante ? filtro.fabricante.id : undefined,
                                                      filtro ? filtro.ncm : undefined,
                                                      filtro && filtro.unidadeMedida ? filtro.unidadeMedida.id : undefined,
                                                      filtro && filtro.empresa ? filtro.empresa : undefined,
                                                      undefined, undefined, 0, 10);
    }

    ngAfterViewInit() {
        this.produtoDataSource.sort = this.sort;
    }

    deletar(produto: Produto) {
        const dialog = this.dialogService.open('Excluir', `Deseja realmente excluir o produto '${produto.nome}'?`, DialogActionYesAndNo);
        dialog.afterClosed().subscribe(dialogResponse => {
            if (dialogResponse === DialogResponseYes) {
                this.produtoService.deletar(produto.id).then(response => {
                    this.snackBar.open(`O produto ${produto.nome} foi excluido com sucesso!`, `Ok`, { duration: 3000 });
                    this.produtoFiltro.alterarPular(0);
                    this.produtoFiltro.alterarQuantidade(this.produtos.length);
                    this.buscarPorFiltro(this.produtoFiltro, false);
                });
            }
        });
    }

    filtrar() {

        this.produtoFiltro.alterarPular(0);
        this.produtoFiltro.alterarQuantidade(10);

        this.buscarPorFiltro(this.produtoFiltro, false);
    }

    buscarMais() {

        this.produtoFiltro.alterarPular(this.produtos.length);
        this.produtoFiltro.alterarQuantidade(10);

        this.buscarPorFiltro(this.produtoFiltro, true);

    }

    buscarPorFiltro(produtoFiltro: ProdutoFiltroRequest, acrescentar: boolean) {
        this.produtoService.recuperarPorFiltro(produtoFiltro).then(response => {
            if (acrescentar) {
                response.data.forEach(banco => {
                    this.produtos.push(banco);
                });
                this.produtoDataSource.data = this.produtos;

                return;
            }
            this.produtos = response.data ? response.data : [];
            this.produtoDataSource = new MatTableDataSource(this.produtos ? this.produtos : []);
        });
    }


    abrirFiltro() {

        const dialogRef = this.dialog.open(ProdutoFiltroModalComponent, {
            width: '600px',
            data: this.localStorageService.recuperarItem(NOME_FILTRO_PRODUTO)
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const filtro = this.localStorageService.recuperarItem<ProdutoFiltro>(NOME_FILTRO_PRODUTO);
                this.produtoFiltro.alterarNome(filtro.nome);
                this.produtoFiltro.alterarCodigo(filtro.codigo);
                this.produtoFiltro.alterarReferencia(filtro.referencia);
                this.produtoFiltro.alterarCategoriaId(filtro.categoria ? filtro.categoria.id : null);
                this.produtoFiltro.alterarGrupoId(filtro.grupo ? filtro.grupo.id : null);
                this.produtoFiltro.alterarSubGrupoId(filtro.subGrupo ? filtro.subGrupo.id : null);
                this.produtoFiltro.alterarFabricanteId(filtro.fabricante ? filtro.fabricante.id : null);
                this.produtoFiltro.alterarNcm(filtro.ncm);
                this.produtoFiltro.alterarUnidadeMedidaId(filtro.unidadeMedida ? filtro.unidadeMedida.id : null);
                this.produtoFiltro.alterarEmpresaId(filtro.empresa ? filtro.empresa : null);
                this.filtrar();
            } else {
                this.snackBar.open(`Filtro cancelado!`,
                    `Ok`, { duration: 3000 });
            }
        });
    }

    novo() {
        const ref = this.dialog.open(ProdutoIncluirModalComponent, {
          width: '80vw',
          disableClose: true
        });
    
        ref.afterClosed().subscribe(value => {
          if (value) {
            this.snackBar.open(`Produto cadastrado com sucesso!`,
              `Ok`, { duration: 3000 });
            this.filtrar();
          } else {
            this.snackBar.open(`Inclusão cancelada!`,
              `Ok`, { duration: 3000 });
          }
        });
      }
    
      alterar(produto: any) {
        const ref = this.dialog.open(ProdutoAlterarModalComponent, {
          width: '80vw',
          data: produto,
          disableClose: true
        });
    
        ref.afterClosed().subscribe(value => {
          if (value) {
            this.snackBar.open(`Produto editado com sucesso!`,
              `Ok`, { duration: 3000 });
            this.filtrar();
          } else {
            this.snackBar.open(`Edição cancelada!`,
              `Ok`, { duration: 3000 });
          }
        });
      }
}
