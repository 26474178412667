import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { UsuarioContaDigital } from './models/usuarioContaDigital';
import { ContaDigital } from './models/contaDigital';
import { UsuarioContaDigitalDocumentacao } from './models/usuarioContaDigitalDocumentacao';
import { InserirContaDigitalDocumentoRequest } from './models/inserirContaDigitalDocumentoRequest';
import { AlterarContaDigitalDocumentoRequest } from './models/alterarContaDigitalDocumentoRequest';
import { InserirContaDigitalRequest } from './models/inserirContaDigitalRequest';
import { AlterarSenhaEletronicaRequest } from './models/alterarSenhaEletronicaRequest';
import { AutenticarSenhaEletronicaRequest } from './models/autenticarSenhaEletronicaRequest';

@Injectable({
    providedIn: 'root'
})
export class UsuarioContaDigitalService {

    constructor(private readonly http: HttpClient,
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    criaContaDigital() {
        return this.http.post<ResponseData<ContaDigital>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-digitais/cria-conta-digital`, {});
    }

    resetarSenhaEletronica() {
        return this.http.put<ResponseData<ContaDigital>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-digitais/reseta-senha-eletronica`, {});
    }

    alterarSenhaEletronica(alterarSenhaEletronicaRequest: AlterarSenhaEletronicaRequest) {
        return this.http.post<ResponseData<ContaDigital>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-digitais/altera-senha-eletronica`, alterarSenhaEletronicaRequest);
    }

    autenticarContaDigital(autenticarSenhaEletronicaRequest: AutenticarSenhaEletronicaRequest) {
        return this.http.post<ResponseData<ContaDigital>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-digitais/autentica`, autenticarSenhaEletronicaRequest).toPromise();
    }
    
    verificaStatus() {
        return this.http.put<ResponseData<ContaDigital>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-digitais/verifica-status`, {});
    }

    recuperarMinhaContaDigital() {
        return this.http.get<ResponseData<ContaDigital>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-digitais/minha-conta-digital`).toPromise();
    }

    recuperarDocumentos() {
        return this.http.get<ResponseData<UsuarioContaDigitalDocumentacao[]>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-digitais/documentos`);
    }

    inserirDocumento(request: InserirContaDigitalDocumentoRequest) {
        const formData: FormData = new FormData();
        formData.append('documento', request.documento, request.nome);
        formData.append('documentoId', request.documentoId);
        formData.append('nome', request.nome);
        formData.append('tipoDocumento', request.tipoDocumento.toString());

        return this.http.post<ResponseData<UsuarioContaDigitalDocumentacao[]>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-digitais/documentos`, formData);
    }

    alterarDocumento(request: AlterarContaDigitalDocumentoRequest) {
        const formData: FormData = new FormData();
        formData.append('documento', request.documento, request.nome);
        formData.append('documentoId', request.documentoId);
        formData.append('nome', request.nome);
        formData.append('tipoDocumento', request.tipoDocumento.toString());

        return this.http.put<ResponseData<UsuarioContaDigitalDocumentacao[]>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-digitais/documentos`, formData);
    }

    deletarDocumento(documentoId) {
        return this.http.delete<ResponseData<UsuarioContaDigitalDocumentacao[]>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-digitais/documentos`);
    }

}
