import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UsuarioService } from '../usuario.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlterarSenhaUsuarioRequest } from '../models/alterarSenhaUsuarioRequest';

@Component({
    selector: 'app-usuario-senha-modal',
    templateUrl: './usuario-senha-modal.component.html',
    styleUrls: ['./usuario-senha-modal.component.scss']
})
export class UsuarioSenhaModalComponent implements OnInit {

    senhaForm: UntypedFormGroup;

    constructor(public dialogRef: MatDialogRef<UsuarioSenhaModalComponent>,
         private formBuilder: UntypedFormBuilder,
        private usuarioService: UsuarioService, private snackBar: MatSnackBar) {
        this.senhaForm = this.formBuilder.group({
            senhaAntiga: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(8)]],
            senhaNova: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(8)]],
            confirmacaoSenha: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(8)]]
        });
    }

    ngOnInit() {
    }

    cancelar(): void {
        this.dialogRef.close();
    }

    alterarSenha() {
        if (this.senhaForm.value.senhaNova !== this.senhaForm.value.confirmacaoSenha) {
            this.snackBar.open(`Nova senha e a confirmação da senha não conferem!`,
                `Ok`, { duration: 3000 });
        }

        let senha = new AlterarSenhaUsuarioRequest(this.senhaForm.value.senhaAntiga,
            this.senhaForm.value.senhaNova, this.senhaForm.value.confirmacaoSenha)

        this.usuarioService.alterarSenha(senha).then(() => {
            this.snackBar.open(`Senha alterada com sucesso!`,
                `Ok`, { duration: 3000 });
            this.dialogRef.close(true);
        });
    }
}
