import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { CampanhaFuncionarioSaldo } from './models/campanhaFuncionarioSaldo';
import { UsuarioContaBancariaResumo } from '../usuario-conta-bancaria/models/usuarioContaBancariaResumo';

@Injectable({
    providedIn: 'root'
})
export class CampanhaFuncionarioSaldoService {

    constructor(private readonly http: HttpClient,
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    retirarSaldo(idCampanha: string, idCampanhaFuncionarioSaldo: string) {
        return this.http.put<ResponseData<CampanhaFuncionarioSaldo>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/retira-saldo/${idCampanhaFuncionarioSaldo}`, {});
    }

    async validarContaSaldo(idCampanha: string, idCampanhaFuncionarioSaldo: string) {
        return await this.http.get<ResponseData<UsuarioContaBancariaResumo>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/retira-saldo/${idCampanhaFuncionarioSaldo}`, {}).toPromise();
    }
}
