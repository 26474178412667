
import { Routes, RouterModule } from '@angular/router';
// Guards
import { GeneralRouteGuard } from '../seguranca/general-route-guard';
import { CampanhaListaComponent } from './campanha-lista/campanha-lista.component';

// Resolver
import { CampanhaListaResolver } from './campanha-lista/campanha-lista.resolver';
import { NgModule } from '@angular/core';
import { CampanhaIncluirComponent } from './campanha-incluir/campanha-incluir.component';
import { CampanhaIncluirResolver } from './campanha-incluir/campanha-incluir.resolver';

const routes: Routes =
    [
        {
            path: '',
            children: [
                {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                },
                {
                    path: 'list',
                    component: CampanhaListaComponent,
                    canActivate: [GeneralRouteGuard],
                    resolve:
                    {
                        campanhas: CampanhaListaResolver
                    }
                },
                {
                    path: 'compose',
                    component: CampanhaIncluirComponent,
                    canActivate: [GeneralRouteGuard],
                    resolve:
                    {
                        campanha: CampanhaIncluirResolver
                    }
                },
            ]
        }
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class CampanhaRoutingModule { }
