<mat-card fxFlex>
  <mat-card-header>
    <mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
      <div fxLayout="row" fxFlex fxLayoutAlign="space-between center">
        <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>playlist_add</mat-icon>
          <span class="p6n-action-bar-title">Contas bancárias</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="end start">
          <button matTooltip="Adicionar conta bancária" mat-button color="primary"
            (click)="this.adicionarUsuarioContaBancaria()" fxFlexAlign="end" *ngIf="this.contasBancarias == null || this.contasBancarias.length == 0">
            <mat-icon>add</mat-icon> Adicionar
          </button>
        </div>
      </div>
    </mat-toolbar>
  </mat-card-header>
  <mat-card-content fxLayout="column">
    <mat-table #table [(dataSource)]="this.contasBancariasDataSource" fxLayout="column">
      <ng-container matColumnDef="banco">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex>Banco</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let contaBancaria" fxFlex>{{contaBancaria.codigoBanco}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="agencia">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex>Agência</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let contaBancaria" fxFlex>{{contaBancaria.agencia}}{{contaBancaria.agenciaDigito != null ? "-"+contaBancaria.agenciaDigito : ""}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="conta">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex>Conta</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let contaBancaria" fxFlex>{{contaBancaria.conta}}{{contaBancaria.contaDigito != null ? "-"+contaBancaria.contaDigito : ""}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tipoContaBancaria">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex>Tipo conta bancária</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let contaBancaria" fxFlex>{{contaBancaria.tipoContaBancaria != null ? contaBancaria.tipoContaBancaria.description : ""}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="statusContaBancaria">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex="60px">Status</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let contaBancaria" fxFlex="60px" matTooltip="{{contaBancaria.statusContaBancaria.description}}">
              <mat-icon *ngIf="contaBancaria.statusContaBancaria.value == 'APPROVED'" class="mat-green-color">verified</mat-icon>
              <mat-icon *ngIf="contaBancaria.statusContaBancaria.value == 'REJECTED'" class="mat-red-color">report</mat-icon>
              <mat-icon *ngIf="contaBancaria.statusContaBancaria.value == 'AWAITING_APPROVAL'" class="mat-blue-color">hourglass_top</mat-icon>
              <mat-icon *ngIf="contaBancaria.statusContaBancaria.value == 'PENDING'" class="mat-orange-color">warning</mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="acoes">
        <mat-header-cell *matHeaderCellDef fxFlex="60px"></mat-header-cell>
        <mat-cell *matCellDef="let contaBancaria" fxFlex="60px">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="verificarStatusConta(contaBancaria)" matTooltip="Verificar status"
              color="warn" id="buttonVerificaStatus">
              <mat-icon>refresh</mat-icon> Verificar status
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="colunas"></mat-header-row>
      <mat-row *matRowDef="let row; columns: colunas;"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>