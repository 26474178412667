import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators } from '@angular/forms';
import { GrupoService } from '../grupo.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MovimentoConst } from 'src/app/comum/consts/movimento.const';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { InserirGrupoRequest } from '../models/inserirGrupoRequest';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-grupo-incluir-modal',
  templateUrl: './grupo-incluir-modal.component.html',
  styleUrls: ['./grupo-incluir-modal.component.scss']
})
export class GrupoIncluirModalComponent implements OnInit {

  grupoFormGroup: UntypedFormGroup;
  empresas: ComboBoxItem[];

  constructor(private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private grupoService: GrupoService,
    private empresaService: EmpresaService,
    public location: Location,
    private readonly ref: MatDialogRef<GrupoIncluirModalComponent>) {
  }


  ngOnInit() {

    this.grupoFormGroup = this.formBuilder.group({
      nome: [null, Validators.required],
      empresa: [null, Validators.required]
    });

    this.empresaService.listarVinculados()
      .then
      (response => {
        this.empresas = response.data;
        this.grupoFormGroup.get("empresa").setValue(this.empresas.length == 1 ? this.empresas[0].id : null);
      });
  }

  async salvar() {
    if (!this.grupoFormGroup.valid) {
      throw new Error('Dados inválidos!');
    }

    let inserirGrupoRequest = new InserirGrupoRequest(this.grupoFormGroup.value.nome, this.grupoFormGroup.value.empresa)

    await this.grupoService.incluir(inserirGrupoRequest).then(response => {
      this.snackBar.open(`Grupo salva com sucesso!`, `Ok`, { duration: 2000 });
      this.ref.close(response.data);
    });
  }

  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }
}
