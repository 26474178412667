import { Campanha } from '../models/campanha';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageDialogService } from '../../message-dialog/message-dialog.service';
import { Validators } from '@angular/forms';
import { CampanhaService } from '../campanha.service';
import { Location } from '@angular/common';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { ActivatedRoute, Router } from '@angular/router';
import { CampanhaRemuneracaoService } from 'src/app/campanha-remuneracao/campanha-remuneracao.service';
import { CampanhaRemuneracao } from 'src/app/campanha-remuneracao/models/campanhaRemuneracao';
import { StatusCampanhaConst } from 'src/app/comum/consts/statusCampanha.const';
import { StatusCampanhaEnum } from 'src/app/comum/enuns/statusCampanha.enum';
import { CampanhaFuncionarioService } from 'src/app/campanha-funcionario/campanha-funcionario.service';
import { CampanhaFuncionario } from 'src/app/campanha-funcionario/models/campanhaFuncionario';
import { CampanhaFuncionarioMeta } from 'src/app/campanha-funcionario-meta/models/campanhaFuncionarioMeta';
import { CampanhaFuncionarioMetaService } from 'src/app/campanha-funcionario-meta/campanha-funcionario-meta.service';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { CampanhaTotalizacaoAgrupada } from 'src/app/campanha-totalizacao/models/campanhaTotalizacaoAgrupada';
import { CampanhaTotalizacaoService } from 'src/app/campanha-totalizacao/campanha-totalizacao.service';
import { CampanhaFuncionarioMetaAgrupada } from 'src/app/campanha-funcionario-meta/models/campanhaFuncionarioMetaAgrupada';
import { InserirCampanhaRequest } from '../models/inserirCampanhaRequest';
import { AlterarCampanhaRequest } from '../models/alterarCampanhaRequest';
import { Empresa } from 'src/app/empresa/models/empresa';
import { CampanhaEmpresa } from 'src/app/campanha-empresa/models/campanhaEmpresa';
import { CampanhaEmpresaService } from 'src/app/campanha-empresa/campanha-empresa.service';
import { DialogActionDefault, DialogActionProgress, DialogActionYesAndNo, DialogResponseOk, DialogResponseYes } from 'src/app/message-dialog/message-dialog-action';
import { CampanhaFuncionarioSaldoService } from 'src/app/campanha-funcionario-saldo/campanha-funcionario-saldo.service';
import { CampanhaFuncionarioSaldo } from 'src/app/campanha-funcionario-saldo/models/campanhaFuncionarioSaldo';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { CampanhaListaUsuario } from '../models/campanha-lista-usuario';
import { CampanhaFuncionarioMetaTotalizacao } from 'src/app/campanha-funcionario-meta/models/campanhaFuncionarioMetaTotalizacao';
import { RouterService } from 'src/app/seguranca/router-service';
import { DrawerMenuItemAtual } from 'src/app/comum/componentes/drawer-menu/drawer-menu-item-atual';

@Component({
  selector: 'app-campanha-incluir',
  templateUrl: './campanha-incluir.component.html',
  styleUrls: ['./campanha-incluir.component.scss']
})
export class CampanhaIncluirComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;

  campanhaFormGroup: UntypedFormGroup;
  campanhaEmpresaFormGroup: UntypedFormGroup;
  campanhaRemuneracaoFormGroup: UntypedFormGroup;
  campanhaRemuneracaoDesempenhoFormGroup: UntypedFormGroup;

  inserindoCampanha: boolean;
  vinculandoEmpresa: boolean;

  @ViewChild(MatSort) sort: MatSort;
  colunas = ['nomeRazaoSocial', 'nomeFantasia', 'cpfCnpj', 'tipoPessoa', 'email', 'acoes'];
  empresaDataSource: MatTableDataSource<Empresa>;
  empresas: ComboBoxItem[];

  usuariosCadastros: ComboBoxItem[];
  statusCampanhas: Array<object>;
  campanhaListaUsuario: CampanhaListaUsuario;
  campanhasEmpresas: CampanhaEmpresa[];
  campanhasRemuneracoes: CampanhaRemuneracao[];
  campanhasFuncionarios: CampanhaFuncionario[];
  campanhasFuncionariosMetasTotalizacoes: CampanhaFuncionarioMetaTotalizacao[];
  campanhasFuncionariosSaldos: CampanhaFuncionarioSaldo[];
  campanhasTotalizacoesAgrupadas: CampanhaTotalizacaoAgrupada[];
  campanhaCobranca: any;
  tabGroupSelected: number;

  constructor(private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private campanhaService: CampanhaService,
    private campanhaEmpresaService: CampanhaEmpresaService,
    private campanhaRemuneracaoService: CampanhaRemuneracaoService,
    private campanhaFuncionarioService: CampanhaFuncionarioService,
    private campanhaFuncionarioMetaService: CampanhaFuncionarioMetaService,
    private campanhaFuncionarioSaldoService: CampanhaFuncionarioSaldoService,
    private campanhaTotalizacaoService: CampanhaTotalizacaoService,
    public location: Location,
    private activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private dialogService: MessageDialogService,
    private empresaService: EmpresaService,
    public router: Router,
    public routerService: RouterService) {
    this.statusCampanhas = StatusCampanhaConst;
    this.tabGroupSelected = 0;
  }

  ngOnInit() {
    setTimeout(() => { this.routerService.alterarRota(new DrawerMenuItemAtual("Sua campanha", "/campanhas", "", false, false, null))}, 0);
    this.campanhaFormGroup = this.formBuilder.group({
      id: [null],
      nome: [null, [Validators.required, Validators.maxLength(500)]],
      inicioCampanha: [null, [Validators.required]],
      fimCampanha: [null, [Validators.required]],
      multiEmpresa: [null, [Validators.required]],
      statusCampanha: [{ disabled: true, value: null }],
      usuarioCadastro: [{ disabled: true, value: null }],
    });

    this.activatedRoute.data.subscribe((data) => {
      if (data.campanha == null)
        return;

      this.campanhaListaUsuario = data.campanha.data;
      this.recarregarCampanha()
    });
  }

  atribuirValoresCampanha(campanha: any) {
    if (campanha) {
      this.campanhaFormGroup.controls['id'].setValue(campanha.id);
      this.campanhaFormGroup.controls['nome'].setValue(campanha.nome);
      this.campanhaFormGroup.controls['inicioCampanha'].setValue(campanha.inicioCampanha);
      this.campanhaFormGroup.controls['fimCampanha'].setValue(campanha.fimCampanha);
      this.campanhaFormGroup.controls['statusCampanha'].setValue(StatusCampanhaEnum[campanha.statusCampanha.value]);
      this.campanhaFormGroup.controls['usuarioCadastro'].setValue(campanha.usuarioCadastro ? { id: campanha.usuarioCadastro.id, descricao: campanha.usuarioCadastro.nome } : null);
      this.campanhaFormGroup.controls['multiEmpresa'].setValue(campanha.multiEmpresa);
    }
  }



  async liquidacaoLibrada() {
    const dialog = this.dialogService.open('Já pode comemorar!', `A campanha foi quitada e agora os participantes já podem solicitar a retirada pelo App MonneraPay!`, DialogActionDefault, "payments");
  }


  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }

  recarregarCampanha() {
    this.campanhaService.recuperarPorCampanhaECampanhaFuncionario(this.campanhaListaUsuario.id, this.campanhaListaUsuario.campanhaFuncionarioId).then(registro => {
      this.atribuirValoresCampanha(registro.data);
      this.campanhaListaUsuario = registro.data;
    });
    this.campanhaFuncionarioMetaService.recuperarPorFuncionario(this.campanhaListaUsuario.id, this.campanhaListaUsuario.campanhaFuncionarioId).subscribe(registro => {
      this.campanhasFuncionariosMetasTotalizacoes = registro.data;
    });
  }

  sacar(campanhaListaUsuario: CampanhaListaUsuario) {
    const dialog = this.dialogService.open('Atenção', `Deseja realmente sacar a quantia de R$ ${campanhaListaUsuario.saldoDisponivel}?`, DialogActionYesAndNo);
    dialog.afterClosed().subscribe(dialogResponse => {
      if (dialogResponse === DialogResponseYes) {
        this.campanhaService.retirarSaldo(campanhaListaUsuario.id, campanhaListaUsuario.campanhaFuncionarioId).then(response => {
          this.snackBar.open(`Em instantes o saldo estará disponível na sua carteira, aguarde...`, `Ok`, { duration: 3000 });
          this.recarregarCampanha();
      });
      }
    });
  }


  getStatusIcon(campanha: CampanhaListaUsuario) {
    switch (campanha.statusCampanha.value) {

      case 'Rascunho':
        return "receipt";
      case 'Vigente':
        return "play_circle";
      case 'Interrompida':
        return "pause";
      case 'Encerrando':
        return "pending_actions";
      case 'Totalizada':
        return "calculate";
      case 'AguardandoFundos':
        return "pending_actions";
      case 'AguardandoLiberacao':
        return "lock_open";
      case 'LiquidacaoLiberada':
        return "qr_code_scanner";
      case 'Liquidado':
        return "price_check";
      case 'Finalizada':
        return "done_all";
      case 'Cancelada':
        return "cancel"
    }
  }

  getStatusColor(campanha: CampanhaListaUsuario) {

    switch (campanha.statusCampanha.value) {

      case 'Rascunho':
        return "";
      case 'Vigente':
        return "primary";
      case 'Interrompida':
        return "accent";
      case 'Encerrando':
        return "primary";
      case 'Totalizada':
        return "primary";
      case 'AguardandoFundos':
        return "primary";
      case 'AguardandoLiberacao':
        return "primary";
      case 'LiquidacaoLiberada':
        return "primary";
      case 'Liquidado':
        return "primary";
      case 'Finalizada':
        return "primary";
      case 'Cancelada':
        return "warn"
    }
  }
}
