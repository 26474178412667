
import { Routes, RouterModule } from '@angular/router';
import { GeneralRouteGuard } from '../seguranca/general-route-guard';
import { NgModule } from '@angular/core';
import { UsuarioContaDigitalDetalheComponent } from './usuario-conta-digital-detalhe/usuario-conta-digital-detalhe.component';
import { UsuarioContaDigitalDetalheResolver } from './usuario-conta-digital-detalhe/usuario-conta-digital-detalhe.resolver';

const routes: Routes =
    [
        {
            path: '',
            component: UsuarioContaDigitalDetalheComponent,
            canActivate: [GeneralRouteGuard],
            resolve:
            {
                contaDigital: UsuarioContaDigitalDetalheResolver
            }
        }
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class UsuarioContaDigitalRoutingModule { }
