import { Component, OnInit, Input, Output, ViewEncapsulation, OnChanges, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CampanhaFuncionarioMetaNovoModalComponent } from '../campanha-funcionario-meta-novo-modal/campanha-funcionario-meta-novo-modal.component';
import { EventEmitter } from '@angular/core';
import { CampanhaFuncionarioMeta } from '../models/campanhaFuncionarioMeta';
import { CampanhaFuncionarioMetaAlterarModalComponent } from '../campanha-funcionario-meta-alterar-modal/campanha-funcionario-meta-alterar-modal.component';
import { MessageDialogService } from 'src/app/message-dialog/message-dialog.service';
import { DialogActionYesAndNo, DialogResponseYes } from 'src/app/message-dialog/message-dialog-action';
import { CampanhaFuncionarioMetaService } from '../campanha-funcionario-meta.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Campanha } from 'src/app/campanha/models/campanha';
import { CampanhaRemuneracao } from 'src/app/campanha-remuneracao/models/campanhaRemuneracao';
import { CampanhaFuncionarioMetaTotalizacao } from '../models/campanhaFuncionarioMetaTotalizacao';
import { Chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import { SeriesGaugeOptions } from 'highcharts';

@Component({
    selector: 'app-campanha-funcionario-meta-lista',
    templateUrl: './campanha-funcionario-meta-lista.component.html',
    styleUrls: ['./campanha-funcionario-meta-lista.component.scss'],
    encapsulation: ViewEncapsulation.None,
    inputs: ['campanhasFuncionariosMetasTotalizacoes'],
    outputs: ['adicionar', 'remover'],
})
export class CampanhaFuncionarioMetaListaComponent implements OnInit, OnChanges {

    @Input('campanhasFuncionariosMetasTotalizacoes')
    public campanhasFuncionariosMetasTotalizacoes: CampanhaFuncionarioMetaTotalizacao[];

    @Input('campanha')
    public campanha: Campanha;

    @Output('adicionar')
    public adicionar = new EventEmitter();

    @Output('alterar')
    public alterar = new EventEmitter();

    @Output('remover')
    public remover = new EventEmitter();

    campanhaFuncionarioMetaDataSource: MatTableDataSource<any>;
    colunas: string[] = ['funcionario', 'codigoRemuneracao', 'tipoMeta', 'totalApuradoGeral', 'totalApuradoFuncionario', 'representatividade', 'metaMinima', 'meta', 'superMeta', 'acoes'];

    constructor(private dialog: MatDialog,
        private campanhaFuncionarioMetaService: CampanhaFuncionarioMetaService,
        private snackBar: MatSnackBar,
        private dialogService: MessageDialogService) {
        this.campanhaFuncionarioMetaDataSource = new MatTableDataSource<any>(this.campanhasFuncionariosMetasTotalizacoes);

       

    }

    ngOnChanges() {
        this.recarregar();
    }

    ngOnInit() {
      
    }

    recarregar() {
        this.campanhaFuncionarioMetaDataSource = new MatTableDataSource<any>(this.campanhasFuncionariosMetasTotalizacoes);
        // if(!this.campanha){
        //     return;
        // }
        // await this.campanhaFuncionarioMetaService.recuperarPorCampanha(this.campanha.id,).subscribe(response => {
        //     this.campanhasFuncionariosMetasTotalizacoes = response.data;
        //     this.campanhaFuncionarioMetaDataSource = new MatTableDataSource<any>(this.campanhasFuncionariosMetasTotalizacoes);
        // });
    }

    adicionarCampanhaFuncionarioMeta(CampanhaFuncionarioMetaTotalizacao: CampanhaFuncionarioMetaTotalizacao) {
        const dialogRef = this.dialog.open(CampanhaFuncionarioMetaNovoModalComponent,
            {
                width: '700px',
                disableClose: true,
                data: CampanhaFuncionarioMetaTotalizacao
            });

        dialogRef.afterClosed().subscribe((campanhaFuncionarioMetaResult: CampanhaFuncionarioMeta) => {
            this.snackBar.open(`A meta do funcionário ${CampanhaFuncionarioMetaTotalizacao.funcionarioNome} foi incluída com sucesso!`, `Ok`, { duration: 3000 });
            this.adicionar.emit(true);
            this.recarregar();
        });
    }

    alterarCampanhaFuncionarioMeta(CampanhaFuncionarioMetaTotalizacao: CampanhaFuncionarioMetaTotalizacao) {
        const dialogRef = this.dialog.open(CampanhaFuncionarioMetaAlterarModalComponent,
            {
                width: '700px',
                disableClose: true,
                data: CampanhaFuncionarioMetaTotalizacao
            });

        dialogRef.afterClosed().subscribe((campanhaFuncionarioMetaResult: CampanhaFuncionarioMeta) => {
            this.snackBar.open(`A meta do funcionário ${CampanhaFuncionarioMetaTotalizacao.funcionarioNome} foi alterada com sucesso!`, `Ok`, { duration: 3000 });
            this.alterar.emit(true);
            this.recarregar();
        });
    }

    informacoesTooltip(campanhaFuncionarioMeta: CampanhaFuncionarioMeta) {
        return `Tipo remuneração: ${campanhaFuncionarioMeta.campanhaRemuneracao.tipoRemuneracao.description}
                Origem remuneração: ${campanhaFuncionarioMeta.campanhaRemuneracao.origemRemuneracao.description}
                Tipo cálculo: ${campanhaFuncionarioMeta.campanhaRemuneracao.tipoCalculoRemuneracao.description}
                Tipo pagamento: ${campanhaFuncionarioMeta.campanhaRemuneracao.tipoPagamentoRemuneracao.description}
                Tipo faixa desempenho: ${campanhaFuncionarioMeta.campanhaRemuneracao.tipoFaixaDesempenho != null ? campanhaFuncionarioMeta.campanhaRemuneracao.tipoFaixaDesempenho.description : "---"}`
    }

    retornarGrafico(campanhaFuncionarioMetaTotalizacao: CampanhaFuncionarioMetaTotalizacao) {
        let chart = new Chart({
                chart: {
                    type: 'gauge',
                    plotBackgroundColor: null,
                    plotBackgroundImage: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    height: '80%'
                },
                title: {
                    text: 'Meta'
                },
                pane: {
                    startAngle: -90,
                    endAngle: 89.9,
                    background: null,
                    center: ['50%', '75%'],
                    size: '110%'
                },

                // the value axis
                yAxis: {
                    min: 0,
                    max: 200,
                    tickPixelInterval: 72,
                    tickPosition: 'inside',
                    tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
                    tickLength: 20,
                    tickWidth: 2,
                    minorTickInterval: null,
                    labels: {
                        distance: 20,
                        style: {
                            fontSize: '14px'
                        }
                    },
                    plotBands: [
                        {
                            from: 0,
                            to: campanhaFuncionarioMetaTotalizacao.metaMinima,
                            color: '#DF5353', //red
                            thickness: 20
                        }, {
                            from: campanhaFuncionarioMetaTotalizacao.metaMinima,
                            to: campanhaFuncionarioMetaTotalizacao.meta,
                            color: '#DDDF0D', //yellow
                            thickness: 20
                        }, {
                            from: campanhaFuncionarioMetaTotalizacao.meta,
                            to: campanhaFuncionarioMetaTotalizacao.superMeta,
                            color: '#55BF3B', //green
                            thickness: 20
                        },
                        // {
                        //     from: 160,
                        //     to: 200,
                        //     color: '#DF5353', // red
                        //     thickness: 20
                        // }
                    ]
                },
                series: [{
                    name: 'Meta',
                    type: undefined,
                    data: [campanhaFuncionarioMetaTotalizacao.metaApurada],
                    tooltip: {
                        valuePrefix: 'R$ '
                    },
                    dataLabels: {
                        format: 'R$ {y}',
                        borderWidth: 0,
                        color: (
                            Highcharts.defaultOptions.title &&
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || '#333333',
                        style: {
                            fontSize: '16px'
                        }
                    }
                }]
            });
        
        return chart;
    }

    informacoesDivisaoOrcamentoTooltip()
    {
        return `Representa como o orçamento será apenas calculado:
                Geral: Será levado em consideração o total de TODOS os vendedores, participantes ou não da campanha.
                Participantes: Será levado em consideração o total dos vendedores ENVOLVIDOS na campanha.
                Colocação: Será levado em consideração o total APENAS dos COLOCADOS.`;
    }

    informacoesApuracaoMetaTooltip()
    {
        return `Representa como a meta será calculada e apurada:
                Geral: Será levado em consideração o total de TODOS os vendedores, participantes ou não da campanha.
                Participantes: Será levado em consideração o total dos vendedores ENVOLVIDOS na campanha.
                Colocação: Será levado em consideração o total APENAS dos COLOCADOS.`;
    }

    identificarMetaAtingida(campanhaFuncionarioMetaTotalizacao: CampanhaFuncionarioMetaTotalizacao)
    {
        if(campanhaFuncionarioMetaTotalizacao.atingiuSuperMeta)
        {
            return 'mat-blue-color';
        }
        else if(campanhaFuncionarioMetaTotalizacao.atingiuMeta)
        {
            return 'mat-green-color';
        }
        else if(campanhaFuncionarioMetaTotalizacao.atingiuMetaMinima)
        {
            return 'mat-darkorange-color';
        }
        else{
            return 'mat-red-color';
        }
    }
}
