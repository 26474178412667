<div fxLayout="column">
  <h2 mat-dialog-title> <mat-icon class="material-symbols-outlined">{{ data.icon == null ? 'info' : data.icon  }}</mat-icon> <strong>{{ data.title }} </strong></h2>
  <mat-dialog-content class="mat-typography">
    <h3>{{ data.message }}</h3>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxFlex="100" fxFlexAlign="end">
    <div *ngIf="data.buttons == 'YesAndNo'">
      <button mat-button [mat-dialog-close]="'Yes'" color="accent" class="mat-dialog-button">SIM</button>
      <button mat-button [mat-dialog-close]="'No'" color="accent" class="mat-dialog-button">NÃO</button>
    </div>
    <div *ngIf="data.buttons == 'YesAndCancel'">
      <button mat-button [mat-dialog-close]="'Yes'" color="accent" class="mat-dialog-button">SIM</button>
      <button mat-button [mat-dialog-close]="'Cancel'" color="accent" class="mat-dialog-button">CANCELAR</button>
    </div>
    <div *ngIf="data.buttons == 'OkAndCancel'">
      <button mat-button [mat-dialog-close]="'Ok'" color="accent" class="mat-dialog-button">OK</button>
      <button mat-button [mat-dialog-close]="'Cancel'" color="accent" class="mat-dialog-button">CANCELAR</button>
    </div>
    <div *ngIf="data.buttons == 'OkAndNo'">
      <button mat-button [mat-dialog-close]="'Ok'" color="accent" class="mat-dialog-button">OK</button>
      <button mat-button [mat-dialog-close]="'No'" color="accent" class="mat-dialog-button">NO</button>
    </div>
    <div *ngIf="data.buttons == 'Default'">
      <button mat-button [mat-dialog-close]="'Ok'" color="accent" class="mat-dialog-button">OK</button>
    </div>
  </mat-dialog-actions>
</div>