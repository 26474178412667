import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication-service';
import { LoaderService } from '../comum/services/loader.service';

@Injectable({ providedIn: 'root' })
export class RequestInterceptor implements HttpInterceptor {

    private requests: HttpRequest<any>[] = [];

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.findIndex(w => w.urlWithParams == req.urlWithParams);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        this.requests.push(req);

        if (this.authService.authenticated() && req.url.indexOf('viacep') < 0) {
            var token = this.authService.getSession().token;
            req = req.clone({
                setHeaders: {
                    "Autorizacao": token
                }
            });
        }

        this.loaderService.isLoading.next(true);

        return next.handle(req)
            .pipe(tap(n => {
                if (!(n instanceof HttpResponse)) {
                    return;
                }

                if (!n.body) {
                    return;
                }

                this.removeRequest(req);

            }, err => {
                if (err.status === 401) {
                    this.authService.abrirLogin();
                    this.snackBar.open('Redirecionando para autenticação...', 'Ok', { duration: 3000 });
                    this.removeRequest(req);
                    return;
                }
                else if (err.status === 403) {
                    this.snackBar.open('Usuário sem acesso a rota solicitada!', 'Ok', { duration: 3000 });
                    this.removeRequest(req);
                    return;
                }
                if (err.status === 500) {
                    this.snackBar.open('Solicitação não processada. Erro interno no servidor!', 'Ok', { duration: 3000 });
                    this.removeRequest(req);
                    return;
                }
                if (err.status === 400) {
                    const error = err.error;
                    if (error.errors) {
                        let erro = "";
                        error.errors.forEach((element: any) => {
                            erro += (element + "\n");
                        });
                        this.snackBar.open(erro, 'Ok', { duration: 3000 });
                    }
                    this.removeRequest(req);
                    return;
                }
                else if (err.name == "HttpErrorResponse") {
                    this.snackBar.open('Servidor de autenticação offline, tente novamente!'+req.url, 'Ok', { duration: 3000 });
                    this.removeRequest(req);
                    return;
                }
                else if (!err || !err.error) {
                    this.snackBar.open('Ocorreu um erro desconhecido!', 'Ok', { duration: 3000 });
                    this.removeRequest(req);
                    return;
                }

                this.removeRequest(req);
            }, () => {
                this.removeRequest(req);
            }));
    }

    constructor(private readonly snackBar: MatSnackBar,
                private readonly authService: AuthenticationService,
                private loaderService: LoaderService
    ) {

    }

}
