<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
  <div fxLayout="row" fxFlex fxLayoutAlign="space-between center">
    <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-icon>playlist_add</mat-icon>
      <span class="p6n-action-bar-title">Documentação da conta digital</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="end start">
      <button matTooltip="Criar conta" mat-button color="primary" fxFlexAlign="end">
        <mat-icon>refresh</mat-icon> Verificar status
      </button>
    </div>
  </div>
</mat-toolbar>
<mat-dialog-content>
  <mat-card *ngFor="let documentacao of this.documentacoes" class="border-document" fxLayout="column"
    fxLayoutGap="10px" style="margin:10px">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div>
          {{documentacao.tipoDocumento.description}}
        </div>
      </div>
    </div>
    <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
      <span>
        Status do documento:
      </span>
      <hr class="hr-dotted" fxFlex>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div>
          {{documentacao.statusDocumento.description}}
        </div>
      </div>
    </div>
    <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
      <span>
        Nome:
      </span>
      <hr class="hr-dotted" fxFlex>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div>
          {{documentacao.titulo}}
        </div>
      </div>
    </div>
    <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
      <span>
        Descrição:
      </span>
      <hr class="hr-dotted" fxFlex>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div>
          {{documentacao.descricao}}
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex *ngIf="documentacao.statusDocumento.value != 'APPROVED'" fxLayoutAlign="start center">
      <a mat-raised-button href="{{documentacao.onboardingUrl}}" target="_blank" color="primary">
        Validar identificação
        <mat-icon>open_in_new</mat-icon>
       </a>
      <!--<strong>ou</strong>
      <app-dialog (arquivos)="this.anexarArquivos($event, documentacao)" [extensoes]="['.png', '.pdf']"
        [descricao]="'Enviar documento Digital'" *ngIf="documentacao.tipoDocumento.value == 'IDENTIFICATION'"></app-dialog> -->
    </div>
  </mat-card>
  <br>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="1rem">
  <button mat-button color="warn" [mat-dialog-close]="false" id="buttonCancelar">
    <span>Fechar</span>
  </button>
</mat-dialog-actions>