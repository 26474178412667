import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TermoAceite } from './models/termoAceite';
import { TermoAceiteFiltro } from './models/termo-aceite-filtro';
import { BENEFICIO_URI, BENEFICIO_ADDRESS } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { InserirTermoAceiteRequest } from './models/inserirTermoAceiteRequest';
import { ResponseData } from '../comum/models/responseData';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { AlterarTermoAceiteRequest } from './models/alterarTermoAceiteRequest';
import { TermoAceiteFiltroRequest } from './models/termo-aceite-filtro-request';

@Injectable({ providedIn: 'root'})
export class TermoAceiteService {

  constructor(private http: HttpClient, 
    private queryParamsService: QueryParamsService,
    @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
    @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

  public recuperarPorFiltro(filtro: TermoAceiteFiltroRequest) {
    return this.http.get<ResponseData<TermoAceite[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/termos-aceites`, {params: this.queryParamsService.gerarQuery(filtro) }).toPromise();
  }

  public recuperarPorId(id: string) {
    return this.http.get<ResponseData<TermoAceite>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/termos-aceites/${id}`).toPromise();
  }

  public incluir(request: InserirTermoAceiteRequest) {
    const formData: FormData = new FormData();
    formData.append('anexo', request.anexo, request.nome);
    formData.append('nome', request.nome);
    formData.append('descricao', request.descricao);
    formData.append('tipoTermoAceite', request.tipoTermoAceite.toString());

    return this.http.post<ResponseData<TermoAceite>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/termos-aceites`, formData).toPromise();
  }

  public alterar(id: any, request: AlterarTermoAceiteRequest) {
    return this.http.put<ResponseData<TermoAceite>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/termos-aceites/${id}`, request).toPromise();
  }

  public deletar(id: any) {
    return this.http.delete<any>(`${this.beneficioAddress}${this.beneficioUri}/administracao/termos-aceites/${id}`).toPromise();
  }

  public listarAutoComplete(contem: string, empresaId: string) {
    return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/termos-aceites/combobox`, {params: {conteudoPesquisa : contem , empresaId: empresaId}}).toPromise();
  }

  public recuperarAnexo(id: string) {
    return this.http.get(`${this.beneficioAddress}${this.beneficioUri}/administracao/termos-aceites/${id}/anexo`, { observe: 'response', responseType: "arraybuffer"}).toPromise();
  }

  public recuperarPendentePorTipo(tipoTermoAceite: number) {
    return this.http.get<ResponseData<TermoAceite>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/termos-aceites/tipo-termo-aceite/${tipoTermoAceite}`, {}).toPromise();
  }
}
