import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthenticationService } from "../seguranca/authentication-service";
import { environment } from "../../environments/environment";
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from "../app.config";
import { NgProgress } from "ngx-progressbar";
import { UsuarioModel } from "../dominio/modelos/UsuarioModel";
import { ResponseData } from "../comum/models/responseData";
import { EmpresaSessaoModel } from "../dominio/modelos/EmpresaSessaoModel";
import { AutenticacaoTokenCodeRequest } from "./models/autenticacaoTokenCodeRequest";

@Injectable()
export class LoginService{

    private token: any;

    constructor(private progressService : NgProgress, 
        private http: HttpClient, 
        private authService : AuthenticationService,
        private router: Router,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) {        
    }

    public login(usuario: any, onError?: (error) => void){
        return this.http.post<ResponseData<UsuarioModel>>(`${this.beneficioAddress}${this.beneficioUri}/autenticacoes-mobile/login`, usuario).toPromise();
    }
    
    public selecionarUnidade(token: string, idContratante: string){
        return this.http.put<any>(`${this.beneficioAddress}${this.beneficioUri}/autenticacoes-mobile/login/${token}/contratantes/${idContratante}`, {}).toPromise();
    }

    public recuperarLogin(){
        return this.http.get<ResponseData<UsuarioModel>>(`${this.beneficioAddress}${this.beneficioUri}/autenticacoes-mobile/login`).toPromise();
    }

    public aceitarTermos(token: any, termoAceiteId){
        return this.http.put<ResponseData<UsuarioModel>>(`${this.beneficioAddress}${this.beneficioUri}/autenticacoes-mobile/login/${token}/termos-aceites/${termoAceiteId}/aceita`, {}).toPromise();
    }

    public autenticarChat(token: any, subscriberId: string){
        return this.http.put<ResponseData<UsuarioModel>>(`${this.beneficioAddress}${this.beneficioUri}/autenticacoes-mobile/login/${token}/chat/${subscriberId}/subscribe`, {}).toPromise();
    }

    public autenticarTokenCode(tokenAutorizacao: any, autenticacaoTokenCodeRequest: AutenticacaoTokenCodeRequest){
        return this.http.put<ResponseData<UsuarioModel>>(`${this.beneficioAddress}${this.beneficioUri}/autenticacoes-mobile/login/${tokenAutorizacao}/autentica-token-code`, autenticacaoTokenCodeRequest).toPromise();
    }

    public reenviarTokenCode(tokenAutorizacao: any){
        return this.http.put<ResponseData<UsuarioModel>>(`${this.beneficioAddress}${this.beneficioUri}/autenticacoes-mobile/login/${tokenAutorizacao}/reenvia-token-code`, {}).toPromise();
    }
    
    public logout(){
        this.authService.deauthenticate();
    }
}