import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NOME_FILTRO_EXTRATO_BANCARIO } from 'src/app/app.config';
import { LocalStorageService } from 'src/app/comum/services/local-storage.service';
import { FiltroExtratoBancarioRequest } from '../models/filtroExtratoBancarioRequest';
import { UsuarioCarteiraDigitalService } from '../usuario-carteira-digital.service';

@Injectable()
export class UsuarioCarteiraDigitalExtratoResolver {

  constructor(private usuarioCarteiraDigitalService: UsuarioCarteiraDigitalService, private localStorageService: LocalStorageService) { }

  resolve(route: ActivatedRouteSnapshot) {
    let filtroExtratoBancarioRequest: FiltroExtratoBancarioRequest;
    filtroExtratoBancarioRequest = this.localStorageService.recuperarItem<FiltroExtratoBancarioRequest>(NOME_FILTRO_EXTRATO_BANCARIO);

    filtroExtratoBancarioRequest = new FiltroExtratoBancarioRequest(filtroExtratoBancarioRequest ? filtroExtratoBancarioRequest.inicio : undefined,
        filtroExtratoBancarioRequest ? filtroExtratoBancarioRequest.fim : undefined, 0, 10);
    

    this.localStorageService.salvarItem(NOME_FILTRO_EXTRATO_BANCARIO, filtroExtratoBancarioRequest);

    return this.usuarioCarteiraDigitalService.recuperarExtratoBancario(filtroExtratoBancarioRequest).then(registro => {
      return registro;
    });
  }
}
