<h4 mat-dialog-title>Novo fabricante</h4>

<mat-dialog-content>
    <form [formGroup]="this.fabricanteForm">

        <div fxLayout="row">
            <mat-form-field fxFlex appearance="outline">
                <mat-label>Nome/Razão social</mat-label>
                <input matInput type="text" placeholder="Insira o nome/razão social do fabricante."
                    formControlName="nomeRazaoSocial" id="inputNomeRazaoSocial" />
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex appearance="outline">
                <mat-label>Nome Fantasia</mat-label>
                <input matInput type="text" placeholder="Insira o nome fantasia do fabricante."
                    formControlName="nomeFantasia" id="inputNomeFantasia" />
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field fxFlex appearance="outline">
                <mat-label>Cpf/Cnpj</mat-label>
                <input matInput type="text" placeholder="Insira o cpf/cnpj do fabricante." formControlName="cpfCnpj"
                    id="inputCpfCnpj" />
            </mat-form-field>
            <mat-form-field fxFlex appearance="outline">
                <mat-label>E-mail</mat-label>
                <input matInput type="email" placeholder="Insira o e-mail do fabricante." formControlName="email"
                    id="inputEmail" />
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Empresa</mat-label>
                <mat-select placeholder="Selecione a empresa" #empresa formControlName="empresa" id="empresa">
                  <mat-option *ngFor="let empresa of this.empresas" [value]="empresa.id">
                    {{ empresa.descricao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="1rem">
    <button mat-button color="warn" [mat-dialog-close]="false" id="buttonCancelar">
        <span>Cancelar</span>
    </button>
    <button mat-button color="primary" [disabled]="this.fabricanteForm.invalid" (click)="this.salvar()"
        id="buttonSalvar">
        <span>Salvar</span>
    </button>
</mat-dialog-actions>