
import { Routes, RouterModule } from '@angular/router';
// Guards
import { GeneralRouteGuard } from '../seguranca/general-route-guard';
import { ProdutoListaComponent } from './produto-lista/produto-lista.component';

// Resolver
import { ProdutoListaResolver } from './produto-lista/produto-lista.resolver';
import { NgModule } from '@angular/core';

const routes: Routes =
    [
        {
            path: '',
            children: [
                {
                    path: '',
                    redirectTo: 'list',
                    pathMatch: 'full'
                },
                {
                    path: 'list',
                    component: ProdutoListaComponent,
                    canActivate: [GeneralRouteGuard],
                    resolve:
                    {
                        produtos: ProdutoListaResolver
                    }
                }
            ]
        }
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ProdutoRoutingModule { }
