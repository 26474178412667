import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { UsuarioContaBancaria } from './models/usuarioContaBancaria';
import { InserirUsuarioContaBancariaRequest } from './models/inserirUsuarioContaBancariaRequest';
import { ContaBancaria } from './models/contaBancaria';

@Injectable({
    providedIn: 'root'
})
export class UsuarioContaBancariaService {

    constructor(private readonly http: HttpClient,
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }


    incluir(inserirUsuarioContaBancariaRequest: InserirUsuarioContaBancariaRequest) {
        return this.http.post<ResponseData<ContaBancaria>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-bancarias`, inserirUsuarioContaBancariaRequest);
    }

    verificarStatus(idUsuarioContaBancaria: string) {
        return this.http.put<ResponseData<ContaBancaria>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-bancarias/${idUsuarioContaBancaria}`, {});
    }

    recuperar() {
        return this.http.get<ResponseData<ContaBancaria[]>>(`${this.beneficioAddress}${this.beneficioUri}/banking/contas-bancarias`);
    }
}
