import { HttpParams } from "@angular/common/http";
import { EnumValue } from "src/app/comum/models/enumValue";
import { Usuario } from "src/app/usuario/models/usuario";

export class FiltroExtratoBancarioRequest {
    constructor(public inicio: Date,
        public fim: Date,
        public pular: number,
        public quantidade: number)
    {
    }

    alterarPular(pular: number) {
        this.pular = pular;
    }
    alterarQuantidade(quantidade: number) {
        this.quantidade = quantidade;
    }

    queryString() {
        return new HttpParams({ fromString: JSON.stringify(this) });
    }
}
