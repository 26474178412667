export enum StatusCampanhaEnum {
        Rascunho = 1,
        Vigente = 2,
        Interrompida = 3,
        Encerrando = 4,
        Totalizada = 5,
        AguardandoFundos = 6,
        LiquidacaoLiberada = 7,
        Liquidando = 8,
        Finalizada = 9,
        Cancelada = 99,
}
