import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SubGrupo } from './models/subGrupo';
import { SubGrupoFiltro } from './models/subGrupoFiltro';
import { BENEFICIO_URI, BENEFICIO_ADDRESS } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { InserirSubGrupoRequest } from './models/inserirSubGrupoRequest';
import { ResponseData } from '../comum/models/responseData';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { AlterarSubGrupoRequest } from './models/alterarSubGrupoRequest';
import { SubGrupoFiltroRequest } from './models/subGrupoFiltroRequest';

@Injectable({ providedIn: 'root'})
export class SubGrupoService {

  constructor(private http: HttpClient, 
    private queryParamsService: QueryParamsService,
    @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
    @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

  public recuperarPorFiltro(filtro: SubGrupoFiltroRequest) {
    return this.http.get<ResponseData<SubGrupo[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/sub-grupos`, {params: this.queryParamsService.gerarQuery(filtro) }).toPromise();
  }

  public recuperarPorId(id: string) {
    return this.http.get<SubGrupo>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/sub-grupos/${id}`).toPromise();
  }

  public incluir(subGrupo: InserirSubGrupoRequest) {
    return this.http.post<ResponseData<SubGrupo>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/sub-grupos`, subGrupo).toPromise();
  }

  public alterar(id: any, subGrupo: AlterarSubGrupoRequest) {
    return this.http.put<ResponseData<SubGrupo>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/sub-grupos/${id}`, subGrupo).toPromise();
  }

  public deletar(id: any) {
    return this.http.delete<any>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/sub-grupos/${id}`).toPromise();
  }

  public listarAutoComplete(contem: string, empresaId: string) {
    return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/sub-grupos/combobox`, {params: {conteudoPesquisa : contem  , empresaId: empresaId}}).toPromise();
  }
}
