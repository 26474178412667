<div class="background-login" fxLayout="column" fxLayoutAlign="center stretch">

  <div fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center start">
    <mat-card class="card">
      <div fxLayout="column" fxLayoutAlign="start stretch">

        <div fxLayout="row" fxFlexAlign="center">
          <img fxAlign="start" class="img-responsive" src="assets/logo.png">
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="start center" *ngIf="this.exibirLogin == true">
          <form [formGroup]="credenciais" role="form" (ngSubmit)="login()" fxLayout="column"
            fxLayoutAlign="start stretch" fxFlex>
            <mat-form-field appearance="outline" fxFlexAlign="stretch">
              <mat-label> E-mail, CPF</mat-label>
              <input matInput type="emailCpf" formControlName="emailCpf" id="emailCpf">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlexAlign="stretch">
              <mat-label> Senha</mat-label>
              <input matInput [type]="view ?  'text' : 'password' " formControlName="senha" id="senha"
                placeholder="Senha">
              <span matSuffix>
                <mat-icon matSuffix (click)="view = !view" color="primary">{{view ? 'visibility': 'visibility_off'}}
                </mat-icon>
              </span>

            </mat-form-field>
            <div fxLayout="column" fxLayoutAlign="space-around stretch" class="btn-column-group" fxLayoutGap="20px">
              <button type="submit" class="login-button" mat-button mat-stroked-button class.dark-theme="true"
                color="primary" style="height: 60px" id="btnLogin">Continuar</button>
              <!--<button mat-button type="button" [routerLink]="['/signup']" class="btn-primary-color ">Registrar</button>-->
              <button mat-button type="button" class="login-button" color="primary" (click)="this.habilitarRecuperarLogin()">Esqueci a
                senha</button>
            </div>
          </form>
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="center start" *ngIf="this.exibirRecuperarLogin == true">
          <form [formGroup]="recuperaLoginForm" role="form" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
            <mat-form-field appearance="outline">
              <mat-label>E-mail, CPF</mat-label>
              <span matPrefix>
                <mat-icon>email</mat-icon>
              </span>
              <input matInput type="emailCpf" formControlName="emailCpf" id="emailCpf">
            </mat-form-field>
            <div fxLayout="column" fxLayoutAlign="space-around stretch" class="btn-column-group">
              <button mat-button mat-raised-button class.dark-theme="true" color="primary" (click)="recuperarLogin()"
                id="btnRecuperarLogin">Recuperar senha</button>
              <button mat-button type="button" color="accent"
                (click)="this.habilitarLogin(this.recuperaLoginForm.value.emailCpf)">Cancelar</button>
            </div>
          </form>
        </div>
        <div fxFlex fxLayout="row" fxLayoutAlign="center start" *ngIf="this.exibirAutenticacaoTokenCode == true">
          <form [formGroup]="autenticaTokenCodeForm" role="form" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
            <mat-form-field appearance="outline">
              <mat-label>Código de autorização</mat-label>
              <span matPrefix>
                <mat-icon>password</mat-icon>
              </span>
              <input matInput type="text" formControlName="tokenCode" id="tokenCode">
            </mat-form-field>
            <div fxLayout="column" fxLayoutAlign="space-around stretch" class="btn-column-group">
              <button mat-button mat-raised-button class.dark-theme="true" color="primary" (click)="autenticarTokenCode()"
                id="btnAutenticarTokenCode">Verificar código</button>
              <button mat-button type="button" color="accent" (click)="this.reenviarTokenCode()">Reenviar</button>
            </div>
          </form>
        </div>
      </div>
    </mat-card>
  </div>
</div>