import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageService } from '../../comum/services/local-storage.service';
import { NOME_FILTRO_CAMPANHA } from '../../app.config';
import { MovimentoConst } from '../../comum/consts/movimento.const';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { GrupoService } from 'src/app/grupo/grupo.service';
import { SubGrupoService } from 'src/app/sub-grupo/sub-grupo.service';
import { FabricanteService } from 'src/app/fabricante/fabricante.service';
import { UnidadeMedidaService } from 'src/app/unidade-medida/unidade-medida.service';
import { StatusCampanhaConst } from 'src/app/comum/consts/statusCampanha.const';

@Component({
    selector: 'app-campanha-filtro-modal',
    templateUrl: './campanha-filtro-modal.component.html',
    styleUrls: ['./campanha-filtro-modal.component.scss']
})
export class CampanhaFiltroModalComponent implements OnInit {
    filtroCampanha: UntypedFormGroup;
    statusCampanhas: Array<object>;
    constructor(public dialogRef: MatDialogRef<CampanhaFiltroModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: UntypedFormBuilder,
        private localStorageService: LocalStorageService,) {
        this.filtroCampanha = this.formBuilder.group({
            nome: [data ? data.nome : null],
            statusCampanha: [data ? data.statusCampanha : null]
        });

        this.statusCampanhas = StatusCampanhaConst;

    }

    ngOnInit() {
    }

    cancelar(): void {
        this.dialogRef.close();
    }

    salvar() {
        this.localStorageService.salvarItem(NOME_FILTRO_CAMPANHA, this.filtroCampanha.value);

        this.dialogRef.close(true);
    }

    displayFn(option: any): string {
        if (option === null || option === undefined) {
          return;
        }
        if (option.valor === undefined) {
          return option.descricao;
        }
        return option.valor;
      }

}
