import { EnumValue } from "src/app/comum/models/enumValue";
import { Empresa } from "src/app/empresa/models/empresa";

export class TermoAceiteDataModel {

    constructor(public token: string,
    public termoAceiteId: string)
    {

    }
}
