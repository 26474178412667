
export class InserirFabricanteRequest {
    constructor(
        public nomeRazaoSocial: string,
        public nomeFantasia: string,
        public cpfCnpj: string,
        public email: string,
        public empresaId: string)
    {

    }
}
