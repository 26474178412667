import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { ComponentesModule } from '../comum/componentes/componentes.module';
import { UsuarioContaBancariaModule } from '../usuario-conta-bancaria/usuario-conta-bancaria.module';
import { UsuarioCarteiraDigitalExtratoComponent } from './usuario-carteira-digital-extrato/usuario-carteira-digital-extrato.component';
import { UsuarioCarteiraDigitalExtratoResolver } from './usuario-carteira-digital-extrato/usuario-carteira-digital-extrato.resolver';
import { UsuarioCarteiraDigitalRoutingModule } from './usuario-carteira-digital.routing';
import { UsuarioCarteiraDigitalService } from './usuario-carteira-digital.service';
import { UsuarioCarteiraDigitalExtratoFiltroModalComponent } from './usuario-carteira-digital-extrato-filtro-modal/usuario-carteira-digital-extrato-filtro-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
    declarations: [UsuarioCarteiraDigitalExtratoComponent, UsuarioCarteiraDigitalExtratoFiltroModalComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatInputModule,
        FlexLayoutModule,
        MatTooltipModule,
        MatToolbarModule,
        MatMenuModule,
        NgxMaskModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatListModule,
        ComponentesModule,
        UsuarioCarteiraDigitalRoutingModule,
        ScrollingModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatIconModule,
        MatDatepickerModule
    ],
    providers: [
        UsuarioCarteiraDigitalService,
        UsuarioCarteiraDigitalExtratoResolver ,
    ],
    exports: [UsuarioCarteiraDigitalExtratoComponent]
})
export class UsuarioCarteiraDigitalModule { }
