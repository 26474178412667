import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { Injectable } from "@angular/core";


@Injectable()
export class PtBrPaginator implements MatPaginatorIntl {
    changes: Subject<void>;
    getRangeLabel: (page: number, pageSize: number, length: number) => string;

    firstPageLabel = 'Primeira página';
    lastPageLabel = 'Ultima página';
    itemsPerPageLabel = 'Itens por página';
    nextPageLabel = 'Próxima';
    previousPageLabel = 'Anterior';

    constructor() {
        this.changes = new Subject<void>();
        this.getRangeLabel = (page: number, pageSize: number, length: number) =>  {

            if (length === 0 || pageSize === 0) {
                return '0 de ' + length;
            }

            length = Math.max(length, 0);

            const startIndex = page * pageSize;

            const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

            return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
        };
    }
}
