import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators } from '@angular/forms';
import { SubGrupoService } from '../sub-grupo.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MovimentoConst } from 'src/app/comum/consts/movimento.const';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { InserirSubGrupoRequest } from '../models/inserirSubGrupoRequest';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sub-grupo-incluir-modal',
  templateUrl: './sub-grupo-incluir-modal.component.html',
  styleUrls: ['./sub-grupo-incluir-modal.component.scss']
})
export class SubGrupoIncluirModalComponent implements OnInit {

  subGrupoFormGroup: UntypedFormGroup;
  empresas: ComboBoxItem[];

  constructor(private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private subGrupoService: SubGrupoService,
    private empresaService: EmpresaService,
    public location: Location,
    private readonly ref: MatDialogRef<SubGrupoIncluirModalComponent>) {
  }


  ngOnInit() {

    this.subGrupoFormGroup = this.formBuilder.group({
      nome: [null, Validators.required],
      empresa: [null, Validators.required]
    });

    this.empresaService.listarVinculados()
      .then
      (response => {
        this.empresas = response.data;
        this.subGrupoFormGroup.get("empresa").setValue(this.empresas.length == 1 ? this.empresas[0].id : null);
      });
  }

  async salvar() {
    if (!this.subGrupoFormGroup.valid) {
      throw new Error('Dados inválidos!');
    }

    let inserirSubGrupoRequest = new InserirSubGrupoRequest(this.subGrupoFormGroup.value.nome, this.subGrupoFormGroup.value.empresa)

    await this.subGrupoService.incluir(inserirSubGrupoRequest).then(response => {
      this.snackBar.open(`SubGrupo salva com sucesso!`, `Ok`, { duration: 2000 });
      this.ref.close(response.data);
    });
  }

  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }
}
