<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
  <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon>shopping_basket</mat-icon>
    <span class="p6n-action-bar-title">Cadastrar produto</span>
  </div>
</mat-toolbar>
<mat-dialog-content>

  <form [formGroup]="this.produtoFormGroup">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap.gt-xs="5px" fxFlex>
        <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-xs="5px" fxFlex>
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Nome</mat-label>
            <input matInput type="text" placeholder="Insira o nome do produto..." formControlName="nome" #nome>
            <mat-hint fxFlexAlign="end">{{nome.value.length}} / 200</mat-hint>
            <mat-error *ngIf="this.produtoFormGroup.controls.nome.hasError('required')">O nome é obrigatório</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Empresa</mat-label>
            <mat-select placeholder="Selecione a empresa" #empresa formControlName="empresa" id="empresa">
              <mat-option *ngFor="let empresa of this.empresas" [value]="empresa.id">
                {{ empresa.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Categoria</mat-label>
          <input matInput type="text" placeholder="Selecione o categoria do produto..."
            [matAutocomplete]="categoriaAutocomplete" id="inputCategoria" formControlName="categoria" #categoria>
          <mat-error *ngIf="this.produtoFormGroup.controls.nome.hasError('required')">A categoria é obrigatória
          </mat-error>
          <mat-autocomplete #categoriaAutocomplete [displayWith]="this.displayFn">
            <mat-option *ngFor="let categoria of this.categorias" [value]="categoria">{{categoria.descricao}}
            </mat-option>
          </mat-autocomplete>
          <button mat-icon-button matSuffix (click)="this.cadastrarCategoria(); $event.stopPropagation();"
            matTooltip="Cadastrar categoria">
            <mat-icon>add</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Grupo</mat-label>
          <input matInput type="text" placeholder="Selecione o grupo do produto..."
            [matAutocomplete]="grupoAutocomplete" id="inputGrupo" formControlName="grupo" #grupo>
          <mat-error *ngIf="this.produtoFormGroup.controls.nome.hasError('required')">O grupo é obrigatório
          </mat-error>
          <mat-autocomplete #grupoAutocomplete [displayWith]="this.displayFn">
            <mat-option *ngFor="let grupo of this.grupos" [value]="grupo">{{grupo.descricao}}
            </mat-option>
          </mat-autocomplete>
          <button mat-icon-button matSuffix (click)="this.cadastrarGrupo(); $event.stopPropagation();"
            matTooltip="Cadastrar grupo">
            <mat-icon>add</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Sub Grupo</mat-label>
          <input matInput type="text" placeholder="Selecione o sub grupo do produto..."
            [matAutocomplete]="subGrupoAutocomplete" id="inputSubGrupo" formControlName="subGrupo" #subGrupo>
          <mat-autocomplete #subGrupoAutocomplete [displayWith]="this.displayFn">
            <mat-option *ngFor="let subGrupo of this.subGrupos" [value]="subGrupo">
              {{subGrupo.descricao}}
            </mat-option>
          </mat-autocomplete>
          <button mat-icon-button matSuffix (click)="this.cadastrarSubGrupo(); $event.stopPropagation();"
            matTooltip="Cadastrar sub grupo">
            <mat-icon>add</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Fabricante</mat-label>
          <input matInput type="text" formControlName="fabricante" #fabricante
            placeholder="Selecione o fabricante do produto..." [matAutocomplete]="fabricanteAutocomplete"
            id="inputFabricante">
          <mat-autocomplete #fabricanteAutocomplete [displayWith]="this.displayFn">
            <mat-option *ngFor="let fabricante of this.fabricantes" [value]="fabricante">
              {{fabricante.descricao}}
            </mat-option>
          </mat-autocomplete>
          <button mat-icon-button matSuffix (click)="this.cadastrarFabricante(); $event.stopPropagation();"
            matTooltip="Cadastrar fabricante">
            <mat-icon>add</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Unidade de medida</mat-label>
          <input matInput type="text" placeholder="Selecione a unidade de medida do produto..."
            [matAutocomplete]="unidadeMedidaAutocomplete" id="inputUnidadeMedida" formControlName="unidadeMedida"
            #unidadeMedida>
          <mat-autocomplete #unidadeMedidaAutocomplete [displayWith]="this.displayFn">
            <mat-option *ngFor="let unidadeMedida of this.unidadesMedidas" [value]="unidadeMedida">
              {{unidadeMedida.descricao}}</mat-option>
          </mat-autocomplete>
          <button mat-icon-button matSuffix (click)="this.cadastrarUnidadeMedida(); $event.stopPropagation();"
            matTooltip="Cadastrar unidade de medida">
            <mat-icon>add</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field appearance="outline">
        <mat-label>Ncm</mat-label>
        <input matInput type="number" class="right" placeholder="Ex: 10999221" step="1" formControlName="ncm" #ncm>
        <mat-hint fxFlexAlign="end">{{ncm.value.length}} / 8</mat-hint>
        <mat-error *ngIf="this.produtoFormGroup.controls.ncm.hasError('required')">O ncm é obrigatório</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Cest</mat-label>
        <input matInput type="number" class="right" placeholder="Ex: 10999221" step="1" formControlName="cest" #cest>
        <mat-hint fxFlexAlign="end">{{cest.value.length}} / 7</mat-hint>
        <mat-error *ngIf="this.produtoFormGroup.controls.cest.hasError('required')">O cest é obrigatório</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Cfop</mat-label>
        <input matInput type="number" class="right" placeholder="Ex: 6105" step="1" formControlName="cfop" #cfop>
        <mat-hint fxFlexAlign="end">{{cfop.value.length}} / 4</mat-hint>
        <mat-error *ngIf="this.produtoFormGroup.controls.cfop.hasError('required')">O cfop é obrigatório</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Referência</mat-label>
        <input matInput type="text" placeholder="Insira a referencia do produto..." formControlName="referencia"
          #referencia>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Ean</mat-label>
        <input matInput type="number" class="right" placeholder="Ex: 79832659481532" step="1" formControlName="ean" #ean
          id="inputEan">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Estoque atual</mat-label>
        <input type="number" class="right" matInput formControlName="estoqueAtual" #estoqueAtual step="0.01"
          placeholder="Insira o estoque atual">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Estoque minimo</mat-label>
        <input type="number" class="right" matInput formControlName="estoqueMinimo" #estoqueMinimo step="0.01"
          placeholder="Insira o estoque minimo">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Estoque máximo</mat-label>
        <input type="number" class="right" matInput formControlName="estoqueMaximo" #estoqueMaximo step="0.01"
          placeholder="Insira o estoque máximo">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Desconto %</mat-label>
        <input matInput type="number" class="right" placeholder="Ex: 0,00" step="0.01"
          formControlName="percentualDesconto" #percentualDesconto>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Valor Desconto R$ </mat-label>
        <input matInput class="right" placeholder="Ex: 0,00" currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" formControlName="valorDesconto" #valorDesconto>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Desconto Maximo %</mat-label>
        <input matInput type="number" class="right" placeholder="Ex: 0,00" step="0.01" formControlName="descontoMaximo"
          #descontoMaximo>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Custo R$</mat-label>
        <input matInput class="right" placeholder="Ex: 0,00" currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" formControlName="custo" #custo id="inputCusto">
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Lucro %</mat-label>
        <input matInput class="right" placeholder="Ex: 0,00" currencyMask
          [options]="{ prefix: '', suffix: ' %', thousands: '.', decimal: ',' }" formControlName="lucro" #lucro
          id="inputLucro">
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Venda R$</mat-label>
        <input matInput class="right" placeholder="Ex: 0,00" currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" formControlName="venda" #venda id="inputVenda">
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="1rem">
  <button mat-button color="warn" [mat-dialog-close]="false" id="buttonCancelar">
    <span>Cancelar</span>
  </button>
  <button mat-button color="primary" [disabled]="this.produtoFormGroup.invalid" (click)="this.salvar()"
    id="buttonSalvar">
    <span>Salvar</span>
  </button>
</mat-dialog-actions>