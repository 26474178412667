
import { Routes, RouterModule } from '@angular/router';
import { GeneralRouteGuard } from '../seguranca/general-route-guard';
import { NgModule } from '@angular/core';
import { UsuarioCarteiraDigitalExtratoComponent } from './usuario-carteira-digital-extrato/usuario-carteira-digital-extrato.component';
import { UsuarioCarteiraDigitalExtratoResolver } from './usuario-carteira-digital-extrato/usuario-carteira-digital-extrato.resolver';

const routes: Routes =
    [
        {
            path: '',
            component: UsuarioCarteiraDigitalExtratoComponent,
            canActivate: [GeneralRouteGuard],
            resolve:
            {
               extratosBancarios: UsuarioCarteiraDigitalExtratoResolver
            },
            data :{ animation: 'movements'}
        }
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class UsuarioCarteiraDigitalRoutingModule { }
