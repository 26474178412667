<div class="router-content">
  <form [formGroup]="this.usuarioFormGroup">
    <div fxLayout="column" fxFlex fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex fxLayoutGap="10px">
        <h2 class="h-no-margin"><strong>Dados da conta </strong></h2>
        <mat-divider fxFlex style="border-width: 2px;border-color: gray;"></mat-divider>
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Cpf</mat-label>
          <input matInput type="text" class="right" placeholder="Ex: 999.999.999-99" step="1" formControlName="cpf" #cpf
            mask="00.000.0000-00">
          <mat-error *ngIf="this.usuarioFormGroup.controls.cpf.hasError('required')">O cpf é obrigatório
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="20">
          <mat-label>DDD Celular</mat-label>
          <input matInput type="text" class="right" placeholder="Ex: (99)" step="1" formControlName="dddCelular"
            #dddCelular mask="00">
          <mat-error *ngIf="this.usuarioFormGroup.controls.dddCelular.hasError('required')">O ddd do celular é
            obrigatório
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Celular</mat-label>
          <input matInput type="text" class="right" placeholder="Ex: (99)" step="1" formControlName="celular" #celular
            mask="00000-0000">
          <mat-error *ngIf="this.usuarioFormGroup.controls.celular.hasError('required')">O celular é obrigatório
          </mat-error>
        </mat-form-field>
        
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>E-mail</mat-label>
          <input matInput type="email" placeholder="Insira o e-mail do usuário" formControlName="email"
            id="inputEmail" />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex fxLayoutGap="10px">
        <h2 class="h-no-margin"><strong>Dados pessoais </strong></h2>
        <mat-divider fxFlex style="border-width: 2px;border-color: gray;"></mat-divider>
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Nome</mat-label>
          <input matInput type="text" placeholder="Insira o nome do usuário..." formControlName="nome" #nome>
          <mat-error *ngIf="this.usuarioFormGroup.controls.nome.hasError('required')">O nome é obrigatório
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Sobrenome</mat-label>
          <input matInput type="text" placeholder="Insira o sobrenome do usuário..." formControlName="sobrenome"
            #sobrenome>
          <mat-error *ngIf="this.usuarioFormGroup.controls.sobrenome.hasError('required')">O sobrenome é
            obrigatório
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex="20">
          <mat-label>DDD Telefone</mat-label>
          <input matInput type="text" class="right" placeholder="Ex: (99)" step="1" formControlName="dddTelefone"
            #dddTelefone mask="00">
          <mat-error *ngIf="this.usuarioFormGroup.controls.dddTelefone.hasError('required')">O ddd do telefone é
            obrigatório
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Telefone</mat-label>
          <input matInput type="text" class="right" placeholder="Ex: (99)" step="1" formControlName="telefone" #telefone
            mask="00000-0000">
          <mat-error *ngIf="this.usuarioFormGroup.controls.telefone.hasError('required')">O telefone é obrigatório
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Nascimento</mat-label>
          <input type="text" matInput placeholder="Insira a data de nascimento" formControlName="nascimento"
            mask="00/00/0000">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex fxLayoutGap="10px">
        <h2 class="h-no-margin"><strong>Endereço </strong></h2>
        <mat-divider fxFlex style="border-width: 2px;border-color: gray;"></mat-divider>
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="10px">
        <mat-form-field appearance="outline">
          <mat-label>Cep</mat-label>
          <input matInput type="text" placeholder="Insira o cep da empresa" #cep formControlName="cep"
            mask="00.000-000">
          <mat-error *ngIf="this.usuarioFormGroup['controls'].cep.hasError('required')">O cep
            é obrigatório</mat-error>
          <mat-error *ngIf="this.usuarioFormGroup['controls'].cep.hasError('maxlength')">O
            cep deve ter 8 digitos</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>Tipo logradouro</mat-label>
          <mat-select placeholder="Selecione o tipo de logradouro" #tipoLogradouro formControlName="tipoLogradouro"
            id="tipoLogradouro">
            <mat-option *ngFor="let tipoLogradouroConst of tiposLogradouros" [value]="tipoLogradouroConst.value">
              {{ tipoLogradouroConst.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Logradouro</mat-label>
          <input matInput type="text" placeholder="Insira o logradouro da emresa" #logradouro
            formControlName="logradouro">
          <mat-error *ngIf="this.usuarioFormGroup['controls'].logradouro.hasError('required')">O
            logradouro é obrigatório</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>Número</mat-label>
          <input matInput type="text" placeholder="Insira o número da empresa" #numero formControlName="numero">
          <mat-error *ngIf="this.usuarioFormGroup['controls'].numero.hasError('required')">O
            número é obrigatório</mat-error>
          <mat-error *ngIf="this.usuarioFormGroup['controls'].numero.hasError('max')">O número
            máximo é 9999999</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Complemento</mat-label>
          <input matInput type="text" placeholder="Insira o complemento do endereço" #complemento
            formControlName="complemento">
          <mat-error *ngIf="this.usuarioFormGroup['controls'].complemento.hasError('required')">O
            complemento é obrigatório</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>Tipo bairro</mat-label>
          <mat-select placeholder="Selecione o tipo de bairro" #tipoBairro formControlName="tipoBairro" id="tipoBairro">
            <mat-option *ngFor="let tipoBairroConst of tiposBairros" [value]="tipoBairroConst.value">
              {{ tipoBairroConst.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Bairro</mat-label>
          <input matInput type="text" placeholder="Insira o bairro do endereço" #bairro formControlName="bairro">
          <mat-error *ngIf="this.usuarioFormGroup['controls'].bairro.hasError('required')">O
            bairro é obrigatório</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Cidade</mat-label>
          <input matInput type="text" placeholder="Insira o município da empresa" #municipio
            formControlName="municipio">
          <mat-error *ngIf="this.usuarioFormGroup['controls'].municipio.hasError('required')">A
            municipio é obrigatória</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>Código Ibge</mat-label>
          <input matInput type="text" placeholder="Insira o código do municipio" #codigoMunicipio
            formControlName="codigoMunicipio">
          <mat-error *ngIf="this.usuarioFormGroup['controls'].codigoMunicipio.hasError('required')">O codigo ibge é
            obrigatório</mat-error>
          <mat-error *ngIf="this.usuarioFormGroup['controls'].codigoMunicipio.hasError('max')">O número
            máximo é 5400000</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxFlex fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Uf</mat-label>
          <mat-select placeholder="Insira a UF da empresa" formControlName="uf" id="uf">
            <mat-option *ngFor="let estado of estados" [value]="estado.value">
              {{ estado.descricao }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="this.usuarioFormGroup['controls'].uf.hasError('required')">A uf é
            obrigatória</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>País</mat-label>
          <mat-select placeholder="Selecione o pais" #codigoPais formControlName="codigoPais" id="codigoPais">
            <mat-option *ngFor="let codigoPaisConst of codigosPaises" [value]="codigoPaisConst.value">
              {{ codigoPaisConst.descricao }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <button mat-raised-button color="primary" [disabled]="this.usuarioFormGroup.invalid" (click)="this.salvar()"
            id="buttonSalvar">
            <span>Salvar</span>
          </button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <button mat-button color="primary" (click)="this.logout()" id="buttonSalvar">
            <span>Sair</span>
          </button>
        </div>
      </div>
    </div>

  </form>
  <br />



</div>