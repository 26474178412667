import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { CampanhaRemuneracao } from './models/campanhaRemuneracao';
import { InserirCampanhaRemuneracaoRequest } from './models/inserirCampanhaRemuneracaoRequest';
import { AlterarCampanhaRemuneracaoRequest } from './models/alterarCampanhaRemuneracaoRequest';
import { ComboBoxItem } from '../comum/models/comboBoxItem';

@Injectable({
  providedIn: 'root'
})
export class CampanhaRemuneracaoService {

    constructor(private readonly http: HttpClient, 
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    recuperarPorCampanha(idCampanha: string) {
        return this.http.get<ResponseData<CampanhaRemuneracao[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes`);
    }

    incluir(idCampanha: string, inserirCampanhaRemuneracaoRequest: InserirCampanhaRemuneracaoRequest) {
        return this.http.post<ResponseData<CampanhaRemuneracao>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes`, inserirCampanhaRemuneracaoRequest);
    }

    alterar(idCampanha: string, idCampanhaRemuneracao: string, alterarCampanhaRemuneracaoRequest: AlterarCampanhaRemuneracaoRequest) {
        return this.http.put<ResponseData<CampanhaRemuneracao>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}`, alterarCampanhaRemuneracaoRequest);
    }

    excluir(idCampanha: string, idCampanhaRemuneracao: string) {
        return this.http.delete(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}`);
    }

    public listarAutoComplete(idCampanha: string, contem: string) {
        return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/combobox`, {params: {conteudoPesquisa : contem}}).toPromise();
    }
}
