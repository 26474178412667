import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubCategoriaListaComponent } from './sub-categoria-lista/sub-categoria-lista.component';
import { SubCategoriaNovoComponent } from './sub-categoria-novo/sub-categoria-novo.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubCategoriaAlterarComponent } from './sub-categoria-alterar/sub-categoria-alterar.component';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatToolbarModule,
        MatCardModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatOptionModule,
        MatSelectModule
    ],
    declarations: [SubCategoriaListaComponent, SubCategoriaNovoComponent, SubCategoriaAlterarComponent],
    exports: [SubCategoriaListaComponent, SubCategoriaNovoComponent, SubCategoriaAlterarComponent]
})
export class SubCategoriaModule { }
