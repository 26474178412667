import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UsuarioService } from './usuario.service';
import { MaterialModule } from '../material/material.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UsuarioRoutingModule } from './usuario.routing';
import { ComponentesModule } from '../comum/componentes/componentes.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { UsuarioEmpresaModule } from '../usuario-empresa/usuario-empresa.module';
import { NgxMaskModule } from 'ngx-mask';
import { UsuarioSenhaModalComponent } from './usuario-senha-modal/usuario-senha-modal.component';
import { UsuarioFuncionarioModule } from '../usuario-funcionario/usuario-funcionario.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { UsuarioProfileComponent } from './usuario-profile/usuario-profile.component';
import { UsuarioProfileResolver } from './usuario-profile/usuario-profile.resolver';
import { UsuarioContaDigitalModule } from '../usuario-conta-digital/usuario-conta-digital.module';
import { UsuarioContaBancariaModule } from '../usuario-conta-bancaria/usuario-conta-bancaria.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatInputModule,
        RouterModule,
        FormsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatOptionModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        UsuarioRoutingModule,
        ComponentesModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatOptionModule,
        CurrencyMaskModule,
        UsuarioEmpresaModule,
        MatTableModule,
        MatTabsModule,
        NgxMaskModule,
        MatCheckboxModule,
        UsuarioFuncionarioModule,
        UsuarioContaDigitalModule,
        UsuarioContaBancariaModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDividerModule
    ],
    declarations: [UsuarioSenhaModalComponent, UsuarioProfileComponent],
    providers: [UsuarioService, UsuarioProfileResolver],
    exports: [
        UsuarioProfileComponent
    ]
})
export class UsuarioModule { }
