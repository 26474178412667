import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { CampanhaRemuneracaoCodigoBarras } from './models/campanhaRemuneracaoCodigoBarras';
import { InserirCampanhaRemuneracaoCodigoBarrasRequest } from './models/inserirCampanhaRemuneracaoCodigoBarrasRequest';

@Injectable({
    providedIn: 'root'
})
export class CampanhaRemuneracaoCodigoBarrasService {

    constructor(private readonly http: HttpClient,
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    async recuperarPorCampanhaRemuneracao(idCampanha: string, idCampanhaRemuneracao: string) {
        return await this.http.get<ResponseData<CampanhaRemuneracaoCodigoBarras[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/codigos-barras`).toPromise();
    }

    async recuperar(idCampanha: string, idCampanhaRemuneracao: string, idCampanhaRemuneracaoCodigoBarras: string) {
        return await this.http.get<ResponseData<CampanhaRemuneracaoCodigoBarras[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/codigos-barras/${idCampanhaRemuneracaoCodigoBarras}`).toPromise();
    }

    incluir(idCampanha: string, idCampanhaRemuneracao: string, inserirCampanhaRemuneracaoCodigoBarrasRequest: InserirCampanhaRemuneracaoCodigoBarrasRequest) {
        return this.http.post<ResponseData<CampanhaRemuneracaoCodigoBarras>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/codigos-barras`, inserirCampanhaRemuneracaoCodigoBarrasRequest);
    }

    excluir(idCampanha: string,idCampanhaRemuneracao: string, idCampanhaRemuneracaoCodigoBarras: string) {
        return this.http.delete(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/codigos-barras/${idCampanhaRemuneracaoCodigoBarras}`);
    }
}
