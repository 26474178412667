import { Produto } from '../models/produto';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';
import { MessageDialogService } from '../../message-dialog/message-dialog.service';
import { Validators } from '@angular/forms';
import { ProdutoService } from '../produto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MovimentoConst } from '../../comum/consts/movimento.const';
import { DialogResponseYes, DialogActionYesAndNo } from '../../message-dialog/message-dialog-action';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { AlterarProdutoRequest } from '../models/alterarProdutoRequest';
import { GrupoService } from 'src/app/grupo/grupo.service';
import { SubGrupoService } from 'src/app/sub-grupo/sub-grupo.service';
import { FabricanteService } from 'src/app/fabricante/fabricante.service';
import { UnidadeMedidaService } from 'src/app/unidade-medida/unidade-medida.service';
import { UnidadeMedidaIncluirModalComponent } from 'src/app/unidade-medida/unidade-medida-incluir-modal/unidade-medida-incluir-modal.component';
import { FabricanteNovoModalComponent } from 'src/app/fabricante/fabricante-novo-modal/fabricante-novo-modal.component';
import { GrupoIncluirModalComponent } from 'src/app/grupo/grupo-incluir-modal/grupo-incluir-modal.component';
import { SubGrupoIncluirModalComponent } from 'src/app/sub-grupo/sub-grupo-incluir-modal/sub-grupo-incluir-modal.component';
import { CategoriaService } from 'src/app/categoria/categoria.service';
import { CategoriaIncluirModalComponent } from 'src/app/categoria/categoria-incluir-modal/categoria-incluir-modal.component';

@Component({
  selector: 'app-produto-alterar-modal',
  templateUrl: './produto-alterar-modal.component.html',
  styleUrls: ['./produto-alterar-modal.component.scss']
})
export class ProdutoAlterarModalComponent implements OnInit {

  produtoFormGroup: UntypedFormGroup;
  empresas: ComboBoxItem[];
  categorias: ComboBoxItem[];
  grupos: ComboBoxItem[];
  subGrupos: ComboBoxItem[];
  unidadesMedidas: ComboBoxItem[];
  fabricantes: ComboBoxItem[];

  constructor(private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private produtoService: ProdutoService,
    private empresaService: EmpresaService,
    private categoriaService: CategoriaService,
    private grupoService: GrupoService,
    private subGrupoService: SubGrupoService,
    private fabricanteService: FabricanteService,
    private unidadeMedidaService: UnidadeMedidaService,
    public location: Location,
    private readonly ref: MatDialogRef<ProdutoAlterarModalComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly dialog: MatDialog) {
  }

  ngOnInit() {
    this.produtoFormGroup = this.formBuilder.group({
      id: [null, Validators.required],
      nome: [null, [Validators.required]],
      codigo: [null, [Validators.required]],
      referencia: [null],
      categoria: [null],
      grupo: [null],
      subGrupo: [null],
      fabricante: [null],
      ncm: [null],
      unidadeMedida: [null],
      cest: [null],

      custo: [null, [Validators.required]],
      lucro: [null, [Validators.required]],
      venda: [null, [Validators.required]],
      estoqueAtual: [null],
      estoqueMaximo: [null],
      estoqueMinimo: [null],
      cfop: [null],
      ean: [null, [Validators.maxLength(20)]],

      percentualDesconto: [null],
      valorDesconto: [null],
      descontoMaximo: [null],

      empresa: [null, [Validators.required]],
    });

    this.empresaService.listarVinculados()
      .then
      (response => {
        this.empresas = response.data;
        this.produtoFormGroup.get("empresa").setValue(this.empresas.length == 1 ? this.empresas[0].id : null);
      });

    this.atribuirValores(this.data);

    this.produtoFormGroup
      .get('categoria')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.produtoFormGroup.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.categoriaService.listarAutoComplete(value, this.produtoFormGroup.value.empresa)
          .then
          (response => {
            this.categorias = response.data;
          });
      });

    this.produtoFormGroup
      .get('grupo')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.produtoFormGroup.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.grupoService.listarAutoComplete(value, this.produtoFormGroup.value.empresa)
          .then
          (response => {
            this.grupos = response.data;
          });
      });

    this.produtoFormGroup
      .get('subGrupo')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.produtoFormGroup.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.subGrupoService.listarAutoComplete(value, this.produtoFormGroup.value.empresa)
          .then
          (response => {
            this.subGrupos = response.data;
          });
      });

    this.produtoFormGroup
      .get('fabricante')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.produtoFormGroup.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.fabricanteService.listarAutoComplete(value, this.produtoFormGroup.value.empresa)
          .subscribe
          (response => {
            this.fabricantes = response.data;
          });
      });

    this.produtoFormGroup
      .get('unidadeMedida')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.produtoFormGroup.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.unidadeMedidaService.listarAutoComplete(value, this.produtoFormGroup.value.empresa)
          .then
          (response => {
            this.unidadesMedidas = response.data;
          });
      });
  }

  async salvar() {
    if (!this.produtoFormGroup.valid) {
      throw new Error('Dados inválidos!');
    }

    let alterarProdutoRequest = new AlterarProdutoRequest(this.produtoFormGroup.value.nome,
      this.produtoFormGroup.value.referencia,
      this.produtoFormGroup.value.categoria ? this.produtoFormGroup.value.categoria.id : null,
      this.produtoFormGroup.value.grupo ? this.produtoFormGroup.value.grupo.id : null,
      this.produtoFormGroup.value.subGrupo ? this.produtoFormGroup.value.subGrupo.id : null,
      this.produtoFormGroup.value.fabricante ? this.produtoFormGroup.value.fabricante.id : null,
      this.produtoFormGroup.value.ncm,
      this.produtoFormGroup.value.unidadeMedida ? this.produtoFormGroup.value.unidadeMedida.id : null,
      this.produtoFormGroup.value.cest,
      this.produtoFormGroup.value.custo,
      this.produtoFormGroup.value.lucro,
      this.produtoFormGroup.value.venda,
      this.produtoFormGroup.value.estoqueAtual,
      this.produtoFormGroup.value.estoqueMaximo,
      this.produtoFormGroup.value.estoqueMinimo,
      this.produtoFormGroup.value.cfop,
      this.produtoFormGroup.value.ean,
      this.produtoFormGroup.value.percentualDesconto,
      this.produtoFormGroup.value.valorDesconto,
      this.produtoFormGroup.value.descontoMaximo);

    await this.produtoService.alterar(this.produtoFormGroup.value.id, alterarProdutoRequest).then(response => {
      this.snackBar.open(`Produto alterada com sucesso!`, `Ok`, { duration: 2000 });
      this.ref.close(response.data);
    });
  }

  atribuirValores(produto: any) {
    if (produto) {
      this.produtoFormGroup.controls['id'].setValue(produto.id);
      this.produtoFormGroup.controls['nome'].setValue(produto.nome);
      this.produtoFormGroup.controls['codigo'].setValue(produto.codigo);
      this.produtoFormGroup.controls['referencia'].setValue(produto.referencia);
      this.produtoFormGroup.controls['categoria'].setValue(produto.categoria ? { id: produto.categoria.id, descricao: produto.categoria.nome } : null);
      this.produtoFormGroup.controls['grupo'].setValue(produto.grupo ? { id: produto.grupo.id, descricao: produto.grupo.nome } : null);
      this.produtoFormGroup.controls['subGrupo'].setValue(produto.subGrupo ? { id: produto.subGrupo.id, descricao: produto.subGrupo.nome } : null);
      this.produtoFormGroup.controls['fabricante'].setValue(produto.fabricante ? { id: produto.fabricante.id, descricao: produto.fabricante.nomeRazaoSocial } : null);
      this.produtoFormGroup.controls['ncm'].setValue(produto.ncm);
      this.produtoFormGroup.controls['unidadeMedida'].setValue(produto.unidadeMedida ? { id: produto.unidadeMedida.id, descricao: produto.unidadeMedida.nome } : null);
      this.produtoFormGroup.controls['cest'].setValue(produto.cest);

      this.produtoFormGroup.controls['custo'].setValue(produto.custo);
      this.produtoFormGroup.controls['lucro'].setValue(produto.lucro);
      this.produtoFormGroup.controls['venda'].setValue(produto.venda);
      this.produtoFormGroup.controls['estoqueAtual'].setValue(produto.estoqueAtual);
      this.produtoFormGroup.controls['estoqueMaximo'].setValue(produto.estoqueMaximo);
      this.produtoFormGroup.controls['estoqueMinimo'].setValue(produto.estoqueMinimo);
      this.produtoFormGroup.controls['cfop'].setValue(produto.cfop);
      this.produtoFormGroup.controls['ean'].setValue(produto.ean);

      this.produtoFormGroup.controls['percentualDesconto'].setValue(produto.percentualDesconto);
      this.produtoFormGroup.controls['valorDesconto'].setValue(produto.valorDesconto);
      this.produtoFormGroup.controls['descontoMaximo'].setValue(produto.descontoMaximo);

      this.produtoFormGroup.controls['codigoIntegracao'].setValue(produto.codigoIntegracao);
      this.produtoFormGroup.controls['empresa'].setValue(produto.empresa.id);
    }
  }

  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }

  cadastrarCategoria() {
    const ref = this.dialog.open(CategoriaIncluirModalComponent, { width: '500px' });
    ref.afterClosed().subscribe(response => {

      if (response) {
        this.snackBar.open(`Categoria cadastrada com sucesso!`,
          `Ok`, { duration: 3000 });
        this.produtoFormGroup.get('categoria').setValue({ id: response.id, descricao: response.nome });

      } else {
        this.snackBar.open(`Edição do item cancelada!`,
          `Ok`, { duration: 3000 });
      }
    });
  }

  cadastrarSubGrupo() {
    const ref = this.dialog.open(SubGrupoIncluirModalComponent, { width: '500px' });

    ref.afterClosed().subscribe(response => {

      if (response) {
        this.snackBar.open(`Sub grupo cadastrado com sucesso!`,
          `Ok`, { duration: 3000 });
        this.produtoFormGroup.get('subGrupo').setValue({ id: response.id, descricao: response.nome });

      } else {
        this.snackBar.open(`Edição do item cancelada!`,
          `Ok`, { duration: 3000 });
      }
    });

  }

  cadastrarGrupo() {
    const ref = this.dialog.open(GrupoIncluirModalComponent, { width: '500px' });
    ref.afterClosed().subscribe(response => {

      if (response) {
        this.snackBar.open(`Grupo cadastrado com sucesso!`,
          `Ok`, { duration: 3000 });
        this.produtoFormGroup.get('grupo').setValue({ id: response.id, descricao: response.nome });

      } else {
        this.snackBar.open(`Edição do item cancelada!`,
          `Ok`, { duration: 3000 });
      }
    });
  }

  cadastrarFabricante() {
    const ref = this.dialog.open(FabricanteNovoModalComponent, { width: '600px' });
    ref.afterClosed().subscribe(response => {

      if (response) {
        this.snackBar.open(`Fabricante cadastrado com sucesso!`,
          `Ok`, { duration: 3000 });
        this.produtoFormGroup.get('fabricante').setValue({ id: response.id, descricao: response.nomeRazaoSocial });

      } else {
        this.snackBar.open(`Edição do item cancelada!`,
          `Ok`, { duration: 3000 });
      }
    });
  }

  cadastrarUnidadeMedida() {
    const ref = this.dialog.open(UnidadeMedidaIncluirModalComponent, { width: '600px' });
    ref.afterClosed().subscribe(response => {

      if (response) {
        this.snackBar.open(`UnidadeMedida cadastrado com sucesso!`,
          `Ok`, { duration: 3000 });
        this.produtoFormGroup.get('unidadeMedida').setValue({ id: response.id, descricao: `${response.abreviacao} - ${response.nome}` });

      } else {
        this.snackBar.open(`Edição do item cancelada!`,
          `Ok`, { duration: 3000 });
      }
    });
  }
}
