import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginService } from './login.service';
import { MessageDialogModule } from '../message-dialog/message-dialog.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SegurancaModule } from '../seguranca/seguranca.module';
import { LoginRoutingModule } from './login.routing';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { UsuarioModule } from '../usuario/usuario.module';
import { TermoAceiteModule } from '../termo-aceite/termo-aceite.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatInputModule,
        FlexLayoutModule,
        MessageDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatChipsModule,
        LoginRoutingModule,
        MatFormFieldModule,
        MatListModule,
        MatRadioModule,
        UsuarioModule,
        MatDialogModule,
        TermoAceiteModule
    ],
    declarations: [LoginComponent, LoginModalComponent],
    providers: [LoginService],
    exports: [LoginComponent]
})
export class LoginModule { }
