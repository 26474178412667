import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CampanhaFuncionarioMetaModule } from '../campanha-funcionario-meta/campanha-funcionario-meta.module';
import { CampanhaRemuneracaoCodigoBarrasModule } from '../campanha-remuneracao-codigo-barras/campanha-remuneracao-codigo-barras.module';
import { CampanhaRemuneracaoColocacaoModule } from '../campanha-remuneracao-colocacao/campanha-remuneracao-colocacao.module';
import { CampanhaRemuneracaoDesempenhoModule } from '../campanha-remuneracao-desempenho/campanha-remuneracao-desempenho.module';
import { CampanhaRemuneracaoExclusaoModule } from '../campanha-remuneracao-exclusao/campanha-remuneracao-exclusao.module';
import { CampanhaRemuneracaoAlterarModalComponent } from './campanha-remuneracao-alterar-modal/campanha-remuneracao-alterar-modal.component';
import { CampanhaRemuneracaoListaComponent } from './campanha-remuneracao-lista/campanha-remuneracao-lista.component';
import { CampanhaRemuneracaoNovoModalComponent } from './campanha-remuneracao-novo-modal/campanha-remuneracao-novo-modal.component';
import { CampanhaRemuneracaoService } from './campanha-remuneracao.service';

@NgModule({
    declarations: [CampanhaRemuneracaoListaComponent, CampanhaRemuneracaoAlterarModalComponent, CampanhaRemuneracaoNovoModalComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatInputModule,
        FlexLayoutModule,
        MatTooltipModule,
        MatToolbarModule,
        MatMenuModule,
        CurrencyMaskModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        CampanhaRemuneracaoDesempenhoModule,
        CampanhaRemuneracaoColocacaoModule,
        CampanhaRemuneracaoExclusaoModule,
        CampanhaFuncionarioMetaModule,
        CampanhaRemuneracaoCodigoBarrasModule
    ],
    providers: [
        CampanhaRemuneracaoService
    ],
    exports: [CampanhaRemuneracaoListaComponent, CampanhaRemuneracaoNovoModalComponent, CampanhaRemuneracaoAlterarModalComponent]
})
export class CampanhaRemuneracaoModule { }
