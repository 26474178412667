import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from './models/usuario';
import { UsuarioFiltro } from './models/usuario-filtro';
import { BENEFICIO_URI, BENEFICIO_ADDRESS } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { InserirUsuarioRequest } from './models/inserirUsuarioRequest';
import { ResponseData } from '../comum/models/responseData';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { AlterarUsuarioRequest } from './models/alterarUsuarioRequest';
import { UsuarioFiltroRequest } from './models/usuario-filtro-request';
import { UsuarioContratante } from './models/usuarioContratante';
import { VincularUsuarioRequest } from './models/vincularUsuarioRequest';
import { AlterarSenhaUsuarioRequest } from './models/alterarSenhaUsuarioRequest';
import { ResetarSenhaUsuarioRequest } from './models/resetarSenhaUsuarioRequest';
import { PesquisarUsuarioRequest } from './models/pesquisarUsuarioRequest';
import { UsuarioDetalhado } from './models/usuarioDetalhado';

@Injectable({ providedIn: 'root' })
export class UsuarioService {

  constructor(private http: HttpClient,
    private queryParamsService: QueryParamsService,
    @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
    @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

  public recuperarPorFiltro(filtro: UsuarioFiltroRequest) {
    return this.http.get<ResponseData<UsuarioContratante[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios`, { params: this.queryParamsService.gerarQuery(filtro)}).toPromise();
  }

  public pesquisarPorCpfEmail(pesquisarUsuarioRequest: PesquisarUsuarioRequest) {
    return this.http.get<ResponseData<Usuario[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/login/pesquisa`, { params: this.queryParamsService.gerarQuery(pesquisarUsuarioRequest)}).toPromise();
  }

  public recuperarPorLogin(emailCpf: string) {
    return this.http.get<ResponseData<Usuario>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/login/${emailCpf}`).toPromise();
  }
  
  public recuperarPorId(id: string) {
    return this.http.get<ResponseData<UsuarioContratante>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/${id}`).toPromise();
  }

  public incluir(usuario: InserirUsuarioRequest) {
    return this.http.post<ResponseData<UsuarioContratante>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios`, usuario).toPromise();
  }

  public vincular(usuario: VincularUsuarioRequest) {
    return this.http.post<ResponseData<UsuarioContratante>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/vincula`, usuario).toPromise();
  }

  public alterar(id: any, usuario: AlterarUsuarioRequest) {
    return this.http.put<ResponseData<UsuarioContratante>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/${id}`, usuario).toPromise();
  }

  public deletar(id: any) {
    return this.http.delete<any>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/${id}`).toPromise();
  }

  public listarAutoComplete(contem: string, empresaId: string) {
    return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/combobox`, { params: { conteudoPesquisa: contem, empresaId: empresaId } }).toPromise();
  }

  public alterarSenha(alterarSenha: AlterarSenhaUsuarioRequest) {
    return this.http.put<any>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/altera-senha`, alterarSenha).toPromise();
  }

  public resetarSenha(resetarSenha: ResetarSenhaUsuarioRequest) {
    return this.http.put<any>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/reseta-senha`, resetarSenha).toPromise();
  }

  public menusPermitidos() {
    return this.http.get<any>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/menus`, {}).toPromise();
  }

  public recuperarDadosPessoais() {
    return this.http.get<ResponseData<UsuarioDetalhado>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/dados-pessoais`).toPromise();
  }

  public alterarDadosPessoais(usuario: AlterarUsuarioRequest) {
    return this.http.put<ResponseData<UsuarioDetalhado>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/altera-dados-pessoais`, usuario).toPromise();
  }
}
