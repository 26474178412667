<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
  <div fxLayout="row" fxFlex fxLayoutAlign="space-between center">
    <div fxLayout="row" fxFlex fxLayoutAlign="end center" fxLayoutGap="10px">
      <button mat-raised-button color="primary" (click)="this.abrirFiltroExtrato()">
        <mat-icon>receipt</mat-icon> Extrato
      </button>
      <button mat-icon-button color="primary" (click)="this.abrirFiltro()">
        <mat-icon class="material-icons-round">search</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div fxLayout="column" fxLayoutAlign="space-evenly stretch" fxLayoutGap="20px" >
  <mat-nav-list>
    <mat-list-item *ngFor="let extratoBancario of this.extratosBancarios" [style.height]="itemSize + 'px'" (click)="this.abrirExtrato(extratoBancario)">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" fxFlex="95">
        <div fxLayout="row" fxLayoutGap="20px" fxLayout>
          <div fxLayout="column" fxFlexAlign="center">
            <mat-icon cass="md-36" *ngIf="extratoBancario.transferenciaId != null">currency_exchange</mat-icon>
            <mat-icon cass="md-36" *ngIf="extratoBancario.pagamentoContaId != null">barcode_scanner</mat-icon>
            <mat-icon cass="md-36" *ngIf="extratoBancario.cobrancaId != null">savings</mat-icon>
            <mat-icon cass="md-36" *ngIf="extratoBancario.tipoTransacao.value == 'INTERNAL_TRANSFER_CREDIT'">savings
            </mat-icon>
          </div>
          <div fxLayout="column" fxFlex fxLayoutAlign="space-evenly strech">
            <br>
            <h2>{{extratoBancario.tipoTransacao.description}}</h2>
            <h3>{{extratoBancario.descricao}}</h3>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <h3
                [ngClass]="{'mat-green-color': extratoBancario.valor > 0, 'mat-red-color': extratoBancario.valor < 0 }">
                <Strong>{{extratoBancario.valor | currency: 'BRL'}}</Strong></h3>
              <h3><Strong>{{extratoBancario.dataTransacao | date: 'dd/MM '}}</Strong></h3>
            </div>
          </div>
        </div>
        <mat-divider fxFLex></mat-divider>
      </div>
    </mat-list-item>
  </mat-nav-list>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-busca-mais (click)="buscarMais()" [descricao]="'Buscar mais'">
    </app-busca-mais>
  </div>

  <div *ngIf="this.extratosBancarios.length == 0" fxLayout="row" fxLayoutAlign="center center"
    style="border-width: 3px;border-style: dashed;border-color: lightgray;border-radius: 13px;height: 100px;">
    <h2 style="margin: 0px; color:lightgray">Sem registros</h2>
  </div>
  <br>
</div>