<mat-card fxLayout="column" fxLayoutAlign="start strech">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon>password</mat-icon>
    <h3>Digite sua senha eletrônica</h3>
  </div>
  <form [formGroup]="this.senhaEletronicaFormGroup">
    <div fxLayout="column">

    
    <mat-form-field appearance="outline">
      <mat-label>Senha</mat-label>
      <input matInput formControlName="senhaEletronica" mask="000000" [type]="viewPassword ?  'text' : 'password'">
      <span matSuffix>
        <mat-icon matSuffix (click)="viewPassword = !viewPassword" color="primary">{{viewPassword ? 'visibility': 'visibility_off'}}
        </mat-icon>
      </span>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Repita sua senha</mat-label>
      <input matInput formControlName="senhaEletronicaConfirmacao" mask="000000" [type]="viewConfirmPassword ?  'text' : 'password'"> 
      <span matSuffix>
        <mat-icon matSuffix (click)="viewConfirmPassword = !viewConfirmPassword" color="primary">{{viewConfirmPassword ? 'visibility': 'visibility_off'}}
        </mat-icon>
      </span>
    </mat-form-field>
  </div>
  </form>
  <div fxLayout="column" fxFlex fxLayoutAlign="center center">
    <button mat-raised-button color="primary" [disabled]="this.senhaEletronicaFormGroup.invalid"
      (click)="this.criaContaDigital()">Criar conta digital <mat-icon>wallet</mat-icon>
    </button>
    <button mat-button (click)="this.fechar()">
      Cancelar</button>
  </div>
</mat-card>