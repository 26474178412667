import { Injectable, EventEmitter } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UsuarioModel } from "../dominio/modelos/UsuarioModel";
import { LoginModalComponent } from "../login/login-modal/login-modal.component";

@Injectable()
export class AuthenticationService {
    
    authenticatedEvent : EventEmitter<boolean>;
    dialogRef: MatDialogRef<LoginModalComponent, any>;
    isOpened: boolean;

    constructor(private router : Router,
        private dialog: MatDialog) {
        this.authenticatedEvent = new EventEmitter<boolean>();
    }

    authenticate(usuarioModel: UsuarioModel){
        localStorage.removeItem('usuarioMeusBeneficios')
        localStorage.setItem('usuarioMeusBeneficios', JSON.stringify(usuarioModel));
    }

    deauthenticate(){
        let tokenObject = this.getSession();
        if(tokenObject)
        {
            tokenObject =  new UsuarioModel(tokenObject.id, tokenObject.email, tokenObject.nome, tokenObject.sobrenome, null, null, null, tokenObject.cpf, null, null, null, null, null, null, null, null, null, null, null, null, tokenObject.menuFixado);
            localStorage.removeItem('usuarioMeusBeneficios')
            localStorage.setItem('usuarioMeusBeneficios', JSON.stringify(tokenObject));
        }
        this.authenticatedEvent.emit(false);
        this.router.navigate(['/login']);
    }

    getSession() : UsuarioModel{
        return JSON.parse(localStorage.getItem('usuarioMeusBeneficios'));
    }

    authenticated() : boolean {
        let tokenObject = this.getSession();
        if(tokenObject == null || tokenObject == undefined)
            return false;
        let date = new Date();
        let dateExpiration = new Date(tokenObject.expiracao);

        return tokenObject.token != null && 
        tokenObject.token != undefined && 
        dateExpiration > date;        
    }
    
    expired(){
        let date = new Date();
        let dateExpiration = new Date(this.getSession().expiracao);
        return  dateExpiration < date;
    }

    abrirLogin() {

        if(this.isOpened) {
            return;
        }

        this.isOpened = true;

        this.dialogRef = this.dialog.open(LoginModalComponent, {
            maxWidth: '95vw',
            hasBackdrop: false,
            disableClose: true
        });

        this.dialogRef.afterOpened().subscribe(result =>{
            this.isOpened = true;
        });

        this.dialogRef.afterClosed().subscribe(result =>{
            this.isOpened = false;
        })
    }
}

