<h4 mat-dialog-title>Alterar remuneração da campanha</h4>
<mat-dialog-content>
    <form [formGroup]="this.campanhaFuncionarioMetaForm">
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Tipo Meta</mat-label>
                <mat-select placeholder="Selecione o tipo da meta" #tipoMeta formControlName="tipoMeta" id="tipoMeta">
                    <mat-option *ngFor="let tipoMetaConst of tiposMetas" [value]="tipoMetaConst.value">
                        {{ tipoMetaConst.descricao }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="this.campanhaFuncionarioMetaForm.controls.tipoMeta.hasError('required')">O tipo meta é
                    obrigatório</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Meta {{this.campanhaFuncionarioMetaForm.value.tipoMeta == 1 ? 'R$ ' : 'QTD.'}}</mat-label>
                <input matInput class="right" placeholder="Ex: 0,00" currencyMask
                    [options]="{ prefix: (this.campanhaFuncionarioMetaForm.value.tipoMeta == 1 ? 'R$ ' : ''), suffix: '', thousands: '.', decimal: ',' }" formControlName="meta" #meta
                    id="inputMeta">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Meta mínima {{this.campanhaFuncionarioMetaForm.value.tipoMeta == 1 ? 'R$ ' : 'QTD.'}}</mat-label>
                <input matInput class="right" placeholder="Ex: 0,00" currencyMask
                    [options]="{ prefix: (this.campanhaFuncionarioMetaForm.value.tipoMeta == 1 ? 'R$ ' : ''), suffix: '', thousands: '.', decimal: ',' }" formControlName="metaMinima" #metaMinima
                    id="inputMetaMinima">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Funcionário</mat-label>
                <input matInput type="text" placeholder="Selecione um funcionário para a campanha..."
                    [matAutocomplete]="campanhaFuncionarioAutocomplete" id="inputFuncionario" formControlName="campanhaFuncionario"
                    #campanhaFuncionario>
                <mat-autocomplete #campanhaFuncionarioAutocomplete [displayWith]="this.displayFn">
                    <mat-option *ngFor="let campanhaFuncionario of this.campanhasFuncionarios" [value]="campanhaFuncionario">
                        {{campanhaFuncionario.descricao}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Remuneração</mat-label>
                <input matInput type="text" placeholder="Selecione uma remuneração para a campanha..."
                    [matAutocomplete]="campanhaRemuneracaoAutocomplete" id="inputRemuneracao" formControlName="campanhaRemuneracao"
                    #campanhaRemuneracao>
                <mat-autocomplete #campanhaRemuneracaoAutocomplete [displayWith]="this.displayFn">
                    <mat-option *ngFor="let campanhaRemuneracao of this.remuneracoes" [value]="campanhaRemuneracao">
                        {{campanhaRemuneracao.descricao}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="1rem">
    <button mat-button color="warn" [mat-dialog-close]="false" id="buttonCancelar">
        <span>Cancelar</span>
    </button>
    <button mat-button color="primary" [disabled]="this.campanhaFuncionarioMetaForm.invalid || (this.campanhaFuncionarioMetaAgrupada.statusCampanha.value != 'Rascunho' && this.campanhaFuncionarioMetaAgrupada.statusCampanha.value != 'Interrompida')" (click)="this.salvar()"
        id="buttonSalvar">
        <span>Salvar</span>
    </button>
</mat-dialog-actions>