import { Component, OnInit, Input, Output, ViewEncapsulation, OnChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioContaBancariaNovoModalComponent } from '../usuario-conta-bancaria-novo-modal/usuario-conta-bancaria-novo-modal.component';
import { EventEmitter } from '@angular/core';
import { UsuarioContaBancaria } from '../models/usuarioContaBancaria';
import { Usuario } from 'src/app/usuario/models/usuario';
import { MessageDialogService } from 'src/app/message-dialog/message-dialog.service';
import { DialogActionYesAndNo, DialogResponseYes } from 'src/app/message-dialog/message-dialog-action';
import { UsuarioContaBancariaService } from '../usuario-conta-bancaria.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContaBancaria } from '../models/contaBancaria';

@Component({
    selector: 'app-usuario-conta-bancaria-lista',
    templateUrl: './usuario-conta-bancaria-lista.component.html',
    styleUrls: ['./usuario-conta-bancaria-lista.component.scss'],
    inputs: ['contasBancarias', 'usuario'],
    outputs: ['adicionarContaBancaria', 'verificarStatusContaBancaria'],
    encapsulation: ViewEncapsulation.None
})
export class UsuarioContaBancariaListaComponent implements OnInit, OnChanges {

    @Input('contasBancarias')
    public contasBancarias: ContaBancaria[];

    @Input('usuario')
    public usuario: Usuario;

    @Output('adicionarContaBancaria')
    public adicionarContaBancaria = new EventEmitter();

    @Output('verificarStatusContaBancaria')
    public verificarStatusContaBancaria = new EventEmitter();

    isMobile: MediaQueryList;
    contasBancariasDataSource: MatTableDataSource<any>;
    colunas: string[] = ['banco', 'agencia', 'conta', 'tipoContaBancaria', 'statusContaBancaria', 'acoes'];

    constructor(private dialog: MatDialog,
        private dialogService: MessageDialogService,
        private usuarioContaBancariaService: UsuarioContaBancariaService,
        private snackBar: MatSnackBar) {
    }

    ngOnChanges() {
        this.criarUsuariosContasBancariasDataSouce();
    }

    ngOnInit() {
        this.criarUsuariosContasBancariasDataSouce();
    }

    criarUsuariosContasBancariasDataSouce() {
        this.contasBancariasDataSource = new MatTableDataSource<any>(this.contasBancarias);
    }

    adicionarUsuarioContaBancaria() {
        const dialogRef = this.dialog.open(UsuarioContaBancariaNovoModalComponent,
            {
                width: '700px',
                disableClose: true,
                data: this.usuario
            });

        dialogRef.afterClosed().subscribe((usuarioContaBancariaResult: UsuarioContaBancaria) => {
            this.adicionarContaBancaria.emit(usuarioContaBancariaResult);
        });
    }

    verificarStatusConta(contaBancaria: ContaBancaria) {
        this.verificarStatusContaBancaria.emit(contaBancaria);
    }
}
