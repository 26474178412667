import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { CampanhaFuncionarioMeta } from './models/campanhaFuncionarioMeta';
import { InserirCampanhaFuncionarioMetaRequest } from './models/inserirCampanhaFuncionarioMetaRequest';
import { AlterarCampanhaFuncionarioMetaRequest } from './models/alterarCampanhaFuncionarioMetaRequest';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { CampanhaFuncionarioMetaAgrupada } from './models/campanhaFuncionarioMetaAgrupada';
import { CampanhaFuncionarioMetaTotalizacao } from './models/campanhaFuncionarioMetaTotalizacao';

@Injectable({
    providedIn: 'root'
})
export class CampanhaFuncionarioMetaService {

    constructor(private readonly http: HttpClient,
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    recuperarPorFuncionario(idCampanha: string, campanhaFuncionarioId: string) {
        return this.http.get<ResponseData<CampanhaFuncionarioMetaTotalizacao[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/campanhas-funcionarios/${campanhaFuncionarioId}/metas-relacionadas`);
    }

    async recuperarPorCampanhaRemuneracao(idCampanha: string, idCampanhaRemuneracoa: string) {
        return await this.http.get<ResponseData<CampanhaFuncionarioMeta[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracoa}/metas`).toPromise();
    }

    async recuperarPorCampanhaFuncionarioMeta(idCampanha: string, idCampanhaFuncionario: string) {
        return await this.http.get<ResponseData<CampanhaFuncionarioMeta[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/funcionarios/${idCampanhaFuncionario}/metas`).toPromise();
    }

    async recuperar(idCampanha: string, idCampanhaFuncionario: string, idCampanhaFuncionarioMeta: string) {
        return await this.http.get<ResponseData<CampanhaFuncionarioMeta[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/funcionarios/${idCampanhaFuncionario}/metas/${idCampanhaFuncionarioMeta}`).toPromise();
    }

    incluir(idCampanha: string, idCampanhaFuncionario: string, inserirCampanhaFuncionarioMetaRequest: InserirCampanhaFuncionarioMetaRequest) {
        return this.http.post<ResponseData<CampanhaFuncionarioMeta>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/funcionarios/${idCampanhaFuncionario}/metas`, inserirCampanhaFuncionarioMetaRequest);
    }

    excluir(idCampanha: string, idCampanhaFuncionario: string, idCampanhaFuncionarioMeta: string) {
        return this.http.delete(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/funcionarios/${idCampanhaFuncionario}/metas/${idCampanhaFuncionarioMeta}`);
    }

    alterar(idCampanha: string, idCampanhaFuncionario: string, idCampanhaFuncionarioMeta: string, alterarCampanhaFuncionarioMetaRequest: AlterarCampanhaFuncionarioMetaRequest) {
        return this.http.put<ResponseData<CampanhaFuncionarioMeta>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/funcionarios/${idCampanhaFuncionario}/metas/${idCampanhaFuncionarioMeta}`, alterarCampanhaFuncionarioMetaRequest);
    }
}
