import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Chart } from 'angular-highcharts';
import { DrawerMenuItemAtual } from '../comum/componentes/drawer-menu/drawer-menu-item-atual';
import { TipoTermoAceiteEnum } from '../comum/enuns/tipoTermoAceite.enum';
import { UsuarioModel } from '../dominio/modelos/UsuarioModel';
import { AuthenticationService } from '../seguranca/authentication-service';
import { RouterService } from '../seguranca/router-service';
import { TermoAceiteDataModel } from '../termo-aceite/models/termoAceiteDataModel';
import { TermoAceiteAnexoModalComponent } from '../termo-aceite/termo-aceite-anexo-modal/termo-aceite-anexo-modal.component';
import { TermoAceiteService } from '../termo-aceite/termo-aceite.service';
import { SaldoCarteiraResponse } from '../usuario-carteira-digital/models/saldoCarteiraResponse';
import { UsuarioCarteiraDigitalService } from '../usuario-carteira-digital/usuario-carteira-digital.service';
import { ContaDigital } from '../usuario-conta-digital/models/contaDigital';
import { UsuarioContaDigitalSenhaComponent } from '../usuario-conta-digital/usuario-conta-digital-senha/usuario-conta-digital-senha.component';
import { UsuarioContaDigitalService } from '../usuario-conta-digital/usuario-conta-digital.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  saldoCarteiraResponse: SaldoCarteiraResponse;
  isAuthenticated: boolean;
  usuarioModel: UsuarioModel;
  isMobile: MediaQueryList;
  mobileListener: () => void;
  carregandoSaldo: boolean = false;
  contaDigitalInexistente: boolean = false;
  public contaDigital: ContaDigital;

  constructor(private usuarioCarteiraDigitalService: UsuarioCarteiraDigitalService,
    private routerService: RouterService,
    public authenticationService: AuthenticationService,
    public changeDetector: ChangeDetectorRef,
    public matcher: MediaMatcher,
    public usuarioContaDigitalService: UsuarioContaDigitalService,
    private bottomSheet: MatBottomSheet,
    public termoAceiteService: TermoAceiteService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) {
    this.isMobile = matcher.matchMedia('(max-width: 800px)');
    this.mobileListener = () => changeDetector.detectChanges();
    this.isMobile.addEventListener('change', this.mobileListener);
    // this.isMobile.onchange = (ev: MediaQueryListEvent) => {
    //     if (!ev.matches && this.menuFixado == true) {
    //         //this.sidenav.open();
    //     }
    //     else {
    //         //this.sidenav.close();
    //     }
    // };
  }

  ngOnInit() {
    setTimeout(() => { this.routerService.alterarRota(new DrawerMenuItemAtual(`Meus benefícios`, null, "", false, true, null)) }, 0);

    this.recarregarContaDigital();

    this.usuarioModel = this.authenticationService.getSession();
  }

  atualizarSaldo(forcarBusca: true) {
    this.carregandoSaldo = true;
    this.saldoCarteiraResponse = null;
    this.usuarioCarteiraDigitalService.recuperarSaldo(forcarBusca).then(response => {
      this.saldoCarteiraResponse = response.data;
      this.carregandoSaldo = false;
    }, error => {
      this.carregandoSaldo = false;
    });
  }


  criarContaDigital($event) {
    var usuarioModel = this.authenticationService.getSession();

    this.termoAceiteService.recuperarPendentePorTipo(TipoTermoAceiteEnum.AberturaContaPagamento).then(response => {

      var termoAceiteDataModel = new TermoAceiteDataModel(usuarioModel.token, null);
      if (response.data != null) {
        termoAceiteDataModel.termoAceiteId = response.data.id;
      }

      const ref = this.dialog.open(TermoAceiteAnexoModalComponent, {
        width: '500px',
        disableClose: true,
        data: termoAceiteDataModel
      });

      ref.afterClosed().subscribe(async value => {
        if (value) {
          this.criaContaDigital();
        } else {
          this.snackBar.open(`Termos não foram aceitos!`,
            `Ok`, { duration: 3000 });
        }
      });
    });
  }

  criaContaDigital() {
    this.usuarioContaDigitalService.criaContaDigital().subscribe(response => {
      this.recarregarContaDigital();
    });
  }

  recarregarContaDigital() {
        this.usuarioCarteiraDigitalService.recuperarSaldo(false).then(response => {
          this.contaDigitalInexistente = !response.success;
          this.saldoCarteiraResponse = response.data;
          if(this.usuarioModel.modalidadeRetirada == 1)
          {
            setTimeout(() => { this.routerService.desabilitarRota(false) }, 0);
          }
          else{
            setTimeout(() => { this.routerService.desabilitarRota(true) }, 0);
          }
        }, err => {
          this.contaDigitalInexistente = true;
          setTimeout(() => { this.routerService.desabilitarRota(true) }, 0);
        });
  }
}

