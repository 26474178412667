import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Categoria } from './models/categoria';
import { CategoriaFiltro } from './models/categoria-filtro';
import { BENEFICIO_URI, BENEFICIO_ADDRESS } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { InserirCategoriaRequest } from './models/inserirCategoriaRequest';
import { ResponseData } from '../comum/models/responseData';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { AlterarCategoriaRequest } from './models/alterarCategoriaRequest';
import { CategoriaFiltroRequest } from './models/categoria-filtro-request';

@Injectable({ providedIn: 'root'})
export class CategoriaService {

  constructor(private http: HttpClient, 
    private queryParamsService: QueryParamsService,
    @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
    @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

  public recuperarPorFiltro(filtro: CategoriaFiltroRequest) {
    return this.http.get<ResponseData<Categoria[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/categorias`, {params: this.queryParamsService.gerarQuery(filtro) }).toPromise();
  }

  public recuperarPorId(id: string) {
    return this.http.get<Categoria>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/categorias/${id}`).toPromise();
  }

  public incluir(categoria: InserirCategoriaRequest) {
    return this.http.post<ResponseData<Categoria>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/categorias`, categoria).toPromise();
  }

  public alterar(id: any, categoria: AlterarCategoriaRequest) {
    return this.http.put<ResponseData<Categoria>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/categorias/${id}`, categoria).toPromise();
  }

  public deletar(id: any) {
    return this.http.delete<any>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/categorias/${id}`).toPromise();
  }

  public listarAutoComplete(contem: string, empresaId: string) {
    return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/categorias/combobox`, {params: {conteudoPesquisa : contem  , empresaId: empresaId}}).toPromise();
  }
}
