import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  constructor() { }

  salvarItem(nome: string, dados: any) {
    try {
      localStorage.setItem(nome, JSON.stringify(dados));
    } catch (error) {
    }
  }

  removerItem(nome: string) {
    try {
      localStorage.removeItem(nome);
    } catch (error) {
    }
  }

  recuperarItem<T>(nome: string): T {
    try {
      return JSON.parse(localStorage.getItem(nome));
    } catch (error) {
    }
  }
}
