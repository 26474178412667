<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
  <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon>filter_list</mat-icon>
    <span class="p6n-action-bar-title">Filtros</span>
  </div>
</mat-toolbar>
<form [formGroup]="this.filtroCampanha" fxLayout="column" (ngSubmit)="this.salvar()">
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Nome</mat-label>
      <input matInput type="text" #nome formControlName="nome">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Status campanha</mat-label>
      <mat-select placeholder="Selecione o status da campanha" #statusCampanha formControlName="statusCampanha"
        id="statusCampanha">
        <mat-option [value]="0">
          Todos
        </mat-option>
        <mat-option *ngFor="let statusCampanhaConst of statusCampanhas" [value]="statusCampanhaConst.value">
          {{ statusCampanhaConst.descricao }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <button mat-button (click)="this.cancelar()">
      Cancelar</button>
    <button mat-button color="primary" [disabled]="this.filtroCampanha.invalid" type="submit">
      Aplicar filtro</button>
  </div>
</form>