import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { CampanhaRemuneracaoColocacao } from './models/campanhaRemuneracaoColocacao'
import { InserirCampanhaRemuneracaoColocacaoRequest } from './models/inserirCampanhaRemuneracaoColocacaoRequest';
import { AlterarCampanhaRemuneracaoColocacaoRequest } from './models/alterarCampanhaRemuneracaoColocacaoRequest';

@Injectable({
  providedIn: 'root'
})
export class CampanhaRemuneracaoColocacaoService {

    constructor(private readonly http: HttpClient, 
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    async recuperarPorCampanhaRemuneracao(idCampanha: string, idCampanhaRemuneracao: string) {
        return await this.http.get<ResponseData<CampanhaRemuneracaoColocacao[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/colocacoes`).toPromise();
    }
    
    incluir(idCampanha: string, idCampanhaRemuneracao: string, inserirCampanhaRemuneracaoColocacaoRequest: InserirCampanhaRemuneracaoColocacaoRequest) {
        return this.http.post<ResponseData<CampanhaRemuneracaoColocacao>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/colocacoes`, inserirCampanhaRemuneracaoColocacaoRequest);
    }

    alterar(idCampanha: string, idCampanhaRemuneracao: string, idCampanhaRemuneracaoColocacao: string, alterarCampanhaRemuneracaoColocacaoRequest: AlterarCampanhaRemuneracaoColocacaoRequest) {
        return this.http.put<ResponseData<CampanhaRemuneracaoColocacao>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/colocacoes/${idCampanhaRemuneracaoColocacao}`, alterarCampanhaRemuneracaoColocacaoRequest);
    }

    excluir(idCampanha: string, idCampanhaRemuneracao: string, idCampanhaRemuneracaoColocacao: string) {
        return this.http.delete(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/remuneracoes/${idCampanhaRemuneracao}/colocacoes/${idCampanhaRemuneracaoColocacao}`);
    }

}
