import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { Fabricante } from './models/fabricante';
import { ResponseData } from '../comum/models/responseData';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { InserirFabricanteRequest } from './models/inserirFabricanteRequest';
import { AlterarFabricanteRequest } from './models/alterarFabricanteRequest';
import { FabricanteFiltroRequest } from './models/fabricante-filtro-request';

@Injectable({
  providedIn: 'root'
})
export class FabricanteService {
    constructor(private readonly http: HttpClient, 
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    recuperarPorId(id: string) {
        return this.http.get<ResponseData<Fabricante>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/fabricantes/${id}`);
    }

    recuperarPorFiltro(filtro: FabricanteFiltroRequest): any {
        return this.http.get<ResponseData<Fabricante[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/fabricantes`, {params: this.queryParamsService.gerarQuery(filtro) }).toPromise();
    }

    incluir(fabricante: InserirFabricanteRequest) {
        return this.http.post<ResponseData<Fabricante>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/fabricantes`, fabricante);
    }

    alterar(idFabricante: string, fabricante: AlterarFabricanteRequest) {
        return this.http.put<ResponseData<Fabricante>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/fabricantes/${idFabricante}`, fabricante);
    }

    excluir(idFabricante: string) {
        return this.http.delete<any[]>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/fabricantes/${idFabricante}`);
    }

    listarAutoComplete(contem: string, empresaId: string) {
        return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/fabricantes/combobox`, { params:{ conteudoPesquisa: contem, empresaId: empresaId}});
    }
}
