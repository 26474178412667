import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UsuarioService } from 'src/app/usuario/usuario.service';
import { UsuarioContaDigitalService } from '../usuario-conta-digital.service';

@Injectable()
export class UsuarioContaDigitalDetalheResolver {

  constructor(private usuarioContaDigitalService: UsuarioContaDigitalService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.usuarioContaDigitalService.recuperarMinhaContaDigital().then(registro => {
      return registro;
    });
  }
}
