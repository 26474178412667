<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
  <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon>filter_list</mat-icon>
    <span class="p6n-action-bar-title">Filtros</span>
  </div>
</mat-toolbar>
<form [formGroup]="this.filtroProduto" fxLayout="column" (ngSubmit)="this.salvar()">
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Nome</mat-label>
      <input matInput type="text" #nome formControlName="nome">
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Código</mat-label>
      <input matInput type="text" #codigo formControlName="codigo">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutGap="10px">

    
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Referência</mat-label>
      <input matInput type="text" #referencia formControlName="referencia">
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Categoria</mat-label>
      <input matInput type="text" placeholder="Selecione a categoria do produto..." [matAutocomplete]="categoriaAutocomplete"
        id="inputCategoria" formControlName="categoria" #categoria>
      <mat-autocomplete #categoriaAutocomplete [displayWith]="this.displayFn">
        <mat-option *ngFor="let categoria of this.categorias" [value]="categoria">{{categoria.descricao}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">

    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Grupo</mat-label>
      <input matInput type="text" placeholder="Selecione o grupo do produto..." [matAutocomplete]="grupoAutocomplete"
        id="inputGrupo" formControlName="grupo" #grupo>
      <mat-autocomplete #grupoAutocomplete [displayWith]="this.displayFn">
        <mat-option *ngFor="let grupo of this.grupos" [value]="grupo">{{grupo.descricao}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Sub Grupo</mat-label>
      <input matInput type="text" placeholder="Selecione o sub grupo do produto..."
        [matAutocomplete]="subGrupoAutocomplete" id="inputSubGrupo" formControlName="subGrupo" #subGrupo>
      <mat-autocomplete #subGrupoAutocomplete [displayWith]="this.displayFn">
        <mat-option *ngFor="let subGrupo of this.subGrupos" [value]="subGrupo">
          {{subGrupo.descricao}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Fabricante</mat-label>
      <input matInput type="text" formControlName="fabricante" #fabricante
        placeholder="Selecione o fabricante do produto..." [matAutocomplete]="fabricanteAutocomplete"
        id="inputFabricante">
      <mat-autocomplete #fabricanteAutocomplete [displayWith]="this.displayFn">
        <mat-option *ngFor="let fabricante of this.fabricantes" [value]="fabricante">
          {{fabricante.descricao}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Unidade de medida</mat-label>
      <input matInput type="text" placeholder="Selecione a unidade de medida do produto..."
        [matAutocomplete]="unidadeMedidaAutocomplete" id="inputUnidadeMedida" formControlName="unidadeMedida"
        #unidadeMedida>
      <mat-autocomplete #unidadeMedidaAutocomplete [displayWith]="this.displayFn">
        <mat-option *ngFor="let unidadeMedida of this.unidadesMedidas" [value]="unidadeMedida">
          {{unidadeMedida.descricao}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field appearance="outline">
      <mat-label>Ncm</mat-label>
      <input matInput type="number" class="right" placeholder="Ex: 10999221" step="1" formControlName="ncm" #ncm>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Empresa</mat-label>
      <mat-select placeholder="Selecione a empresa" #empresa formControlName="empresa" id="empresa">
        <mat-option *ngFor="let empresa of this.empresas" [value]="empresa.id">
          {{ empresa.descricao }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <button mat-button (click)="this.cancelar()">
      Cancelar</button>
    <button mat-button color="primary" [disabled]="this.filtroProduto.invalid" type="submit">
      Aplicar filtro</button>
  </div>
</form>