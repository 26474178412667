
export class AlterarProdutoRequest {
    constructor(
        public nome: string,
        public referencia: string,
        public categoriaId: string,
        public grupoId: string,
        public subGrupoId: string,
        public fabricanteId: string,
        public ncm: string,
        public unidadeMedidaId: string,
        public cest: string,

        public custo: number,
        public lucro: number,
        public venda: number,
        public estoqueAtual: number,
        public estoqueMaximo: number,
        public estoqueMinimo: number,
        public cfop: number,
        public ean: string,

        public percentualDesconto: number,
        public valorDesconto: number,
        public descontoMaximo: number)
    {

    }
}
