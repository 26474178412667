<h4 mat-dialog-title>Nova conta bancária</h4>
<mat-dialog-content>
    <form [formGroup]="this.usuarioContaBancariaForm">
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Titularidade</mat-label>
                <mat-select placeholder="Selecione..." #titularidade formControlName="contaTerceiros" id="contaTerceiros">
                    <mat-option [value]="false">
                        Mesma titularidade
                    </mat-option>
                    <mat-option [value]="true">
                        Terceiros
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Tipo conta</mat-label>
                <mat-select placeholder="Selecione..." #tipoContaBancaria formControlName="tipoContaBancaria" id="tipoContaBancaria">
                    <mat-option *ngFor="let tipoContaBancaria of this.tiposContasBancarias" [value]="tipoContaBancaria.value">
                        {{ tipoContaBancaria.descricao }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">

            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Banco</mat-label>
                <input matInput type="text" placeholder="Selecione..." [matAutocomplete]="bancoAutocomplete"
                    formControlName="banco" #banco>
                <mat-autocomplete #bancoAutocomplete [displayWith]="this.displayBanco">
                    <mat-option *ngFor="let banco of this.bancos" [value]="banco">{{banco.value}} - {{banco.nome}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Agência</mat-label>
                <input matInput type="text" class="right" placeholder="Digite a agência..." formControlName="agencia"
                    #agencia>
                <mat-error *ngIf="this.usuarioContaBancariaForm.controls.agencia.hasError('required')">A agência é obrigatória
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Agência dígito</mat-label>
                <input matInput type="text" class="right" placeholder="Dígito da agencia"
                    formControlName="agenciaDigito" #agenciaDigito>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Conta</mat-label>
                <input matInput type="text" class="right" placeholder="Digite a conta..." formControlName="conta"
                    #conta>
                <mat-error *ngIf="this.usuarioContaBancariaForm.controls.conta.hasError('required')">A conta é obrigatória
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Conta dígito</mat-label>
                <input matInput type="text" class="right" placeholder="Dígito da conta"
                    formControlName="contaDigito" #contaDigito>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutGap="10px" *ngIf="this.usuarioContaBancariaForm.value.contaTerceiros == true">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Nome Titular</mat-label>
                <input matInput type="text" placeholder="Insira o nome do usuário..." formControlName="nomeTitular" #nomeTitular>
                <mat-hint fxFlexAlign="end">{{nomeTitular.value.length}} / 200</mat-hint>
                <mat-error *ngIf="this.usuarioContaBancariaForm.controls.nomeTitular.hasError('required')">O nome titular é obrigatório
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Cpf Titular</mat-label>
                <input matInput type="text" class="right" placeholder="Ex: 999.999.999-99" formControlName="cpfCnpj" #cpfCnpj mask="000.000.000-00">
                <mat-hint fxFlexAlign="end">{{cpfCnpj.value.length}} / (11)</mat-hint>
                <mat-error *ngIf="this.usuarioContaBancariaForm.controls.cpfCnpj.hasError('required')">O cpf titular é obrigatório
                </mat-error>
              </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="1rem">
    <button mat-button color="warn" [mat-dialog-close]="false" id="buttonCancelar">
        <span>Cancelar</span>
    </button>
    <button mat-button color="primary" [disabled]="this.usuarioContaBancariaForm.invalid" (click)="this.salvar()"
        id="buttonSalvar">
        <span>Salvar</span>
    </button>
</mat-dialog-actions>