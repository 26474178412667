import { LoginRouteGuard } from './login-route-guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { GeneralRouteGuard } from './general-route-guard';
import { RequestInterceptor } from './request-interceptor';
import { AuthenticationService } from './authentication-service';
import { LoginModule } from '../login/login.module';
@NgModule({
    imports: [
        HttpClientModule,
        LoginModule
    ],
    providers: [
        GeneralRouteGuard,
        LoginRouteGuard,
        AuthenticationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        }
    ]
})
export class SegurancaModule { }
