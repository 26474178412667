import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Grupo } from './models/grupo';
import { GrupoFiltro } from './models/grupo-filtro';
import { BENEFICIO_URI, BENEFICIO_ADDRESS } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { InserirGrupoRequest } from './models/inserirGrupoRequest';
import { ResponseData } from '../comum/models/responseData';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { AlterarGrupoRequest } from './models/alterarGrupoRequest';
import { GrupoFiltroRequest } from './models/grupo-filtro-request';

@Injectable({ providedIn: 'root'})
export class GrupoService {

  constructor(private http: HttpClient, 
    private queryParamsService: QueryParamsService,
    @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
    @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

  public recuperarPorFiltro(filtro: GrupoFiltroRequest) {
    return this.http.get<ResponseData<Grupo[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/grupos`, {params: this.queryParamsService.gerarQuery(filtro) }).toPromise();
  }

  public recuperarPorId(id: string) {
    return this.http.get<Grupo>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/grupos/${id}`).toPromise();
  }

  public incluir(grupo: InserirGrupoRequest) {
    return this.http.post<ResponseData<Grupo>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/grupos`, grupo).toPromise();
  }

  public alterar(id: any, grupo: AlterarGrupoRequest) {
    return this.http.put<ResponseData<Grupo>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/grupos/${id}`, grupo).toPromise();
  }

  public deletar(id: any) {
    return this.http.delete<any>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/grupos/${id}`).toPromise();
  }

  public listarAutoComplete(contem: string, empresaId: string) {
    return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/gerencial/grupos/combobox`, {params: {conteudoPesquisa : contem  , empresaId: empresaId}}).toPromise();
  }
}
