<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
  <div fxLayout="row" fxFlex fxLayoutAlign="space-between center">
    <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">

    </div>
    <div fxLayout="row" fxLayoutAlign="end start">
      <button matTooltip="Criar conta" mat-button color="primary" (click)="this.verificarStatus()" fxFlexAlign="end"
        *ngIf="this.contaDigital != null">
        <mat-icon>refresh</mat-icon> Verificar status
      </button>
    </div>
  </div>
</mat-toolbar>
<div fxLayout="column" fxLayoutAlign="start strech" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="center start" *ngIf="this.usuarioModel.modalidadeRetirada == 1">
    <mat-card fxFlex="95">
      <div fxLayout="column" *ngIf="this.contaDigital == null">
        <div fxLayout="column" fxLayoutAlign="start center">
          <div fxLayout="row" fxFlexAlign="center">
            <img fxAlign="start" class="img-responsive" src="assets/cartoes-removebg.png">
          </div>
          <div fxLayout="row" fxLayoutAlign="center center">
            <h4>
              <p>Com a conta digital Monnera, você poderá receber seus pagamentos </p>
              <p>de desempenhos extraordinários das campanhas em que participa.</p>
            </h4>
          </div>
          <button matTooltip="Criar conta" mat-raised-button color="primary" (click)="this.criarContaDigital()">
            Criar conta digital Monnera
          </button>
        </div>
      </div>
      <mat-list *ngIf="this.contaDigital != null">
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              <strong>Status geral</strong>
            </span>
            <hr class="hr-dotted" fxFlex>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
              matTooltip="{{this.contaDigital.liberada ? 'Liberada': 'Não liberada'}}">
              {{this.contaDigital.statusConta.value != 'APPROVED' ? this.contaDigital.statusConta.description : ""}}
              <mat-icon *ngIf="this.contaDigital.statusConta.value == 'APPROVED'" class="mat-green-color">verified</mat-icon>
              <mat-icon *ngIf="this.contaDigital.statusConta.value != 'APPROVED'" class="mat-orange-color">warning</mat-icon>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              Informações pessoais:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <div>
                {{this.contaDigital.statusInformacoesComerciais.value != 'APPROVED' ? this.contaDigital.statusInformacoesComerciais.description : ""}}
                <mat-icon *ngIf="this.contaDigital.statusInformacoesComerciais.value == 'PENDING'"
                  class="mat-gray-color">pending</mat-icon>
                <mat-icon *ngIf="this.contaDigital.statusInformacoesComerciais.value == 'AWAITING_ACTION_AUTHORIZATION'"
                  class="mat-orange-color">hourglass_top</mat-icon>
                <mat-icon *ngIf="this.contaDigital.statusInformacoesComerciais.value == 'APPROVED'"
                  class="mat-green-color">verified</mat-icon>
                <mat-icon *ngIf="this.contaDigital.statusInformacoesComerciais.value == 'DENIED'"
                  class="mat-red-color">gpp_maybe</mat-icon>
              </div>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="this.contaDigital.statusInformacoesComerciais.value == 'DENIED'" class="mat-red-color">
            <strong>{{this.contaDigital.motivoRejeicaoInformacoesPessoais}}</strong>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              Informações bancárias:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <div>
                {{this.contaDigital.statusInformacoesBancarias.value != 'APPROVED' ? this.contaDigital.statusInformacoesBancarias.description : ""}}
                <mat-icon *ngIf="this.contaDigital.statusInformacoesBancarias.value == 'AWAITING_APPROVAL'"
                  class="mat-orange-color">hourglass_top</mat-icon>
                <mat-icon *ngIf="this.contaDigital.statusInformacoesBancarias.value == 'APPROVED'"
                  class="mat-green-color">verified</mat-icon>
                <mat-icon *ngIf="this.contaDigital.statusInformacoesBancarias.value == 'REJECTED'"
                  class="mat-red-color">gpp_maybe</mat-icon>
              </div>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="this.contaDigital.statusInformacoesBancarias.value == 'REJECTED'" class="mat-red-color">
          <strong>{{this.contaDigital.motivoRejeicaoInformacoesBancarias}}</strong>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              Status dos documentos:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <div>
                {{this.contaDigital.statusDocumento.value != 'APPROVED' ? this.contaDigital.statusDocumento.description : ""}}
                <mat-icon *ngIf="this.contaDigital.statusDocumento.value == 'AWAITING_APPROVAL'"
                  class="mat-orange-color">hourglass_top</mat-icon>
                <mat-icon *ngIf="this.contaDigital.statusDocumento.value == 'APPROVED'"
                  class="mat-green-color">verified</mat-icon>
                <mat-icon *ngIf="this.contaDigital.statusDocumento.value == 'IGNORED'"
                  class="mat-gray-color">do_not_disturb_on</mat-icon>
              </div>
              <button mat-raised-button color="primary"
                *ngIf="this.contaDigital.statusDocumento.value == 'NOT_SENT'"
                (click)="abrirModalDocumentos()">
                Enviar <mat-icon>pending</mat-icon>
              </button>
              <button mat-raised-button color="warn"
                *ngIf="this.contaDigital.statusDocumento.value == 'REJECTED'"
                (click)="abrirModalDocumentos()">
                Reenviar
                <mat-icon>gpp_maybe</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="this.contaDigital.statusDocumento.value == 'REJECTED'" class="mat-red-color">
          <strong color="mat-red-color">{{this.contaDigital.motivoRejeicaoDocumento}}</strong>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              E-mail:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div>
              {{this.contaDigital.email}}
            </div>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              CPF:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div>
              {{this.contaDigital.cpfCnpj | mask: '000.000.000-00'}}
            </div>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              Celular:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div>
              ({{this.contaDigital.dddCelular}}) {{this.contaDigital.celular | mask: '00000-0000'}}
            </div>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              Telefone:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div>
              ({{this.contaDigital.dddTelefone}}) {{this.contaDigital.telefone | mask: '00000-0000'}}
            </div>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              Banco:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div>
              461 - Asaas I.P S.A
            </div>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              Agência:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div>
              {{this.contaDigital.agencia}}
            </div>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              Conta bancária:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div>
              {{this.contaDigital.contaBancaria }}-{{this.contaDigital.contaBancariaDigito}}
            </div>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="row" fxFlex fxLayoutAlign="center center" fxLayoutGap="10px">
            <span>
              Senha eletrônica:
            </span>
            <hr class="hr-dotted" fxFlex>
            <div>
              <button mat-button color="accent" (click)="resetarSenhaEletronica()">
                Resetar
              </button>
              <button mat-raised-button color="primary" (click)="alterarSenhaEletronicaModal()">
                Alterar
              </button>
            </div>
          </div>
        </mat-list-item>
        <!-- <mat-list-item>
    <div fxLayout="row" fxFlex fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <span>
        Total do custo de serviço:
      </span>
      <hr class="hr-dotted" fxFlex>
      <div>
        {{this.contaDigital.totalCustoServico |currency: 'BRL': 'symbol' :'1.2-2'}}
      </div>
    </div>
  </mat-list-item>
  <mat-list-item>
    <div fxLayout="row" fxFlex fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <h3>
        Total da campanha:
      </h3>
      <hr class="hr-dotted" fxFlex>
      <div>
        <h3 style="color: green;">
          {{this.contaDigital.totalCustoCampanha |currency: 'BRL': 'symbol' :'1.2-2'}}
        </h3>
      </div>
    </div>
  </mat-list-item> -->
      </mat-list>
    </mat-card>
  </div>
  <div fxLayout="row" fxLayoutAlign="center start">
    <div fxLayout="row" fxFlex="95" *ngIf="this.usuarioModel.modalidadeRetirada == 1">
      <app-usuario-conta-bancaria-lista (verificarStatusContaBancaria)="this.verificarStatusContaBancaria($event)"
        (adicionarContaBancaria)="this.adicionarContaBancaria($event)" [contasBancarias]="this.contasBancarias"
        [usuario]="this.usuario" fxFlex>
      </app-usuario-conta-bancaria-lista>
    </div>
  </div>
</div>
<!-- 
    <form [formGroup]="this.usuarioContaDigitalForm">
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Nome</mat-label>
                <input matInput type="text" placeholder="Insira o nome do usuário..." formControlName="nome" #nome>
                <mat-hint fxFlexAlign="end">{{nome.value.length}} / 200</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm.controls.nome.hasError('required')">O nome é obrigatório
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>E-mail</mat-label>
                <input matInput type="email" placeholder="Insira o e-mail do usuário" formControlName="email"
                    id="inputEmail" />
                <mat-error *ngIf="this.usuarioContaDigitalForm.controls.email.hasError('required')">O email é obrigatório
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="20">
                <mat-label>Tipo Pessoa</mat-label>
                <mat-select placeholder="Selecione o tipo da pessoa" #tipoPessoa formControlName="tipoPessoa"
                    id="tipoPessoa">
                    <mat-option *ngFor="let tipoPessoaConst of tiposPessoas" [value]="tipoPessoaConst.value">
                        {{ tipoPessoaConst.descricao }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Cpf/Cnpj</mat-label>
                <input matInput type="text" placeholder="Insira o cpf/cnpj da empresa." formControlName="cpfCnpj"
                    [specialCharacters]="[ '.', '/', '-' ]"
                    mask="{{this.usuarioContaDigitalForm.value.tipoPessoa == 1 ? '000.000.000-00' :  '00.000.000/0000-00'}}" />
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Tipo Empresa</mat-label>
                <mat-select placeholder="Selecione o tipo da empresa" #tipoEmpresa formControlName="tipoEmpresa"
                    id="tipoEmpresa" [disabled]="this.usuarioContaDigitalForm.value.tipoPessoa != 2">
                    <mat-option *ngFor="let tipoEmpresaConst of tiposEmpresas" [value]="tipoEmpresaConst.value">
                        {{ tipoEmpresaConst.descricao }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Telefone</mat-label>
                <input matInput type="text" class="right" placeholder="Ex: (99) 9999-9999"
                    formControlName="telefone" #telefone mask="(00) 0000-0000" [showMaskTyped]="true">
                <mat-hint fxFlexAlign="end">{{telefone.value.length}} / 2</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm.controls.telefone.hasError('required')">O telefone é
                    obrigatório
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Celular</mat-label>
                <input matInput type="text" class="right" placeholder="Ex: (99) 99999-9999" formControlName="celular"
                    #celular mask="(00) 00000-0000" [showMaskTyped]="true">
                <mat-hint fxFlexAlign="end">{{celular.value.length}} / 2</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm.controls.celular.hasError('required')">O celular é
                    obrigatório
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex="20">
                <mat-label>Cep</mat-label>
                <input matInput type="text" placeholder="Insira o cep da empresa" #cep formControlName="cep"
                    mask="00.000-000">
                <mat-hint fxFlexAlign="end">{{cep.value.length}} / 8</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].cep.hasError('required')">O cep
                    é obrigatório</mat-error>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].cep.hasError('maxlength')">O
                    cep deve ter 8 digitos</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Logradouro</mat-label>
                <input matInput type="text" placeholder="Insira o logradouro da emresa" #logradouro
                    formControlName="logradouro">
                <mat-hint fxFlexAlign="end">{{logradouro.value.length}} / 200</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].logradouro.hasError('required')">O
                    logradouro é obrigatório</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="15">
                <mat-label>Número</mat-label>
                <input matInput type="text" placeholder="Insira o número da empresa" #numeroEndereco
                    formControlName="numeroEndereco">
                <mat-hint fxFlexAlign="end">{{numeroEndereco.value}} / 9999999</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].numeroEndereco.hasError('required')">O
                    número é obrigatório</mat-error>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].numeroEndereco.hasError('max')">O número
                    máximo é 9999999</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Complemento</mat-label>
                <input matInput type="text" placeholder="Insira o complemento do endereço" #complemento
                    formControlName="complemento">
                <mat-hint fxFlexAlign="end">{{complemento.value.length}} / 200</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].complemento.hasError('required')">O
                    complemento é obrigatório</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Bairro</mat-label>
                <input matInput type="text" placeholder="Insira o bairro do endereço" #bairro formControlName="bairro">
                <mat-hint fxFlexAlign="end">{{bairro.value.length}} / 200</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].bairro.hasError('required')">O
                    bairro é obrigatório</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Cidade</mat-label>
                <input matInput type="text" placeholder="Insira a cidade do endereço" #cidade formControlName="cidade">
                <mat-hint fxFlexAlign="end">{{cidade.value.length}} / 200</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].cidade.hasError('required')">A
                    cidade é obrigatório</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Código IBGE</mat-label>
                <input matInput type="text" placeholder="Insira o código ibge do endereço" #codigoIbge
                    formControlName="codigoIbge">
                <mat-hint fxFlexAlign="end">{{codigoIbge.value.length}} / 200</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].codigoIbge.hasError('required')">O
                    codigo ibge é obrigatório</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>UF</mat-label>
                <input matInput type="text" placeholder="Insira a uf do endereço" #uf formControlName="uf">
                <mat-hint fxFlexAlign="end">{{uf.value.length}} / 200</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].uf.hasError('required')">A
                    uf é obrigatória</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>País</mat-label>
                <input matInput type="text" placeholder="Insira o país do endereço" #pais formControlName="pais">
                <mat-hint fxFlexAlign="end">{{pais.value.length}} / 200</mat-hint>
                <mat-error *ngIf="this.usuarioContaDigitalForm['controls'].pais.hasError('required')">O
                    pais é obrigatório</mat-error>
            </mat-form-field>
        </div>
    </form> -->