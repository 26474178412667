export const TipoLogradouroConst =
    [
        //{ descricao: "Alameda", value: 1 },
        { descricao: "Avenida", value: 2 },
        //{ descricao: "Chácara", value: 3 },
        //{ descricao: "Colônia", value: 4 },
        //{ descricao: "Condomínio", value: 5 },
        //{ descricao: "Eqnp", value: 6 },
        //{ descricao: "Estância", value: 7 },
        { descricao: "Estrada", value: 8 },
        //{ descricao: "Fazenda", value: 9 },
        //{ descricao: "Praça", value: 9 },
        //{ descricao: "Prolongamento", value: 10 },
        { descricao: "Rodovia", value: 11 },
        { descricao: "Rua", value: 12 },
        //{ descricao: "Sítio", value: 13 },
        //{ descricao: "Travessa", value: 14 },
        //{ descricao: "Vicinal", value: 15 },
    ]
