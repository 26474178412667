import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { CampanhaFuncionario } from './models/campanhaFuncionario';
import { InserirCampanhaFuncionarioRequest } from './models/inserirCampanhaFuncionarioRequest';
import { AlterarCampanhaFuncionarioRequest } from './models/alterarCampanhaFuncionarioRequest';
import { ComboBoxItem } from '../comum/models/comboBoxItem';

@Injectable({
  providedIn: 'root'
})
export class CampanhaFuncionarioService {

    constructor(private readonly http: HttpClient, 
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    recuperarPorCampanha(idCampanha: string) {
        return this.http.get<ResponseData<CampanhaFuncionario[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/funcionarios`);
    }

    incluir(idCampanha: string, inserirCampanhaFuncionarioRequest: InserirCampanhaFuncionarioRequest) {
        return this.http.post<ResponseData<CampanhaFuncionario>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/funcionarios`, inserirCampanhaFuncionarioRequest);
    }

    alterar(idCampanha: string, idCampanhaFuncionario: string, alterarCampanhaFuncionarioRequest: AlterarCampanhaFuncionarioRequest) {
        return this.http.put<ResponseData<CampanhaFuncionario>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/funcionarios/${idCampanhaFuncionario}`, alterarCampanhaFuncionarioRequest);
    }

    excluir(idCampanha: string, idCampanhaFuncionario: string) {
        return this.http.delete(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/funcionarios/${idCampanhaFuncionario}`);
    }

    public listarAutoComplete(idCampanha: string, contem: string, empresaId: string) {
        return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/funcionarios/combobox`, {params: {conteudoPesquisa : contem, empresaId: empresaId}}).toPromise();
    }
}
