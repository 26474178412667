<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
  <div fxLayout="row" fxFlex fxLayoutAlign="space-between center">
    <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
      <span class="p6n-action-bar-title">Produtos</span>
    </div>
    <div fxLayout="row" fxFlex fxLayoutAlign="end center" fxLayoutGap="10px">
      <button mat-icon-button color="primary" (click)="this.abrirFiltro()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div fxLayout="row" fxLayoutAlign="center start">
  <mat-card fxFlex *ngIf="this.produtos.length > 0" fxLayout="column" class="list-container mat-elevation-z8"
    fxFlex="95">
    <mat-table #table [(dataSource)]="this.produtoDataSource" fxLayout="column">
      <ng-container matColumnDef="codigo">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex="100px" fxLayoutAlign="start center">Código
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let produto" fxFlex="100px" fxLayoutAlign="start center">{{produto.codigo}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="nome">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex fxLayoutAlign="center center">Produto
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let produto" fxFlex fxLayoutAlign="start center">{{produto.nome}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="categoria">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex fxLayoutAlign="center center">Categoria
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let produto" fxFlex fxLayoutAlign="center center">
          {{produto.categoria != null ? produto.categoria.nome : ''}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="grupo">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex="120px" fxLayoutAlign="center center">Grupo
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let produto" fxFlex="120px" fxLayoutAlign="center center">
          {{produto.grupo != null ? produto.grupo.nome : ''}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="subGrupo">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex="120px" fxLayoutAlign="center center">Sub grupo
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let produto" fxFlex="120px" fxLayoutAlign="center center">
          {{produto.subGrupo != null ? produto.subGrupo.nome : ''}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="unidade">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex="120px" fxLayoutAlign="center center">Unidade
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let produto" fxFlex="120px" fxLayoutAlign="center center">
          {{produto.unidadeMedida != null ? produto.unidadeMedida.nome : ''}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="fabricante">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex="120px"  fxLayoutAlign="center center">Fabricante
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let produto" fxFlex="120px"  fxLayoutAlign="center center">
          {{produto.fabricante != null ? produto.fabricante.nomeRazaoSocial : ''}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="empresa">
        <mat-header-cell *matHeaderCellDef mat-sort-header="empresa" fxFlex="120px" fxLayoutAlign="center center">
          Empresa </mat-header-cell>
        <mat-cell *matCellDef="let produto" matTooltip="Razão Social: {{produto.empresa.nomeRazaoSocial}}"
          fxFlex="120px" fxLayoutAlign="center center"> {{ produto.empresa.nomeFantasia}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="estoqueAtual">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex="100px" fxLayoutAlign="end center">Estoque
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let produto" fxFlex="100px" fxLayoutAlign="end center">{{produto.estoqueAtual}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="venda">
        <mat-header-cell mat-header-cell *matHeaderCellDef fxFlex="100px" fxLayoutAlign="end center">Venda
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let produto" fxFlex="100px" fxLayoutAlign="end center">
          {{produto.venda | currency: 'BRL'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="acoes">
        <mat-header-cell *matHeaderCellDef fxFlex="60px"></mat-header-cell>
        <mat-cell *matCellDef="let produto" fxFlex="60px">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item  (click)="this.alterar(produto)" matTooltip="Alterar"
              color="primary" id="buttonAlterar">
              <mat-icon>edit</mat-icon> Editar
            </button>
            <button mat-menu-item  (click)="this.deletar(produto)" matTooltip="Excluir" color="warn"
              id="buttonExcluir">
              <mat-icon>delete</mat-icon> Excluir
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="colunas"></mat-header-row>
      <mat-row *matRowDef="let row; columns: colunas;"></mat-row>
    </mat-table>
    <app-busca-mais (click)="buscarMais()" [descricao]="'Buscar mais'"></app-busca-mais>
  </mat-card>
  <div *ngIf="this.produtos.length == 0" fxLayout="row" fxLayoutAlign="center center" fxFlex
    style="border-width: 3px;border-style: dashed;border-color: lightgray;border-radius: 13px;height: 100px;">
    <h2 style="margin: 0px; color:lightgray">Sem registros</h2>
  </div>
  <br />
</div>
<div style="position: fixed; right: 16px; bottom: 16px;" fxLayout="column" fxLayoutAlign="end center">
  <button mat-fab color="accent" matTooltip="Novo" (click)="this.novo()">
    <mat-icon>add</mat-icon>
  </button>
</div>