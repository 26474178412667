import { Component, Inject, OnInit } from '@angular/core';
import { LoginService } from './../login.service';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AuthenticationService } from '../../seguranca/authentication-service';
import { Router } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import { UsuarioService } from 'src/app/usuario/usuario.service';
import { UsuarioModel } from 'src/app/dominio/modelos/UsuarioModel';
import { TermoAceiteDataModel } from 'src/app/termo-aceite/models/termoAceiteDataModel';
import { TermoAceiteAnexoModalComponent } from 'src/app/termo-aceite/termo-aceite-anexo-modal/termo-aceite-anexo-modal.component';
import { AutenticacaoTokenCodeRequest } from '../models/autenticacaoTokenCodeRequest';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class LoginModalComponent implements OnInit {

  public credenciais: UntypedFormGroup;
  public recuperaLoginForm: UntypedFormGroup;
  public autenticaTokenCodeForm: UntypedFormGroup;

  public exibirLogin: boolean = true;
  public exibirUnidades: boolean;
  public exibirRecuperarLogin: boolean;
  public exibirAutenticacaoTokenCode: boolean;

  public nomeUsuario: string;
  public usuarioModel: UsuarioModel;

  constructor(private loginService: LoginService,
    private fromBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private readonly ref: MatDialogRef<LoginModalComponent>,
    private readonly snackBar: MatSnackBar,
    private dialog: MatDialog,
    private usuarioService: UsuarioService) {
  }

  ngOnInit() {
    this.usuarioModel = this.authenticationService.getSession();

    this.credenciais = this.fromBuilder.group({
      emailCpf: [this.usuarioModel != null && this.usuarioModel.email != null ? this.usuarioModel.email : null, Validators.required],
      senha: [null, Validators.required]
    });

    this.recuperaLoginForm = this.fromBuilder.group({
      emailCpf: [this.usuarioModel != null && this.usuarioModel.email != null ? this.usuarioModel.email : null, Validators.required]
    });

    this.autenticaTokenCodeForm = this.fromBuilder.group({
      tokenAutorizacao: [null, Validators.required],
      tokenCode: [null, Validators.required]
    });
  }

  async login() {
    if (!this.credenciais.valid)
      return;

    this.loginService.login(this.credenciais.value).then(response => {
      this.nomeUsuario = response.data.nome;
      this.usuarioModel = response.data;
      if (response.data.duplaAutenticacao) {
        this.snackBar.open(`Um sms foi enviado para o telefone  ${response.data.telefone} com o código de autorização!`, `Ok`, { duration: 5000 });
        this.habilitarAutenticacaoTokenCode(response.data.token);
      }
      else {
        this.salvarLogin();
      }
    })
  }

  async salvarLogin() {
    if (this.usuarioModel.termoAceitePendente) {
      await this.abrirTermoAceite();
    }
    else {
      await this.finalizarLogin();
    }
  }

  async abrirTermoAceite() {

    const ref = this.dialog.open(TermoAceiteAnexoModalComponent, {
      width: '600px',
      disableClose: true,
      data: new TermoAceiteDataModel(this.usuarioModel.token, this.usuarioModel.termoAceitePendenteId)
    });

    ref.afterClosed().subscribe(async value => {
      if (value) {
        await this.finalizarLogin();
      } else {
        this.snackBar.open(`Termos não foram aceitos!`,
          `Ok`, { duration: 3000 });
      }
    });
  }

  async finalizarLogin() {
    this.authenticationService.authenticate(this.usuarioModel);
    var login = await this.loginService.recuperarLogin();
    this.authenticationService.authenticate(login.data);
    this.authenticationService.authenticatedEvent.emit(true);
    this.ref.close(true);
  }



  habilitarLogin(email: string) {
    this.exibirLogin = true;
    this.exibirRecuperarLogin = false;

    this.credenciais.get("emailCpf").setValue(email);
  }

  habilitarRecuperarLogin() {
    this.exibirLogin = false;
    this.exibirRecuperarLogin = true;
    this.recuperaLoginForm.get("emailCpf").setValue(this.credenciais.value.emailCpf);
  }

  async recuperarLogin() {
    if (!this.recuperaLoginForm.valid)
      return;

    var response = await this.usuarioService.resetarSenha(this.recuperaLoginForm.value);

    this.habilitarLogin(this.recuperaLoginForm.value.emailCpf);

    this.snackBar.open(`Um email foi enviado para ${this.recuperaLoginForm.value.emailCpf} com a nova senha!`,
      `Ok`, { duration: 5000 });
  }

  habilitarAutenticacaoTokenCode(tokenAutorizacao: string) {
    this.exibirLogin = false;
    this.exibirAutenticacaoTokenCode = true;
    this.exibirRecuperarLogin = false;

    this.autenticaTokenCodeForm.get("tokenAutorizacao").setValue(tokenAutorizacao);
  }

  onSubmit() {
    this.login();
  }

  autenticarTokenCode() {
    if (this.autenticaTokenCodeForm.invalid)
      return;

    let request = new AutenticacaoTokenCodeRequest(this.autenticaTokenCodeForm.value.tokenCode);

    this.loginService.autenticarTokenCode(this.autenticaTokenCodeForm.value.tokenAutorizacao, request).then(response => {
      if (response.data.duplaAutenticacaoVerificada) {
        this.finalizarLogin();
      }
      else {

      }
    })
  }

  reenviarTokenCode() {
    this.loginService.reenviarTokenCode(this.autenticaTokenCodeForm.value.tokenAutorizacao).then(response => {
      this.snackBar.open(`Um sms foi enviado para o telefone  ${response.data.telefone} com o novo código de autorização!`, `Ok`, { duration: 5000 });
    })
  }

}

