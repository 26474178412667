import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CampanhaService } from '../campanha.service';
import { CampanhaFiltro } from '../models/campanha-filtro';
import { LocalStorageService } from '../../comum/services/local-storage.service';
import { NOME_FILTRO_CAMPANHA } from '../../app.config';
import { MovimentoEnum } from '../../comum/enuns/movimento.enum';
import { CampanhaFiltroRequest } from '../models/campanha-filtro-request';
import { TipoOrdenacaoEnum } from 'src/app/comum/enuns/tipoOrdenacao.enum';

@Injectable()
export class CampanhaListaResolver implements Resolve<any> {
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let campanhaFiltro: CampanhaFiltro;
        campanhaFiltro = this.localStorageService.recuperarItem<CampanhaFiltro>(NOME_FILTRO_CAMPANHA);
        let campanhaFiltroRequest = new CampanhaFiltroRequest(campanhaFiltro ? campanhaFiltro.nome : undefined, 
                                      campanhaFiltro ? campanhaFiltro.statusCampanha : undefined,
                                      campanhaFiltro && campanhaFiltro.empresa ? campanhaFiltro.empresa : undefined,
                                      TipoOrdenacaoEnum.Decrescente, "FimCampanha", 0, 50);

        return this.campanhaService.recuperarPorFiltro(campanhaFiltroRequest).then(registros => {
            return registros;
        });
    }

    constructor(private campanhaService: CampanhaService,
                private localStorageService: LocalStorageService) {

    }
}

