import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators } from '@angular/forms';
import { CategoriaService } from '../categoria.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MovimentoConst } from 'src/app/comum/consts/movimento.const';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { InserirCategoriaRequest } from '../models/inserirCategoriaRequest';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-categoria-incluir-modal',
  templateUrl: './categoria-incluir-modal.component.html',
  styleUrls: ['./categoria-incluir-modal.component.scss']
})
export class CategoriaIncluirModalComponent implements OnInit {

  categoriaFormGroup: UntypedFormGroup;
  empresas: ComboBoxItem[];

  constructor(private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private categoriaService: CategoriaService,
    private empresaService: EmpresaService,
    public location: Location,
    private readonly ref: MatDialogRef<CategoriaIncluirModalComponent>) {
  }


  ngOnInit() {

    this.categoriaFormGroup = this.formBuilder.group({
      nome: [null, Validators.required],
      empresa: [null, Validators.required]
    });

    this.empresaService.listarVinculados()
      .then
      (response => {
        this.empresas = response.data;
        this.categoriaFormGroup.get("empresa").setValue(this.empresas.length == 1 ? this.empresas[0].id : null);
      });
  }

  async salvar() {
    if (!this.categoriaFormGroup.valid) {
      throw new Error('Dados inválidos!');
    }

    let inserirCategoriaRequest = new InserirCategoriaRequest(this.categoriaFormGroup.value.nome, this.categoriaFormGroup.value.empresa)

    await this.categoriaService.incluir(inserirCategoriaRequest).then(response => {
      this.snackBar.open(`Categoria salva com sucesso!`, `Ok`, { duration: 2000 });
      this.ref.close(response.data);
    });
  }

  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }
}
