import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { UsuarioEmpresa } from './models/usuarioEmpresa';
import { InserirUsuarioEmpresaRequest } from './models/inserirUsuarioEmpresaRequest';

@Injectable({
  providedIn: 'root'
})
export class UsuarioEmpresaService {

    constructor(private readonly http: HttpClient, 
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    recuperarPorUsuario(idUsuario: string) {
        return this.http.get<ResponseData<UsuarioEmpresa[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/${idUsuario}/empresas`);
    }

    incluir(idUsuario: string, inserirUsuarioEmpresaRequest: InserirUsuarioEmpresaRequest) {
        return this.http.post<ResponseData<UsuarioEmpresa>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/${idUsuario}/empresas`, inserirUsuarioEmpresaRequest);
    }

    excluir(idUsuario: string, idUsuarioEmpresa: string) {
        return this.http.delete(`${this.beneficioAddress}${this.beneficioUri}/administracao/usuarios/${idUsuario}/empresas/${idUsuarioEmpresa}`);
    }
}
