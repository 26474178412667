<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
  <div fxLayout="row" fxFlex fxLayoutAlign="space-between center">
    <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
    </div>
    <div fxLayout="row" fxFlex fxLayoutAlign="end center" fxLayoutGap="10px">
      <button mat-icon-button color="primary" (click)="this.abrirFiltro()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
  <div *ngFor="let campanha of this.campanhas" fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex class="list-container mat-elevation-z8" fxFlex="95"
      [ngClass]="{'campanha-termo-aceite-pendente':campanha.termoAceito == null, 'campanha-termo-aceite-recusado':campanha.termoAceito == false, 'campanha-termo-aceite-aceito':campanha.termoAceito == true}"
      (click)="this.abrirCampanha(campanha)">
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <h3 style="text-align: center;"><strong>{{this.campanha.nome}}</strong></h3>
        </div>
        <label matTooltip="CPF/CNPJ: {{campanha.empresaCpfCnpj}}"><strong>Rede:
          </strong>{{campanha.empresaRazaoSocial}}</label>
        <label matTooltip="CPF/CNPJ: {{campanha.empresaCpfCnpj}}"><strong>Cpf/Cnpj:
          </strong>{{campanha.empresaCpfCnpj | mask: '00.000.000/0000-00'}}</label>
        <label><strong>Período: </strong>{{campanha.inicioCampanha | date: 'dd/MM/yyyy'}} a {{campanha.fimCampanha |
          date: 'dd/MM/yyyy'}}</label>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="campanha.termoAceito == true">
          <mat-chip-list>
            <mat-chip color="{{this.getStatusColor(campanha)}}" selected> {{campanha.statusCampanha.description}}
              <mat-icon fxAlign="center">{{this.getStatusIcon(campanha)}}</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
        <mat-divider
          *ngIf="campanha.termoAceito == true && (this.campanha.saldoBloqueado > 0 || this.campanha.saldoDisponivel > 0 || this.campanha.saldoProcessandoRetirada > 0 || this.campanha.saldoRetirado > 0)"></mat-divider>
        <div fxLayout="row" fxLayoutAlign="center stretch" *ngIf="campanha.termoAceito == true">
          <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.campanha.saldoBloqueado > 0">
            <h4 style="color: red">Saldo bloqueado</h4>
            <h3 style="color: red"><strong>{{this.campanha.saldoBloqueado |currency: 'BRL': 'symbol' :
                '1.2-2'}}</strong></h3>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.campanha.saldoDisponivel > 0">
            <h4 style="color: green">Saldo disponivel</h4>
            <h3 style="color: green"><strong>{{this.campanha.saldoDisponivel |currency: 'BRL': 'symbol' :
                '1.2-2'}}</strong></h3>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.campanha.saldoProcessandoRetirada > 0">
            <h4 style="color: orange">Saldo em retirada</h4>
            <h3 style="color: orange"><strong>{{this.campanha.saldoProcessandoRetirada |currency: 'BRL': 'symbol'
                : '1.2-2'}}</strong></h3>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" *ngIf="this.campanha.saldoRetirado > 0">
            <h4 style="color: blue">Saldo retirado</h4>
            <h3 style="color: blue"><strong>{{this.campanha.saldoRetirado |currency: 'BRL': 'symbol' :
                '1.2-2'}}</strong></h3>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center"
          *ngIf="campanha.termoAceito == null && this.exibirTermoAceite(campanha) == true">
          <h4><strong>TERMO DE ACEITE PENDENTE</strong></h4>
          <button mat-raised-button color="accent" (click)="this.abrirTermoAceite(campanha)">
            Visualizar termo <mat-icon>gavel</mat-icon>
          </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center"
          *ngIf="campanha.termoAceito == true && this.visaoMobile == false">
          <button mat-raised-button color="primary" (click)="this.abrirCampanha(campanha)">
            Abrir campanha <mat-icon>visibility</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-busca-mais (click)="buscarMais()" [descricao]="'Buscar mais'"></app-busca-mais>
  </div>
  <div *ngIf="this.campanhas.length == 0" fxLayout="row" fxLayoutAlign="center center" fxFlex
    style="border-width: 3px;border-style: dashed;border-color: lightgray;border-radius: 13px;height: 100px;">
    <h2 style="margin: 0px; color:lightgray">Sem registros</h2>
  </div>
</div>
<br>