export const BancoConst =
    [
        { "nome": "BCO DO BRASIL S.A.", "value": "001", "ispb": 0, "descricao": "Banco do Brasil S.A." },
        { "nome": "BCO DA AMAZONIA S.A.", "value": "003", "ispb": 4902979, "descricao": "BANCO DA AMAZONIA S.A." },
        { "nome": "BCO DO NORDESTE DO BRASIL S.A.", "value": "004", "ispb": 7237373, "descricao": "Banco do Nordeste do Brasil S.A." },
        { "nome": "BNDES", "value": "007", "ispb": 33657248, "descricao": "BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL" },
        { "nome": "CREDICOAMO", "value": "010", "ispb": 81723108, "descricao": "CREDICOAMO CREDITO RURAL COOPERATIVA" },
        { "nome": "C.SUISSE HEDGING-GRIFFO CV S/A", "value": "011", "ispb": 61809182, "descricao": "CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A" },
        { "nome": "BANCO INBURSA", "value": "012", "ispb": 4866275, "descricao": "Banco Inbursa S.A." },
        { "nome": "STATE STREET BR S.A. BCO COMERCIAL", "value": "014", "ispb": 9274232, "descricao": "STATE STREET BRASIL S.A. - BANCO COMERCIAL" },
        { "nome": "UBS BRASIL CCTVM S.A.", "value": "015", "ispb": 2819125, "descricao": "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A." },
        { "nome": "CCM DESP TRÂNS SC E RS", "value": "016", "ispb": 4715685, "descricao": "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI" },
        { "nome": "BNY MELLON BCO S.A.", "value": "017", "ispb": 42272526, "descricao": "BNY Mellon Banco S.A." },
        { "nome": "BCO TRICURY S.A.", "value": "018", "ispb": 57839805, "descricao": "Banco Tricury S.A." },
        { "nome": "BCO BANESTES S.A.", "value": "021", "ispb": 28127603, "descricao": "BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO" },
        { "nome": "BCO BANDEPE S.A.", "value": "024", "ispb": 10866788, "descricao": "Banco Bandepe S.A." },
        { "nome": "BCO ALFA S.A.", "value": "025", "ispb": 3323840, "descricao": "Banco Alfa S.A." },
        { "nome": "BANCO ITAÚ CONSIGNADO S.A.", "value": "029", "ispb": 33885724, "descricao": "Banco Itaú Consignado S.A." },
        { "nome": "BCO SANTANDER (BRASIL) S.A.", "value": "033", "ispb": 90400888, "descricao": "BANCO SANTANDER (BRASIL) S.A." },
        { "nome": "BCO BBI S.A.", "value": "036", "ispb": 6271464, "descricao": "Banco Bradesco BBI S.A." },
        { "nome": "BCO DO EST. DO PA S.A.", "value": "037", "ispb": 4913711, "descricao": "Banco do Estado do Pará S.A." },
        { "nome": "BCO CARGILL S.A.", "value": "040", "ispb": 3609817, "descricao": "Banco Cargill S.A." },
        { "nome": "BCO DO ESTADO DO RS S.A.", "value": "041", "ispb": 92702067, "descricao": "Banco do Estado do Rio Grande do Sul S.A." },
        { "nome": "BCO DO EST. DE SE S.A.", "value": "047", "ispb": 13009717, "descricao": "Banco do Estado de Sergipe S.A." },
        { "nome": "CONFIDENCE CC S.A.", "value": "060", "ispb": 4913129, "descricao": "Confidence Corretora de Câmbio S.A." },
        { "nome": "HIPERCARD BM S.A.", "value": "062", "ispb": 3012230, "descricao": "Hipercard Banco Múltiplo S.A." },
        { "nome": "BANCO BRADESCARD", "value": "063", "ispb": 4184779, "descricao": "Banco Bradescard S.A." },
        { "nome": "GOLDMAN SACHS DO BRASIL BM S.A", "value": "064", "ispb": 4332281, "descricao": "GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A." },
        { "nome": "BCO ANDBANK S.A.", "value": "065", "ispb": 48795256, "descricao": "Banco AndBank (Brasil) S.A." },
        { "nome": "BCO MORGAN STANLEY S.A.", "value": "066", "ispb": 2801938, "descricao": "BANCO MORGAN STANLEY S.A." },
        { "nome": "BRB - BCO DE BRASILIA S.A.", "value": "070", "ispb": 208, "descricao": "BRB - BANCO DE BRASILIA S.A." },
        { "nome": "BCO. J.SAFRA S.A.", "value": "074", "ispb": 3017677, "descricao": "Banco J. Safra S.A." },
        { "nome": "BCO ABN AMRO S.A.", "value": "075", "ispb": 3532415, "descricao": "Banco ABN Amro S.A." },
        { "nome": "BCO KDB BRASIL S.A.", "value": "076", "ispb": 7656500, "descricao": "Banco KDB do Brasil S.A." },
        { "nome": "BANCO INTER", "value": "077", "ispb": 416968, "descricao": "Banco Inter S.A." },
        { "nome": "HAITONG BI DO BRASIL S.A.", "value": "078", "ispb": 34111187, "descricao": "Haitong Banco de Investimento do Brasil S.A." },
        { "nome": "BCO ORIGINAL DO AGRO S/A", "value": "079", "ispb": 9516419, "descricao": "Banco Original do Agronegócio S.A." },
        { "nome": "B&amp;T CC LTDA.", "value": "080", "ispb": 73622748, "descricao": "B&amp;T CORRETORA DE CAMBIO LTDA." },
        { "nome": "BANCOSEGURO S.A.", "value": "081", "ispb": 10264663, "descricao": "BancoSeguro S.A." },
        { "nome": "BANCO TOPÁZIO S.A.", "value": "082", "ispb": 7679404, "descricao": "BANCO TOPÁZIO S.A." },
        { "nome": "BCO DA CHINA BRASIL S.A.", "value": "083", "ispb": 10690848, "descricao": "Banco da China Brasil S.A." },
        { "nome": "UNIPRIME DO BRASIL - CC LTDA.", "value": "084", "ispb": 2398976, "descricao": "UNIPRIME DO BRASIL - COOPERATIVA DE CRéDITO LTDA." },
        { "nome": "COOPCENTRAL AILOS", "value": "085", "ispb": 5463212, "descricao": "Cooperativa Central de Crédito - Ailos" },
        { "nome": "BANCO RANDON S.A.", "value": "088", "ispb": 11476673, "descricao": "BANCO RANDON S.A." },
        { "nome": "CREDISAN CC", "value": "089", "ispb": 62109566, "descricao": "CREDISAN COOPERATIVA DE CRÉDITO" },
        { "nome": "BRK S.A. CFI", "value": "092", "ispb": 12865507, "descricao": "BRK S.A. Crédito, Financiamento e Investimento" },
        { "nome": "POLOCRED SCMEPP LTDA.", "value": "093", "ispb": 7945233, "descricao": "PÓLOCRED SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT" },
        { "nome": "BANCO FINAXIS", "value": "094", "ispb": 11758741, "descricao": "Banco Finaxis S.A." },
        { "nome": "TRAVELEX BANCO DE CÂMBIO S.A.", "value": "095", "ispb": 11703662, "descricao": "Travelex Banco de Câmbio S.A." },
        { "nome": "BCO B3 S.A.", "value": "096", "ispb": 997185, "descricao": "Banco B3 S.A." },
        { "nome": "CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.", "value": "097", "ispb": 4632856, "descricao": "Credisis - Central de Cooperativas de Crédito Ltda." },
        { "nome": "CREDIALIANÇA CCR", "value": "098", "ispb": 78157146, "descricao": "Credialiança Cooperativa de Crédito Rural" },
        { "nome": "UNIPRIME CENTRAL CCC LTDA.", "value": "099", "ispb": 3046391, "descricao": "UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA." },
        { "nome": "PLANNER CV S.A.", "value": "100", "ispb": 806535, "descricao": "Planner Corretora de Valores S.A." },
        { "nome": "RENASCENCA DTVM LTDA", "value": "101", "ispb": 62287735, "descricao": "RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
        { "nome": "XP INVESTIMENTOS CCTVM S/A", "value": "102", "ispb": 2332886, "descricao": "XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A" },
        { "nome": "CAIXA ECONOMICA FEDERAL", "value": "104", "ispb": 360305, "descricao": "CAIXA ECONOMICA FEDERAL" },
        { "nome": "LECCA CFI S.A.", "value": "105", "ispb": 7652226, "descricao": "Lecca Crédito, Financiamento e Investimento S/A" },
        { "nome": "BCO BOCOM BBM S.A.", "value": "107", "ispb": 15114366, "descricao": "Banco Bocom BBM S.A." },
        { "nome": "PORTOCRED S.A. - CFI", "value": "108", "ispb": 1800019, "descricao": "PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO" },
        { "nome": "OLIVEIRA TRUST DTVM S.A.", "value": "111", "ispb": 36113876, "descricao": "OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A." },
        { "nome": "MAGLIANO S A CTVM", "value": "113", "ispb": 61723847, "descricao": "MAGLIANO S.A. CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS" },
        { "nome": "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO", "value": "114", "ispb": 5790149, "descricao": "Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP" },
        { "nome": "ADVANCED CC LTDA", "value": "117", "ispb": 92856905, "descricao": "ADVANCED CORRETORA DE CÂMBIO LTDA" },
        { "nome": "BCO WESTERN UNION", "value": "119", "ispb": 13720915, "descricao": "Banco Western Union do Brasil S.A." },
        { "nome": "BCO RODOBENS S.A.", "value": "120", "ispb": 33603457, "descricao": "BANCO RODOBENS S.A." },
        { "nome": "BCO AGIBANK S.A.", "value": "121", "ispb": 10664513, "descricao": "Banco Agibank S.A." },
        { "nome": "BCO BRADESCO BERJ S.A.", "value": "122", "ispb": 33147315, "descricao": "Banco Bradesco BERJ S.A." },
        { "nome": "BCO WOORI BANK DO BRASIL S.A.", "value": "124", "ispb": 15357060, "descricao": "Banco Woori Bank do Brasil S.A." },
        { "nome": "BANCO GENIAL", "value": "125", "ispb": 45246410, "descricao": "BANCO GENIAL S.A." },
        { "nome": "BR PARTNERS BI", "value": "126", "ispb": 13220493, "descricao": "BR Partners Banco de Investimento S.A." },
        { "nome": "CODEPE CVC S.A.", "value": "127", "ispb": 9512542, "descricao": "Codepe Corretora de Valores e Câmbio S.A." },
        { "nome": "MS BANK S.A. BCO DE CÂMBIO", "value": "128", "ispb": 19307785, "descricao": "MS Bank S.A. Banco de Câmbio" },
        { "nome": "UBS BRASIL BI S.A.", "value": "129", "ispb": 18520834, "descricao": "UBS Brasil Banco de Investimento S.A." },
        { "nome": "CARUANA SCFI", "value": "130", "ispb": 9313766, "descricao": "CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
        { "nome": "TULLETT PREBON BRASIL CVC LTDA", "value": "131", "ispb": 61747085, "descricao": "TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA" },
        { "nome": "ICBC DO BRASIL BM S.A.", "value": "132", "ispb": 17453575, "descricao": "ICBC do Brasil Banco Múltiplo S.A." },
        { "nome": "CRESOL CONFEDERAÇÃO", "value": "133", "ispb": 10398952, "descricao": "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E" },
        { "nome": "BGC LIQUIDEZ DTVM LTDA", "value": "134", "ispb": 33862244, "descricao": "BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
        { "nome": "CONF NAC COOP CENTRAIS UNICRED", "value": "136", "ispb": 315557, "descricao": "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI" },
        { "nome": "GET MONEY CC LTDA", "value": "138", "ispb": 10853017, "descricao": "Get Money Corretora de Câmbio S.A." },
        { "nome": "INTESA SANPAOLO BRASIL S.A. BM", "value": "139", "ispb": 55230916, "descricao": "Intesa Sanpaolo Brasil S.A. - Banco Múltiplo" },
        { "nome": "NU INVEST CORRETORA DE VALORES S.A.", "value": "140", "ispb": 62169875, "descricao": "NU INVEST CORRETORA DE VALORES S.A." },
        { "nome": "BROKER BRASIL CC LTDA.", "value": "142", "ispb": 16944141, "descricao": "Broker Brasil Corretora de Câmbio Ltda." },
        { "nome": "TREVISO CC S.A.", "value": "143", "ispb": 2992317, "descricao": "Treviso Corretora de Câmbio S.A." },
        { "nome": "BEXS BCO DE CAMBIO S.A.", "value": "144", "ispb": 13059145, "descricao": "BEXS BANCO DE CÂMBIO S/A" },
        { "nome": "LEVYCAM CCV LTDA", "value": "145", "ispb": 50579044, "descricao": "LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA." },
        { "nome": "GUITTA CC LTDA", "value": "146", "ispb": 24074692, "descricao": "GUITTA CORRETORA DE CAMBIO LTDA." },
        { "nome": "FACTA S.A. CFI", "value": "149", "ispb": 15581638, "descricao": "Facta Financeira S.A. - Crédito Financiamento e Investimento" },
        { "nome": "ICAP DO BRASIL CTVM LTDA.", "value": "157", "ispb": 9105360, "descricao": "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda." },
        { "nome": "CASA CREDITO S.A. SCM", "value": "159", "ispb": 5442029, "descricao": "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor" },
        { "nome": "COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO", "value": "163", "ispb": 23522214, "descricao": "Commerzbank Brasil S.A. - Banco Múltiplo" },
        { "nome": "BRL TRUST DTVM SA", "value": "173", "ispb": 13486793, "descricao": "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A." },
        { "nome": "PEFISA S.A. - C.F.I.", "value": "174", "ispb": 43180355, "descricao": "PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
        { "nome": "GUIDE", "value": "177", "ispb": 65913436, "descricao": "Guide Investimentos S.A. Corretora de Valores" },
        { "nome": "CM CAPITAL MARKETS CCTVM LTDA", "value": "180", "ispb": 2685483, "descricao": "CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
        { "nome": "SOCRED SA - SCMEPP", "value": "183", "ispb": 9210106, "descricao": "SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P" },
        { "nome": "BCO ITAÚ BBA S.A.", "value": "184", "ispb": 17298092, "descricao": "Banco Itaú BBA S.A." },
        { "nome": "ATIVA S.A. INVESTIMENTOS CCTVM", "value": "188", "ispb": 33775974, "descricao": "ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES" },
        { "nome": "HS FINANCEIRA", "value": "189", "ispb": 7512441, "descricao": "HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS" },
        { "nome": "SERVICOOP", "value": "190", "ispb": 3973814, "descricao": "SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN" },
        { "nome": "NOVA FUTURA CTVM LTDA.", "value": "191", "ispb": 4257795, "descricao": "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda." },
        { "nome": "PARMETAL DTVM LTDA", "value": "194", "ispb": 20155248, "descricao": "PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
        { "nome": "VALOR SCD S.A.", "value": "195", "ispb": 7799277, "descricao": "VALOR SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "FAIR CC S.A.", "value": "196", "ispb": 32648370, "descricao": "FAIR CORRETORA DE CAMBIO S.A." },
        { "nome": "STONE PAGAMENTOS S.A.", "value": "197", "ispb": 16501555, "descricao": "Stone Pagamentos S.A." },
        { "nome": "BANCO BTG PACTUAL S.A.", "value": "208", "ispb": 30306294, "descricao": "Banco BTG Pactual S.A." },
        { "nome": "BANCO ORIGINAL", "value": "212", "ispb": 92894922, "descricao": "Banco Original S.A." },
        { "nome": "BCO ARBI S.A.", "value": "213", "ispb": 54403563, "descricao": "Banco Arbi S.A." },
        { "nome": "BANCO JOHN DEERE S.A.", "value": "217", "ispb": 91884981, "descricao": "Banco John Deere S.A." },
        { "nome": "BCO BS2 S.A.", "value": "218", "ispb": 71027866, "descricao": "Banco BS2 S.A." },
        { "nome": "BCO CRÉDIT AGRICOLE BR S.A.", "value": "222", "ispb": 75647891, "descricao": "BANCO CRÉDIT AGRICOLE BRASIL S.A." },
        { "nome": "BCO FIBRA S.A.", "value": "224", "ispb": 58616418, "descricao": "Banco Fibra S.A." },
        { "nome": "BANCO CIFRA", "value": "233", "ispb": 62421979, "descricao": "Banco Cifra S.A." },
        { "nome": "BCO BRADESCO S.A.", "value": "237", "ispb": 60746948, "descricao": "Banco Bradesco S.A." },
        { "nome": "BCO CLASSICO S.A.", "value": "241", "ispb": 31597552, "descricao": "BANCO CLASSICO S.A." },
        { "nome": "BANCO MASTER", "value": "243", "ispb": 33923798, "descricao": "BANCO MASTER S/A" },
        { "nome": "BCO ABC BRASIL S.A.", "value": "246", "ispb": 28195667, "descricao": "Banco ABC Brasil S.A." },
        { "nome": "BANCO INVESTCRED UNIBANCO S.A.", "value": "249", "ispb": 61182408, "descricao": "Banco Investcred Unibanco S.A." },
        { "nome": "BCV - BCO, CRÉDITO E VAREJO S.A.", "value": "250", "ispb": 50585090, "descricao": "BCV - BANCO DE CRÉDITO E VAREJO S.A." },
        { "nome": "BEXS CC S.A.", "value": "253", "ispb": 52937216, "descricao": "Bexs Corretora de Câmbio S/A" },
        { "nome": "PARANA BCO S.A.", "value": "254", "ispb": 14388334, "descricao": "PARANÁ BANCO S.A." },
        { "nome": "MONEYCORP BCO DE CÂMBIO S.A.", "value": "259", "ispb": 8609934, "descricao": "MONEYCORP BANCO DE CÂMBIO S.A." },
        { "nome": "NU PAGAMENTOS - IP", "value": "260", "ispb": 18236120, "descricao": "NU PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO" },
        { "nome": "BCO FATOR S.A.", "value": "265", "ispb": 33644196, "descricao": "Banco Fator S.A." },
        { "nome": "BCO CEDULA S.A.", "value": "266", "ispb": 33132044, "descricao": "BANCO CEDULA S.A." },
        { "nome": "BARI CIA HIPOTECÁRIA", "value": "268", "ispb": 14511781, "descricao": "BARI COMPANHIA HIPOTECÁRIA" },
        { "nome": "BCO HSBC S.A.", "value": "269", "ispb": 53518684, "descricao": "BANCO HSBC S.A." },
        { "nome": "SAGITUR CC LTDA", "value": "270", "ispb": 61444949, "descricao": "Sagitur Corretora de Câmbio Ltda." },
        { "nome": "IB CCTVM S.A.", "value": "271", "ispb": 27842177, "descricao": "IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A." },
        { "nome": "AGK CC S.A.", "value": "272", "ispb": 250699, "descricao": "AGK CORRETORA DE CAMBIO S.A." },
        { "nome": "CCR DE SÃO MIGUEL DO OESTE", "value": "273", "ispb": 8253539, "descricao": "Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel" },
        { "nome": "MONEY PLUS SCMEPP LTDA", "value": "274", "ispb": 11581339, "descricao": "MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT" },
        { "nome": "BCO SENFF S.A.", "value": "276", "ispb": 11970623, "descricao": "BANCO SENFF S.A." },
        { "nome": "GENIAL INVESTIMENTOS CVM S.A.", "value": "278", "ispb": 27652684, "descricao": "Genial Investimentos Corretora de Valores Mobiliários S.A." },
        { "nome": "CCR DE PRIMAVERA DO LESTE", "value": "279", "ispb": 26563270, "descricao": "COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE" },
        { "nome": "WILL FINANCEIRA S.A.CFI", "value": "280", "ispb": 23862762, "descricao": "WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
        { "nome": "CCR COOPAVEL", "value": "281", "ispb": 76461557, "descricao": "Cooperativa de Crédito Rural Coopavel" },
        { "nome": "RB INVESTIMENTOS DTVM LTDA.", "value": "283", "ispb": 89960090, "descricao": "RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA" },
        { "nome": "FRENTE CC LTDA.", "value": "285", "ispb": 71677850, "descricao": "Frente Corretora de Câmbio Ltda." },
        { "nome": "CCR DE OURO", "value": "286", "ispb": 7853842, "descricao": "COOPERATIVA DE CRÉDITO RURAL DE OURO SULCREDI/OURO" },
        { "nome": "CAROL DTVM LTDA.", "value": "288", "ispb": 62237649, "descricao": "CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA." },
        { "nome": "DECYSEO CC LTDA.", "value": "289", "ispb": 94968518, "descricao": "DECYSEO CORRETORA DE CAMBIO LTDA." },
        { "nome": "PAGSEGURO S.A.", "value": "290", "ispb": 8561701, "descricao": "Pagseguro Internet S.A." },
        { "nome": "BS2 DTVM S.A.", "value": "292", "ispb": 28650236, "descricao": "BS2 Distribuidora de Títulos e Valores Mobiliários S.A." },
        { "nome": "LASTRO RDV DTVM LTDA", "value": "293", "ispb": 71590442, "descricao": "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda." },
        { "nome": "VISION S.A. CC", "value": "296", "ispb": 4062902, "descricao": "VISION S.A. CORRETORA DE CAMBIO" },
        { "nome": "VIPS CC LTDA.", "value": "298", "ispb": 17772370, "descricao": "Vip's Corretora de Câmbio Ltda." },
        { "nome": "BCO SOROCRED S.A. - BM", "value": "299", "ispb": 4814563, "descricao": "BANCO SOROCRED S.A. - BANCO MÚLTIPLO" },
        { "nome": "BCO LA NACION ARGENTINA", "value": "300", "ispb": 33042151, "descricao": "Banco de la Nacion Argentina" },
        { "nome": "BPP IP S.A.", "value": "301", "ispb": 13370835, "descricao": "BPP Instituição de Pagamento S.A." },
        { "nome": "PORTOPAR DTVM LTDA", "value": "306", "ispb": 40303299, "descricao": "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA." },
        { "nome": "TERRA INVESTIMENTOS DTVM", "value": "307", "ispb": 3751794, "descricao": "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda." },
        { "nome": "CAMBIONET CC LTDA", "value": "309", "ispb": 14190547, "descricao": "CAMBIONET CORRETORA DE CÂMBIO LTDA." },
        { "nome": "VORTX DTVM LTDA.", "value": "310", "ispb": 22610500, "descricao": "VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA." },
        { "nome": "DOURADA CORRETORA", "value": "311", "ispb": 76641497, "descricao": "DOURADA CORRETORA DE CÂMBIO LTDA." },
        { "nome": "HSCM SCMEPP LTDA.", "value": "312", "ispb": 7693858, "descricao": "HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT" },
        { "nome": "AMAZÔNIA CC LTDA.", "value": "313", "ispb": 16927221, "descricao": "AMAZÔNIA CORRETORA DE CÂMBIO LTDA." },
        { "nome": "PI DTVM S.A.", "value": "315", "ispb": 3502968, "descricao": "PI Distribuidora de Títulos e Valores Mobiliários S.A." },
        { "nome": "BCO BMG S.A.", "value": "318", "ispb": 61186680, "descricao": "Banco BMG S.A." },
        { "nome": "OM DTVM LTDA", "value": "319", "ispb": 11495073, "descricao": "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
        { "nome": "BCO CCB BRASIL S.A.", "value": "320", "ispb": 7450604, "descricao": "China Construction Bank (Brasil) Banco Múltiplo S/A" },
        { "nome": "CREFAZ SCMEPP LTDA", "value": "321", "ispb": 18188384, "descricao": "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT" },
        { "nome": "CCR DE ABELARDO LUZ", "value": "322", "ispb": 1073966, "descricao": "Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz" },
        { "nome": "MERCADO PAGO", "value": "323", "ispb": 10573521, "descricao": "MERCADOPAGO.COM REPRESENTACOES LTDA." },
        { "nome": "CARTOS SCD S.A.", "value": "324", "ispb": 21332862, "descricao": "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "ÓRAMA DTVM S.A.", "value": "325", "ispb": 13293225, "descricao": "Órama Distribuidora de Títulos e Valores Mobiliários S.A." },
        { "nome": "PARATI - CFI S.A.", "value": "326", "ispb": 3311443, "descricao": "PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A." },
        { "nome": "CECM FABRIC CALÇADOS SAPIRANGA", "value": "328", "ispb": 5841967, "descricao": "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA" },
        { "nome": "QI SCD S.A.", "value": "329", "ispb": 32402502, "descricao": "QI Sociedade de Crédito Direto S.A." },
        { "nome": "BANCO BARI S.A.", "value": "330", "ispb": 556603, "descricao": "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A." },
        { "nome": "FRAM CAPITAL DTVM S.A.", "value": "331", "ispb": 13673855, "descricao": "Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A." },
        { "nome": "ACESSO SOLUCOES PAGAMENTO SA", "value": "332", "ispb": 13140088, "descricao": "Acesso Soluções de Pagamento S.A." },
        { "nome": "BANCO DIGIO", "value": "335", "ispb": 27098060, "descricao": "Banco Digio S.A." },
        { "nome": "BCO C6 S.A.", "value": "336", "ispb": 31872495, "descricao": "Banco C6 S.A." },
        { "nome": "SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.", "value": "340", "ispb": 9554480, "descricao": "Super Pagamentos e Administração de Meios Eletrônicos S.A." },
        { "nome": "ITAÚ UNIBANCO S.A.", "value": "341", "ispb": 60701190, "descricao": "ITAÚ UNIBANCO S.A." },
        { "nome": "CREDITAS SCD", "value": "342", "ispb": 32997490, "descricao": "Creditas Sociedade de Crédito Direto S.A." },
        { "nome": "FFA SCMEPP LTDA.", "value": "343", "ispb": 24537861, "descricao": "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA." },
        { "nome": "BCO XP S.A.", "value": "348", "ispb": 33264668, "descricao": "Banco XP S.A." },
        { "nome": "AL5 S.A. CFI", "value": "349", "ispb": 27214112, "descricao": "AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
        { "nome": "CREHNOR LARANJEIRAS", "value": "350", "ispb": 1330387, "descricao": "COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE" },
        { "nome": "TORO CTVM S.A.", "value": "352", "ispb": 29162769, "descricao": "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
        { "nome": "NECTON INVESTIMENTOS S.A CVM", "value": "354", "ispb": 52904364, "descricao": "NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES" },
        { "nome": "ÓTIMO SCD S.A.", "value": "355", "ispb": 34335592, "descricao": "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "MIDWAY S.A. - SCFI", "value": "358", "ispb": 9464032, "descricao": "MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
        { "nome": "ZEMA CFI S/A", "value": "359", "ispb": 5351887, "descricao": "ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A" },
        { "nome": "TRINUS CAPITAL DTVM", "value": "360", "ispb": 2276653, "descricao": "TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
        { "nome": "CIELO S.A.", "value": "362", "ispb": 1027058, "descricao": "CIELO S.A." },
        { "nome": "SINGULARE CTVM S.A.", "value": "363", "ispb": 62285390, "descricao": "SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
        { "nome": "GERENCIANET", "value": "364", "ispb": 9089356, "descricao": "GERENCIANET S.A." },
        { "nome": "SIMPAUL", "value": "365", "ispb": 68757681, "descricao": "SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A." },
        { "nome": "BCO SOCIETE GENERALE BRASIL", "value": "366", "ispb": 61533584, "descricao": "BANCO SOCIETE GENERALE BRASIL S.A." },
        { "nome": "VITREO DTVM S.A.", "value": "367", "ispb": 34711571, "descricao": "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
        { "nome": "BCO CSF S.A.", "value": "368", "ispb": 8357240, "descricao": "Banco CSF S.A." },
        { "nome": "BCO MIZUHO S.A.", "value": "370", "ispb": 61088183, "descricao": "Banco Mizuho do Brasil S.A." },
        { "nome": "WARREN CVMC LTDA", "value": "371", "ispb": 92875780, "descricao": "WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA." },
        { "nome": "UP.P SEP S.A.", "value": "373", "ispb": 35977097, "descricao": "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A." },
        { "nome": "REALIZE CFI S.A.", "value": "374", "ispb": 27351731, "descricao": "REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A." },
        { "nome": "BCO J.P. MORGAN S.A.", "value": "376", "ispb": 33172537, "descricao": "BANCO J.P. MORGAN S.A." },
        { "nome": "BMS SCD S.A.", "value": "377", "ispb": 17826860, "descricao": "BMS SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "BBC LEASING", "value": "378", "ispb": 1852137, "descricao": "BBC LEASING S.A. - ARRENDAMENTO MERCANTIL" },
        { "nome": "CECM COOPERFORTE", "value": "379", "ispb": 1658426, "descricao": "COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU" },
        { "nome": "PICPAY", "value": "380", "ispb": 22896431, "descricao": "PICPAY INSTITUIçãO DE PAGAMENTO S.A." },
        { "nome": "BCO MERCEDES-BENZ S.A.", "value": "381", "ispb": 60814191, "descricao": "BANCO MERCEDES-BENZ DO BRASIL S.A." },
        { "nome": "FIDUCIA SCMEPP LTDA", "value": "382", "ispb": 4307598, "descricao": "FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L" },
        { "nome": "EBANX IP LTDA.", "value": "383", "ispb": 21018182, "descricao": "EBANX INSTITUICAO DE PAGAMENTOS LTDA." },
        { "nome": "GLOBAL SCM LTDA", "value": "384", "ispb": 11165756, "descricao": "GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO" },
        { "nome": "NU FINANCEIRA S.A. CFI", "value": "386", "ispb": 30680829, "descricao": "NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento" },
        { "nome": "BCO TOYOTA DO BRASIL S.A.", "value": "387", "ispb": 3215790, "descricao": "Banco Toyota do Brasil S.A." },
        { "nome": "BCO MERCANTIL DO BRASIL S.A.", "value": "389", "ispb": 17184037, "descricao": "Banco Mercantil do Brasil S.A." },
        { "nome": "BCO GM S.A.", "value": "390", "ispb": 59274605, "descricao": "BANCO GM S.A." },
        { "nome": "CCR DE IBIAM", "value": "391", "ispb": 8240446, "descricao": "COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM" },
        { "nome": "BCO VOLKSWAGEN S.A", "value": "393", "ispb": 59109165, "descricao": "Banco Volkswagen S.A." },
        { "nome": "BCO BRADESCO FINANC. S.A.", "value": "394", "ispb": 7207996, "descricao": "Banco Bradesco Financiamentos S.A." },
        { "nome": "F D GOLD DTVM LTDA", "value": "395", "ispb": 8673569, "descricao": "F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
        { "nome": "HUB PAGAMENTOS", "value": "396", "ispb": 13884775, "descricao": "HUB PAGAMENTOS S.A" },
        { "nome": "LISTO SCD S.A.", "value": "397", "ispb": 34088029, "descricao": "LISTO SOCIEDADE DE CREDITO DIRETO S.A." },
        { "nome": "IDEAL CTVM S.A.", "value": "398", "ispb": 31749596, "descricao": "IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
        { "nome": "KIRTON BANK", "value": "399", "ispb": 1701201, "descricao": "Kirton Bank S.A. - Banco Múltiplo" },
        { "nome": "COOP CREDITAG", "value": "400", "ispb": 5491616, "descricao": "COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DO CENTRO OESTE - CREDIT" },
        { "nome": "IUGU IP S.A.", "value": "401", "ispb": 15111975, "descricao": "IUGU INSTITUIÇÃO DE PAGAMENTO S.A." },
        { "nome": "COBUCCIO SCD S.A.", "value": "402", "ispb": 36947229, "descricao": "COBUCCIO SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "CORA SCD S.A.", "value": "403", "ispb": 37880206, "descricao": "CORA SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "SUMUP SCD S.A.", "value": "404", "ispb": 37241230, "descricao": "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "ACCREDITO SCD S.A.", "value": "406", "ispb": 37715993, "descricao": "ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "ÍNDIGO INVESTIMENTOS DTVM LTDA.", "value": "407", "ispb": 329598, "descricao": "ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
        { "nome": "BONUSPAGO SCD S.A.", "value": "408", "ispb": 36586946, "descricao": "BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "PLANNER SCM S.A.", "value": "410", "ispb": 5684234, "descricao": "PLANNER SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR S.A." },
        { "nome": "VIA CERTA FINANCIADORA S.A. - CFI", "value": "411", "ispb": 5192316, "descricao": "Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos" },
        { "nome": "BCO CAPITAL S.A.", "value": "412", "ispb": 15173776, "descricao": "BANCO CAPITAL S.A." },
        { "nome": "BCO BV S.A.", "value": "413", "ispb": 1858774, "descricao": "BANCO BV S.A." },
        { "nome": "WORK SCD S.A.", "value": "414", "ispb": 37526080, "descricao": "WORK SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "LAMARA SCD S.A.", "value": "416", "ispb": 19324634, "descricao": "LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "ZIPDIN SCD S.A.", "value": "418", "ispb": 37414009, "descricao": "ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A" },
        { "nome": "NUMBRS SCD S.A.", "value": "419", "ispb": 38129006, "descricao": "NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "CC LAR CREDI", "value": "421", "ispb": 39343350, "descricao": "LAR COOPERATIVA DE CRÉDITO - LAR CREDI" },
        { "nome": "BCO SAFRA S.A.", "value": "422", "ispb": 58160789, "descricao": "Banco Safra S.A." },
        { "nome": "COLUNA S.A. DTVM", "value": "423", "ispb": 460065, "descricao": "COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS" },
        { "nome": "SOCINAL S.A. CFI", "value": "425", "ispb": 3881423, "descricao": "SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
        { "nome": "BIORC FINANCEIRA - CFI S.A.", "value": "426", "ispb": 11285104, "descricao": "Biorc Financeira - Crédito, Financiamento e Investimento S.A." },
        { "nome": "CRED-UFES", "value": "427", "ispb": 27302181, "descricao": "COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO" },
        { "nome": "CRED-SYSTEM SCD S.A.", "value": "428", "ispb": 39664698, "descricao": "CRED-SYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "CREDIARE CFI S.A.", "value": "429", "ispb": 5676026, "descricao": "Crediare S.A. - Crédito, financiamento e investimento" },
        { "nome": "CCR SEARA", "value": "430", "ispb": 204963, "descricao": "COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA" },
        { "nome": "BR-CAPITAL DTVM S.A.", "value": "433", "ispb": 44077014, "descricao": "BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
        { "nome": "DELCRED SCD S.A.", "value": "435", "ispb": 38224857, "descricao": "DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "PLANNER TRUSTEE DTVM LTDA", "value": "438", "ispb": 67030395, "descricao": "PLANNER TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
        { "nome": "ID CTVM", "value": "439", "ispb": 16695922, "descricao": "ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A." },
        { "nome": "CREDIBRF COOP", "value": "440", "ispb": 82096447, "descricao": "CREDIBRF - COOPERATIVA DE CRÉDITO" },
        { "nome": "MAGNETIS - DTVM", "value": "442", "ispb": 87963450, "descricao": "MAGNETIS - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA" },
        { "nome": "CREDIHOME SCD", "value": "443", "ispb": 39416705, "descricao": "CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "TRINUS SCD S.A.", "value": "444", "ispb": 40654622, "descricao": "TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "PLANTAE CFI", "value": "445", "ispb": 35551187, "descricao": "PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO" },
        { "nome": "MIRAE ASSET CCTVM LTDA", "value": "447", "ispb": 12392983, "descricao": "MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE CÂMBIO, TÍTULOS E VALORES MO" },
        { "nome": "HEMERA DTVM LTDA.", "value": "448", "ispb": 39669186, "descricao": "HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
        { "nome": "DMCARD SCD S.A.", "value": "449", "ispb": 37555231, "descricao": "DMCARD SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "FITBANK PAGAMENTOS ELETRONICOS S.A.", "value": "450", "ispb": 13203354, "descricao": "FITBANK PAGAMENTOS ELETRONICOS S.A." },
        { "nome": "CREDIFIT SCD S.A.", "value": "452", "ispb": 39676772, "descricao": "CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "MÉRITO DTVM LTDA.", "value": "454", "ispb": 41592532, "descricao": "MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
        { "nome": "BCO MUFG BRASIL S.A.", "value": "456", "ispb": 60498557, "descricao": "Banco MUFG Brasil S.A." },
        { "nome": "UY3 SCD S/A", "value": "457", "ispb": 39587424, "descricao": "UY3 SOCIEDADE DE CRÉDITO DIRETO S/A" },
        { "nome": "HEDGE INVESTMENTS DTVM LTDA.", "value": "458", "ispb": 7253654, "descricao": "HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA." },
        { "nome": "CCM SERV. PÚBLICOS SP", "value": "459", "ispb": 4546162, "descricao": "COOPERATIVA DE CRÉDITO MÚTUO DE SERVIDORES PÚBLICOS DO ESTADO DE SÃO PAULO - CRE" },
        { "nome": "ASAAS IP S.A.", "value": "461", "ispb": 19540550, "descricao": "ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A." },
        { "nome": "STARK SCD S.A.", "value": "462", "ispb": 39908427, "descricao": "STARK SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "BCO SUMITOMO MITSUI BRASIL S.A.", "value": "464", "ispb": 60518222, "descricao": "Banco Sumitomo Mitsui Brasileiro S.A." },
        { "nome": "CAPITAL CONSIG SCD S.A.", "value": "465", "ispb": 40083667, "descricao": "CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A." },
        { "nome": "MASTER S/A CCTVM", "value": "467", "ispb": 33886862, "descricao": "MASTER S/A CORRETORA DE CâMBIO, TíTULOS E VALORES MOBILIáRIOS" },
        { "nome": "BCO CAIXA GERAL BRASIL S.A.", "value": "473", "ispb": 33466988, "descricao": "Banco Caixa Geral - Brasil S.A." },
        { "nome": "CITIBANK N.A.", "value": "477", "ispb": 33042953, "descricao": "Citibank N.A." },
        { "nome": "BCO ITAUBANK S.A.", "value": "479", "ispb": 60394079, "descricao": "Banco ItauBank S.A." },
        { "nome": "DEUTSCHE BANK S.A.BCO ALEMAO", "value": "487", "ispb": 62331228, "descricao": "DEUTSCHE BANK S.A. - BANCO ALEMAO" },
        { "nome": "JPMORGAN CHASE BANK", "value": "488", "ispb": 46518205, "descricao": "JPMorgan Chase Bank, National Association" },
        { "nome": "ING BANK N.V.", "value": "492", "ispb": 49336860, "descricao": "ING Bank N.V." },
        { "nome": "BCO LA PROVINCIA B AIRES BCE", "value": "495", "ispb": 44189447, "descricao": "Banco de La Provincia de Buenos Aires" },
        { "nome": "BCO CREDIT SUISSE S.A.", "value": "505", "ispb": 32062580, "descricao": "Banco Credit Suisse (Brasil) S.A." },
        { "nome": "SENSO CCVM S.A.", "value": "545", "ispb": 17352220, "descricao": "SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A" },
        { "nome": "BCO LUSO BRASILEIRO S.A.", "value": "600", "ispb": 59118133, "descricao": "Banco Luso Brasileiro S.A." },
        { "nome": "BCO INDUSTRIAL DO BRASIL S.A.", "value": "604", "ispb": 31895683, "descricao": "Banco Industrial do Brasil S.A." },
        { "nome": "BCO VR S.A.", "value": "610", "ispb": 78626983, "descricao": "Banco VR S.A." },
        { "nome": "BCO PAULISTA S.A.", "value": "611", "ispb": 61820817, "descricao": "Banco Paulista S.A." },
        { "nome": "BCO GUANABARA S.A.", "value": "612", "ispb": 31880826, "descricao": "Banco Guanabara S.A." },
        { "nome": "OMNI BANCO S.A.", "value": "613", "ispb": 60850229, "descricao": "Omni Banco S.A." },
        { "nome": "BANCO PAN", "value": "623", "ispb": 59285411, "descricao": "Banco Pan S.A." },
        { "nome": "BCO C6 CONSIG", "value": "626", "ispb": 61348538, "descricao": "BANCO C6 CONSIGNADO S.A." },
        { "nome": "BCO LETSBANK S.A.", "value": "630", "ispb": 58497702, "descricao": "BANCO LETSBANK S.A." },
        { "nome": "BCO RENDIMENTO S.A.", "value": "633", "ispb": 68900810, "descricao": "Banco Rendimento S.A." },
        { "nome": "BCO TRIANGULO S.A.", "value": "634", "ispb": 17351180, "descricao": "BANCO TRIANGULO S.A." },
        { "nome": "BCO SOFISA S.A.", "value": "637", "ispb": 60889128, "descricao": "BANCO SOFISA S.A." },
        { "nome": "BCO PINE S.A.", "value": "643", "ispb": 62144175, "descricao": "Banco Pine S.A." },
        { "nome": "ITAÚ UNIBANCO HOLDING S.A.", "value": "652", "ispb": 60872504, "descricao": "Itaú Unibanco Holding S.A." },
        { "nome": "BANCO VOITER", "value": "653", "ispb": 61024352, "descricao": "BANCO VOITER S.A." },
        { "nome": "BCO DIGIMAIS S.A.", "value": "654", "ispb": 92874270, "descricao": "BANCO DIGIMAIS S.A." },
        { "nome": "BCO VOTORANTIM S.A.", "value": "655", "ispb": 59588111, "descricao": "Banco Votorantim S.A." },
        { "nome": "BCO CREFISA S.A.", "value": "69", "ispb": 61033106, "descricao": "Banco Crefisa S.A." },
        { "nome": "BCO DAYCOVAL S.A", "value": "707", "ispb": 62232889, "descricao": "Banco Daycoval S.A." },
        { "nome": "BCO OURINVEST S.A.", "value": "712", "ispb": 78632767, "descricao": "Banco Ourinvest S.A." },
        { "nome": "BCO RNX S.A.", "value": "720", "ispb": 80271455, "descricao": "BANCO RNX S.A." },
        { "nome": "BCO CETELEM S.A.", "value": "739", "ispb": 558456, "descricao": "Banco Cetelem S.A." },
        { "nome": "BCO RIBEIRAO PRETO S.A.", "value": "741", "ispb": 517645, "descricao": "BANCO RIBEIRAO PRETO S.A." },
        { "nome": "BANCO SEMEAR", "value": "743", "ispb": 795423, "descricao": "Banco Semear S.A." },
        { "nome": "BCO CITIBANK S.A.", "value": "745", "ispb": 33479023, "descricao": "Banco Citibank S.A." },
        { "nome": "BCO MODAL S.A.", "value": "746", "ispb": 30723886, "descricao": "Banco Modal S.A." },
        { "nome": "BCO RABOBANK INTL BRASIL S.A.", "value": "747", "ispb": 1023570, "descricao": "Banco Rabobank International Brasil S.A." },
        { "nome": "BCO COOPERATIVO SICREDI S.A.", "value": "748", "ispb": 1181521, "descricao": "BANCO COOPERATIVO SICREDI S.A." },
        { "nome": "SCOTIABANK BRASIL", "value": "751", "ispb": 29030467, "descricao": "Scotiabank Brasil S.A. Banco Múltiplo" },
        { "nome": "BCO BNP PARIBAS BRASIL S A", "value": "752", "ispb": 1522368, "descricao": "Banco BNP Paribas Brasil S.A." },
        { "nome": "NOVO BCO CONTINENTAL S.A. - BM", "value": "753", "ispb": 74828799, "descricao": "Novo Banco Continental S.A. - Banco Múltiplo" },
        { "nome": "BANCO SISTEMA", "value": "754", "ispb": 76543115, "descricao": "Banco Sistema S.A." },
        { "nome": "BOFA MERRILL LYNCH BM S.A.", "value": "755", "ispb": 62073200, "descricao": "Bank of America Merrill Lynch Banco Múltiplo S.A." },
        { "nome": "BANCO SICOOB S.A.", "value": "756", "ispb": 2038232, "descricao": "BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB" },
        { "nome": "BCO KEB HANA DO BRASIL S.A.", "value": "757", "ispb": 2318507, "descricao": "BANCO KEB HANA DO BRASIL S.A." },
    ];
