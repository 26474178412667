import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { SaldoCarteiraResponse } from './models/saldoCarteiraResponse';
import { FiltroExtratoBancarioRequest } from './models/filtroExtratoBancarioRequest';
import { ExtratoBancarioResponse } from './models/extratoBancarioResponse';
import { PaginacaoResponse } from '../comum/models/paginacaoResponse';

@Injectable({
    providedIn: 'root'
})
export class UsuarioCarteiraDigitalService {

    constructor(private readonly http: HttpClient,
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    recuperarSaldo(forcarBusca: boolean) {
        return this.http.get<ResponseData<SaldoCarteiraResponse>>(`${this.beneficioAddress}${this.beneficioUri}/banking/carteiras-digitais/saldo?forcarBusca=${forcarBusca}`).toPromise();
    }

    async recuperarExtratoBancario(filtroExtratoBancarioRequest: FiltroExtratoBancarioRequest) {
        return await this.http.get<ResponseData<PaginacaoResponse<ExtratoBancarioResponse>>>(`${this.beneficioAddress}${this.beneficioUri}/banking/carteiras-digitais/movimentacoes`, {params: this.queryParamsService.gerarQuery(filtroExtratoBancarioRequest)}).toPromise();
    }

    solicitarExtrato(filtroExtratoBancarioRequest: FiltroExtratoBancarioRequest) {
        return this.http.get(`${this.beneficioAddress}${this.beneficioUri}/banking/carteiras-digitais/movimentacoes/extrato`, { params: this.queryParamsService.gerarQuery(filtroExtratoBancarioRequest), observe: 'response', responseType: "arraybuffer"});
    }
}
