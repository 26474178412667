import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { EmpresaFiltro } from './models/empresa-filtro';
import { Empresa } from './models/empresa';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { ResponseData } from '../comum/models/responseData';
import { AuthenticationService } from '../seguranca/authentication-service';

@Injectable({
    providedIn: 'root'
})
export class EmpresaService {
    constructor(private readonly http: HttpClient,
        private queryParamsService: QueryParamsService,
        public authenticationService: AuthenticationService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    recuperarPorId(id: string) {
        return this.http.get<ResponseData<Empresa>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/empresas/${id}`);
    }

    recuperarPorFiltro(filtro: EmpresaFiltro) {
        return this.http.get<ResponseData<Empresa[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/empresas`, { params: this.queryParamsService.gerarQuery(filtro) }).toPromise();
    }

    incluir(empresa: any) {
        return this.http.post<any>(`${this.beneficioAddress}${this.beneficioUri}/administracao/empresas`, empresa);
    }

    alterar(idEmpresa: string, empresa: any) {
        return this.http.put<any>(`${this.beneficioAddress}${this.beneficioUri}/administracao/empresas/${idEmpresa}`, empresa);
    }

    excluir(idEmpresa: string) {
        return this.http.delete<any[]>(`${this.beneficioAddress}${this.beneficioUri}/administracao/empresas/${idEmpresa}`);
    }

    // listarAutoComplete(filtro: string) {
    //     return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/empresas/combobox`, { params: { conteudoPesquisa: filtro } }).toPromise();
    // }

    listarVinculados(): Promise<ResponseData<ComboBoxItem[]>> {

        var sessao = this.authenticationService.getSession();
        if (sessao.contratantesSessoes == null || sessao.contratantesSessoes.length == 0) {
            return null;
        }

        if (sessao.contratantesSessoes[0].empresas.length == 0) {
            return null;
        }

        var comboboxitem = sessao.contratantesSessoes[0].empresas.map(empresa => new ComboBoxItem(empresa.id, null, empresa.nomeRazaoSocial, empresa.nomeFantasia))
        var responseData = new ResponseData(true, null, comboboxitem);
        const promise = new Promise<ResponseData<ComboBoxItem[]>>((resolve, reject) => {
            resolve(responseData);
        })

        return promise;
    }

    listarAutoCompleteDisponiveisPorUsuario(filtro: string) {
        return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/empresas/disponiveis-por-usuario`, { params: { conteudoPesquisa: filtro } }).toPromise();
    }

    listarAutoCompleteDisponiveisCampanha(filtro: string, campanhaId: string ) {
        return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/administracao/empresas/disponiveis-por-campanha`, { params: { conteudoPesquisa: filtro, campanhaId: campanhaId } }).toPromise();
    }

    gerarArquivoDeConfiguracaoAutomatiza(idEmpresa: string) {
        return this.http.get(`${this.beneficioAddress}${this.beneficioUri}/administracao/empresas/${idEmpresa}/gera-arquivo-configuracao-automatiza`, { observe: 'response', responseType: "arraybuffer"});
      }
}
