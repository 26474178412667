import { Injectable } from '@angular/core';
import { CampanhaService } from '../campanha.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class CampanhaIncluirResolver {

  constructor(private campanhaService: CampanhaService) { }

  resolve(route: ActivatedRouteSnapshot) {

    const campanhaId = route.queryParams['campanhaId'];
    const campanhaFuncionarioId = route.queryParams['campanhaFuncionarioId'];

    if (campanhaId === null || campanhaId === undefined || campanhaFuncionarioId === null || campanhaFuncionarioId === undefined) {
      return null;
    }

    return this.campanhaService.recuperarPorCampanhaECampanhaFuncionario(campanhaId, campanhaFuncionarioId).then(registro => {
      return registro;
    });
  }

}
