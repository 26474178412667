import { Usuario } from '../models/usuario';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';
import { MessageDialogService } from '../../message-dialog/message-dialog.service';
import { Validators } from '@angular/forms';
import { UsuarioService } from '../usuario.service';
import { Location } from '@angular/common';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { AlterarUsuarioRequest } from '../models/alterarUsuarioRequest';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioEmpresaService } from 'src/app/usuario-empresa/usuario-empresa.service';
import { UsuarioEmpresa } from 'src/app/usuario-empresa/models/usuarioEmpresa';
import { DialogActionYesAndNo, DialogResponseYes } from 'src/app/message-dialog/message-dialog-action';
import { TipoPerfilEnum } from 'src/app/comum/enuns/tipoPerfil.enum';
import { TipoPerfilConst } from 'src/app/comum/consts/tipoPerfil.const';
import { UsuarioFuncionarioService } from 'src/app/usuario-funcionario/usuario-funcionario.service';
import { UsuarioFuncionario } from 'src/app/usuario-funcionario/models/usuarioFuncionario';
import { UsuarioContaDigital } from 'src/app/usuario-conta-digital/models/usuarioContaDigital';
import { CodigoPaisEnum } from 'src/app/comum/enuns/codigoPais.enum';
import { TipoLogradouroEnum } from 'src/app/comum/enuns/tipoLogradouro.enum';
import { TipoBairroEnum } from 'src/app/comum/enuns/tipoBairro.enum';
import { TipoLogradouroConst } from 'src/app/comum/consts/tipoLogradouro.const';
import { TipoBairroConst } from 'src/app/comum/consts/tipoBairro.const';
import { EstadoConst } from 'src/app/comum/consts/estado.const';
import { CodigoPaisConst } from 'src/app/comum/consts/codigoPais.const';
import { UsuarioDetalhado } from '../models/usuarioDetalhado';
import { ViaCepService } from 'src/app/comum/services/viacep.service';
import { Endereco } from 'src/app/comum/models/cep';
import { RouterService } from 'src/app/seguranca/router-service';
import { DrawerMenuItemAtual } from 'src/app/comum/componentes/drawer-menu/drawer-menu-item-atual';
import { AuthenticationService } from 'src/app/seguranca/authentication-service';

@Component({
  selector: 'app-usuario-profile',
  templateUrl: './usuario-profile.component.html',
  styleUrls: ['./usuario-profile.component.scss']
})
export class UsuarioProfileComponent implements OnInit {

  usuarioFormGroup: UntypedFormGroup;
  tiposPerfis: Array<object>;
  usuarioDetalhado: UsuarioDetalhado;
  tiposLogradouros: Array<object>;
  tiposBairros: Array<object>;
  estados: Array<object>;
  codigosPaises: Array<object>;

  constructor(private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioService,
    public location: Location,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private viaCepService: ViaCepService,
    private routerService: RouterService,
    public authService: AuthenticationService) {
    this.tiposPerfis = TipoPerfilConst;
    this.tiposLogradouros = TipoLogradouroConst;
    this.tiposBairros = TipoBairroConst;
    this.estados = EstadoConst;
    this.codigosPaises = CodigoPaisConst;
  }

  ngOnInit() {
    setTimeout(() => { this.routerService.alterarRota(new DrawerMenuItemAtual("Perfil", "home", "", true, false, null))}, 0);

    this.usuarioFormGroup = this.formBuilder.group({
      id: [{ disabled: true, value: null }, [Validators.required]],
      dddCelular: [{ disabled: true, value: null }, [Validators.required]],
      celular: [{ disabled: true, value: null }, [Validators.required]],
      email: [{ disabled: true, value: null }, [Validators.required, Validators.email]],
      cpf: [{ disabled: true, value: null }, [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],

      nome: [null, [Validators.required]],
      sobrenome: [null, [Validators.required]],
      dddTelefone: [null, [Validators.minLength(2), Validators.maxLength(2)]],
      telefone: [null, [Validators.minLength(8), Validators.maxLength(9)]],
      nascimento: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      cep: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      logradouro: [null, [Validators.required]],
      tipoLogradouro: [null, [Validators.required]],
      numero: [null, [Validators.required]],
      complemento: [null],
      bairro: [null, [Validators.required]],
      tipoBairro: [null, [Validators.required]],
      codigoMunicipio: [null, [Validators.required, Validators.max(5400000)]],
      municipio: [null, [Validators.required]],
      uf: [null, [Validators.required]],
      codigoPais: [null, [Validators.required]]
    });

    this.activatedRoute.data.subscribe((data) => {
      this.usuarioDetalhado = data.usuarioDetalhado.data;
      this.atribuirValores(data.usuarioDetalhado.data);
    });

    this.usuarioFormGroup
      .get('cep')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }

        if (!value || value.length != 8) {
          return;
        }
        this.pesquisarCep(value);

      });
  }

  async salvar() {
    if (!this.usuarioFormGroup.valid) {
      throw new Error('Dados inválidos!');
    }

    let alterarUsuarioRequest = new AlterarUsuarioRequest(
      this.usuarioFormGroup.value.nome,
      this.usuarioFormGroup.value.sobrenome,
      new Date(this.usuarioFormGroup.value.nascimento.substr(4, 4), (this.usuarioFormGroup.value.nascimento.substr(2, 2) - 1), this.usuarioFormGroup.value.nascimento.substr(0, 2)),
      this.usuarioFormGroup.value.dddTelefone,
      this.usuarioFormGroup.value.telefone,
      this.usuarioFormGroup.value.cep,
      this.usuarioFormGroup.value.logradouro,
      this.usuarioFormGroup.value.tipoLogradouro,
      this.usuarioFormGroup.value.numero,
      this.usuarioFormGroup.value.complemento,
      this.usuarioFormGroup.value.bairro,
      this.usuarioFormGroup.value.tipoBairro,
      this.usuarioFormGroup.value.codigoMunicipio,
      this.usuarioFormGroup.value.municipio,
      this.usuarioFormGroup.value.uf,
      this.usuarioFormGroup.value.codigoPais,
      "Brasil");

    await this.usuarioService.alterarDadosPessoais(alterarUsuarioRequest).then(response => {
      this.snackBar.open(`Dados salvos com sucesso!`, `Ok`, { duration: 2000 });
    });
  }

  atribuirValores(usuarioDetalhado: UsuarioDetalhado) {
    if (usuarioDetalhado) {
      this.usuarioFormGroup.controls['id'].setValue(usuarioDetalhado.id);
      this.usuarioFormGroup.controls['nome'].setValue(usuarioDetalhado.nome);
      this.usuarioFormGroup.controls['sobrenome'].setValue(usuarioDetalhado.sobrenome);
      this.usuarioFormGroup.controls['dddCelular'].setValue(usuarioDetalhado.dddCelular);
      this.usuarioFormGroup.controls['celular'].setValue(usuarioDetalhado.celular);
      this.usuarioFormGroup.controls['email'].setValue(usuarioDetalhado.email);
      this.usuarioFormGroup.controls['cpf'].setValue(usuarioDetalhado.cpf);

      this.usuarioFormGroup.controls['dddTelefone'].setValue(usuarioDetalhado.dddTelefone);
      this.usuarioFormGroup.controls['telefone'].setValue(usuarioDetalhado.telefone);
      this.usuarioFormGroup.controls['nascimento'].setValue(new Date(usuarioDetalhado.nascimento).toLocaleDateString().split('/').join(''));
      this.usuarioFormGroup.controls['cep'].setValue(usuarioDetalhado.cep);
      this.usuarioFormGroup.controls['logradouro'].setValue(usuarioDetalhado.logradouro);
      this.usuarioFormGroup.controls['tipoLogradouro'].setValue(usuarioDetalhado.tipoLogradouro != null ? TipoLogradouroEnum[usuarioDetalhado.tipoLogradouro.value] : null);
      this.usuarioFormGroup.controls['numero'].setValue(usuarioDetalhado.numero);
      this.usuarioFormGroup.controls['complemento'].setValue(usuarioDetalhado.complemento);
      this.usuarioFormGroup.controls['bairro'].setValue(usuarioDetalhado.bairro);
      this.usuarioFormGroup.controls['tipoBairro'].setValue(usuarioDetalhado.tipoBairro != null ? TipoBairroEnum[usuarioDetalhado.tipoBairro.value] : null);
      this.usuarioFormGroup.controls['codigoMunicipio'].setValue(usuarioDetalhado.codigoMunicipio);
      this.usuarioFormGroup.controls['municipio'].setValue(usuarioDetalhado.municipio);
      this.usuarioFormGroup.controls['uf'].setValue(usuarioDetalhado.uf);
      this.usuarioFormGroup.controls['codigoPais'].setValue(usuarioDetalhado.codigoPais.toString());
    }
  }

  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }

  recarregarUsuario() {
    this.usuarioService.recuperarDadosPessoais().then(registro => {
      this.atribuirValores(registro.data);
      this.usuarioDetalhado = registro.data;
    });
  }

  pesquisarCep(cep: string) {
    this.viaCepService.recuperar(cep.replace(/[^0-9]+/g, '')).then((endereco: Endereco) => {

      this.usuarioFormGroup.controls['bairro'].setValue(endereco.bairro);
      this.usuarioFormGroup.controls['complemento'].setValue(endereco.complemento);
      this.usuarioFormGroup.controls['codigoMunicipio'].setValue(endereco.ibge);
      this.usuarioFormGroup.controls['municipio'].setValue(endereco.localidade);
      this.usuarioFormGroup.controls['logradouro'].setValue(endereco.logradouro);
      this.usuarioFormGroup.controls['uf'].setValue(endereco.uf);
      this.usuarioFormGroup.controls['tipoBairro'].setValue(1);
      this.usuarioFormGroup.controls['pais'].setValue(CodigoPaisEnum[1058]);
      this.usuarioFormGroup.controls['codigoPais'].setValue(this.codigosPaises[0]);
    }).catch((error) => {
      this.snackBar.open(error.message, `Ok`);
    });
  }

  public logout() {
    this.authService.deauthenticate();
    //this.sidenav.close();
}

}
