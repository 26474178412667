import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators } from '@angular/forms';
import { ProdutoService } from '../produto.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MovimentoConst } from 'src/app/comum/consts/movimento.const';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { InserirProdutoRequest } from '../models/inserirProdutoRequest';
import { GrupoService } from 'src/app/grupo/grupo.service';
import { SubGrupoService } from 'src/app/sub-grupo/sub-grupo.service';
import { FabricanteService } from 'src/app/fabricante/fabricante.service';
import { UnidadeMedidaService } from 'src/app/unidade-medida/unidade-medida.service';
import { SubGrupoIncluirModalComponent } from 'src/app/sub-grupo/sub-grupo-incluir-modal/sub-grupo-incluir-modal.component';
import { GrupoIncluirModalComponent } from 'src/app/grupo/grupo-incluir-modal/grupo-incluir-modal.component';
import { FabricanteNovoModalComponent } from 'src/app/fabricante/fabricante-novo-modal/fabricante-novo-modal.component';
import { UnidadeMedidaIncluirModalComponent } from 'src/app/unidade-medida/unidade-medida-incluir-modal/unidade-medida-incluir-modal.component';
import { CategoriaService } from 'src/app/categoria/categoria.service';
import { CategoriaIncluirModalComponent } from 'src/app/categoria/categoria-incluir-modal/categoria-incluir-modal.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-produto-incluir-modal',
  templateUrl: './produto-incluir-modal.component.html',
  styleUrls: ['./produto-incluir-modal.component.scss']
})
export class ProdutoIncluirModalComponent implements OnInit {

  produtoFormGroup: UntypedFormGroup;
  empresas: ComboBoxItem[];
  categorias: ComboBoxItem[];
  grupos: ComboBoxItem[];
  subGrupos: ComboBoxItem[];
  unidadesMedidas: ComboBoxItem[];
  fabricantes: ComboBoxItem[];

  constructor(private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private produtoService: ProdutoService,
    private empresaService: EmpresaService,
    private categoriaService: CategoriaService,
    private grupoService: GrupoService,
    private subGrupoService: SubGrupoService,
    private fabricanteService: FabricanteService,
    private unidadeMedidaService: UnidadeMedidaService,
    public location: Location,
    private readonly ref: MatDialogRef<ProdutoIncluirModalComponent>,
    private readonly dialog: MatDialog) {
  }


  ngOnInit() {

    this.produtoFormGroup = this.formBuilder.group({
      nome: [null, [Validators.required, Validators.maxLength(500)]],
      referencia: [null, [Validators.maxLength(20)]],
      categoria: [null],
      grupo: [null],
      subGrupo: [null],
      fabricante: [null],
      ncm: [null, [Validators.max(99999999)]],
      unidadeMedida: [null],
      cest: [null, [Validators.max(9999999)]],

      custo: [null, [Validators.required]],
      lucro: [null, [Validators.required]],
      venda: [null, [Validators.required]],
      estoqueAtual: [null],
      estoqueMaximo: [null],
      estoqueMinimo: [null],
      cfop: [null, [Validators.max(9999)]],
      ean: [null, [Validators.maxLength(20)]],

      percentualDesconto: [null],
      valorDesconto: [null],
      descontoMaximo: [null],

      empresa: [null, [Validators.required]],
    });

    this.empresaService.listarVinculados()
      .then
      (response => {
        this.empresas = response.data;
        this.produtoFormGroup.get("empresa").setValue(this.empresas.length == 1 ? this.empresas[0].id : null);
      });

    this.produtoFormGroup
      .get('categoria')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.produtoFormGroup.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.categoriaService.listarAutoComplete(value, this.produtoFormGroup.value.empresa)
          .then
          (response => {
            this.categorias = response.data;
          });
      });


    this.produtoFormGroup
      .get('grupo')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.produtoFormGroup.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.grupoService.listarAutoComplete(value, this.produtoFormGroup.value.empresa)
          .then
          (response => {
            this.grupos = response.data;
          });
      });

    this.produtoFormGroup
      .get('subGrupo')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.produtoFormGroup.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.subGrupoService.listarAutoComplete(value, this.produtoFormGroup.value.empresa)
          .then
          (response => {
            this.subGrupos = response.data;
          });
      });

    this.produtoFormGroup
      .get('fabricante')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.produtoFormGroup.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.fabricanteService.listarAutoComplete(value, this.produtoFormGroup.value.empresa)
          .subscribe
          (response => {
            this.fabricantes = response.data;
          });
      });

    this.produtoFormGroup
      .get('unidadeMedida')
      .valueChanges
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }
        if (this.produtoFormGroup.value.empresa == null) {
          this.snackBar.open(`A empresa precisa ser informada para que a consulta seja feita!`, `Ok`, { duration: 2000 });
          return;
        }
        this.unidadeMedidaService.listarAutoComplete(value, this.produtoFormGroup.value.empresa)
          .then
          (response => {
            this.unidadesMedidas = response.data;
          });
      });
  }

  async salvar() {
    if (!this.produtoFormGroup.valid) {
      throw new Error('Dados inválidos!');
    }

    let inserirProdutoRequest = new InserirProdutoRequest(this.produtoFormGroup.value.nome,
      this.produtoFormGroup.value.referencia,
      this.produtoFormGroup.value.categoria ? this.produtoFormGroup.value.categoria.id : null,
      this.produtoFormGroup.value.grupo ? this.produtoFormGroup.value.grupo.id : null,
      this.produtoFormGroup.value.subGrupo ? this.produtoFormGroup.value.subGrupo.id : null,
      this.produtoFormGroup.value.fabricante ? this.produtoFormGroup.value.fabricante.id : null,
      this.produtoFormGroup.value.ncm,
      this.produtoFormGroup.value.unidadeMedida ? this.produtoFormGroup.value.unidadeMedida.id : null,
      this.produtoFormGroup.value.cest,
      this.produtoFormGroup.value.custo,
      this.produtoFormGroup.value.lucro,
      this.produtoFormGroup.value.venda,
      this.produtoFormGroup.value.estoqueAtual,
      this.produtoFormGroup.value.estoqueMaximo,
      this.produtoFormGroup.value.estoqueMinimo,
      this.produtoFormGroup.value.cfop,
      this.produtoFormGroup.value.ean,
      this.produtoFormGroup.value.percentualDesconto,
      this.produtoFormGroup.value.valorDesconto,
      this.produtoFormGroup.value.descontoMaximo,
      this.produtoFormGroup.value.empresa);

    await this.produtoService.incluir(inserirProdutoRequest).then(response => {
      this.snackBar.open(`Produto salva com sucesso!`, `Ok`, { duration: 2000 });
      this.ref.close(response.data);
    });
  }

  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }

  cadastrarSubGrupo() {
    const ref = this.dialog.open(SubGrupoIncluirModalComponent, { width: '500px' });

    ref.afterClosed().subscribe(response => {

      if (response) {
        this.snackBar.open(`Sub grupo cadastrado com sucesso!`,
          `Ok`, { duration: 3000 });
        this.produtoFormGroup.get('subGrupo').setValue({ id: response.id, descricao: response.nome });

      } else {
        this.snackBar.open(`Edição do item cancelada!`,
          `Ok`, { duration: 3000 });
      }
    });

  }

  cadastrarCategoria() {
    const ref = this.dialog.open(CategoriaIncluirModalComponent, { width: '500px' });
    ref.afterClosed().subscribe(response => {

      if (response) {
        this.snackBar.open(`Categoria cadastrada com sucesso!`,
          `Ok`, { duration: 3000 });
        this.produtoFormGroup.get('categoria').setValue({ id: response.id, descricao: response.nome });

      } else {
        this.snackBar.open(`Edição do item cancelada!`,
          `Ok`, { duration: 3000 });
      }
    });
  }

  cadastrarGrupo() {
    const ref = this.dialog.open(GrupoIncluirModalComponent, { width: '500px' });
    ref.afterClosed().subscribe(response => {

      if (response) {
        this.snackBar.open(`Grupo cadastrado com sucesso!`,
          `Ok`, { duration: 3000 });
        this.produtoFormGroup.get('grupo').setValue({ id: response.id, descricao: response.nome });

      } else {
        this.snackBar.open(`Edição do item cancelada!`,
          `Ok`, { duration: 3000 });
      }
    });
  }

  cadastrarFabricante() {
    const ref = this.dialog.open(FabricanteNovoModalComponent, { width: '600px' });
    ref.afterClosed().subscribe(response => {

      if (response) {
        this.snackBar.open(`Fabricante cadastrado com sucesso!`,
          `Ok`, { duration: 3000 });
        this.produtoFormGroup.get('fabricante').setValue({ id: response.id, descricao: response.nomeRazaoSocial });

      } else {
        this.snackBar.open(`Edição do item cancelada!`,
          `Ok`, { duration: 3000 });
      }
    });
  }

  cadastrarUnidadeMedida() {
    const ref = this.dialog.open(UnidadeMedidaIncluirModalComponent, { width: '600px' });
    ref.afterClosed().subscribe(response => {

      if (response) {
        this.snackBar.open(`UnidadeMedida cadastrado com sucesso!`,
          `Ok`, { duration: 3000 });
        this.produtoFormGroup.get('unidadeMedida').setValue({ id: response.id, descricao: `${response.abreviacao} - ${response.nome}` });

      } else {
        this.snackBar.open(`Edição do item cancelada!`,
          `Ok`, { duration: 3000 });
      }
    });
  }
}
