import { Component, OnInit, Inject } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CampanhaFuncionarioMetaService } from '../campanha-funcionario-meta.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InserirCampanhaFuncionarioMetaRequest } from '../models/inserirCampanhaFuncionarioMetaRequest';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { CampanhaFuncionarioService } from 'src/app/campanha-funcionario/campanha-funcionario.service';
import { CampanhaRemuneracao } from 'src/app/campanha-remuneracao/models/campanhaRemuneracao';
import { TipoMetaConst } from 'src/app/comum/consts/tipoMeta.const';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CampanhaFuncionarioMetaAgrupada } from '../models/campanhaFuncionarioMetaAgrupada';
import { TipoMetaEnum } from 'src/app/comum/enuns/tipoMeta.enum';
import { TipoCalculoRemuneracaoEnum } from 'src/app/comum/enuns/tipoCalculoRemuneracao.enum';

@Component({
  selector: 'app-campanha-funcionario-meta-novo-modal',
  templateUrl: './campanha-funcionario-meta-novo-modal.component.html',
  styleUrls: ['./campanha-funcionario-meta-novo-modal.component.scss']
})
export class CampanhaFuncionarioMetaNovoModalComponent implements OnInit {

  campanhaFuncionarioMetaForm: UntypedFormGroup;
  tiposMetas: Array<object>;
  campanhaFuncionarioMetaAgrupada: CampanhaFuncionarioMetaAgrupada;
  campanhasFuncionarios: ComboBoxItem[];
  campanhasRemuneracoes: ComboBoxItem[];

  constructor(private formBuilder: UntypedFormBuilder,
    private campanhaFuncionarioMetaService: CampanhaFuncionarioMetaService,
    private campanhaFuncionarioService: CampanhaFuncionarioService,
    private snackBar: MatSnackBar,
    private ref: MatDialogRef<CampanhaFuncionarioMetaNovoModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CampanhaFuncionarioMetaAgrupada,
    private dialog: MatDialog) {

    this.campanhaFuncionarioMetaAgrupada = this.data;
    this.tiposMetas = TipoMetaConst;

    this.campanhaFuncionarioMetaForm = this.formBuilder.group({
      tipoMeta: [(this.campanhaFuncionarioMetaAgrupada.tipoCalculoRemuneracao.value == "VendaTotal" ? 1 :
                                          (this.campanhaFuncionarioMetaAgrupada.tipoCalculoRemuneracao.value == "QuantidadeTotal" ? 2 : null)), [Validators.required]],
      meta: [null, [Validators.required]],
      metaMinima: [null, [Validators.required]],
      campanhaRemuneracao: [{ disabled: true, value: { id: this.campanhaFuncionarioMetaAgrupada.campanhaRemuneracaoId, descricao: this.campanhaFuncionarioMetaAgrupada.campanhaRemuneracaoNome } }, [Validators.required]],
      campanhaFuncionario: [{ disabled: true, value: { id: this.campanhaFuncionarioMetaAgrupada.campanhaFuncionarioId, descricao: this.campanhaFuncionarioMetaAgrupada.funcionarioNome } }, [Validators.required]]
    });

    this.campanhaFuncionarioMetaForm.updateValueAndValidity();
  }

  ngOnInit() {
  }

  salvar() {
    let inserirCampanhaFuncionarioMetaRequest = new InserirCampanhaFuncionarioMetaRequest(
      (this.campanhaFuncionarioMetaAgrupada.tipoCalculoRemuneracao.value == "VendaTotal" ? 1 :
      (this.campanhaFuncionarioMetaAgrupada.tipoCalculoRemuneracao.value == "QuantidadeTotal" ? 2 : null)),
      this.campanhaFuncionarioMetaForm.value.meta,
      this.campanhaFuncionarioMetaForm.value.metaMinima,
      this.campanhaFuncionarioMetaAgrupada.campanhaRemuneracaoId);

    this.campanhaFuncionarioMetaService
      .incluir(this.campanhaFuncionarioMetaAgrupada.campanhaId, this.campanhaFuncionarioMetaAgrupada.campanhaFuncionarioId, inserirCampanhaFuncionarioMetaRequest)
      .subscribe(response => {
        if (!response) {
          return;
        }

        if (!response.success) {
          return;
        }

        this.ref.close(true);
      });
  }

  displayFn(option: any): string {
    if (option === null || option === undefined) {
      return;
    }
    if (option.valor === undefined) {
      return option.descricao;
    }
    return option.valor;
  }
}
