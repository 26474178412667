import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AuthenticationService } from '../seguranca/authentication-service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UsuarioService } from '../usuario/usuario.service';
import { UsuarioModel } from '../dominio/modelos/UsuarioModel';
import { TermoAceiteAnexoModalComponent } from '../termo-aceite/termo-aceite-anexo-modal/termo-aceite-anexo-modal.component';
import { TermoAceiteDataModel } from '../termo-aceite/models/termoAceiteDataModel';
import { AutenticacaoTokenCodeRequest } from './models/autenticacaoTokenCodeRequest';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public credenciais: UntypedFormGroup;
  public recuperaLoginForm: UntypedFormGroup;
  public autenticaTokenCodeForm: UntypedFormGroup;

  public exibirLogin: boolean = true;
  public exibirUnidades: boolean;
  public exibirRecuperarLogin: boolean;
  public exibirAutenticacaoTokenCode: boolean;

  public nomeUsuario: string;
  public usuarioModel: UsuarioModel;
  public subscriberId: string;
  constructor(private loginService: LoginService,
    private fromBuilder: UntypedFormBuilder,
    private usuarioService: UsuarioService,
    private readonly snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.subscriberId = this.router.routerState.snapshot.root.queryParams['_sid'];
    
    console.log(this.subscriberId);

    this.credenciais = this.fromBuilder.group({
      emailCpf: [null, Validators.required],
      senha: [null, Validators.required]
    });
    
    this.recuperaLoginForm = this.fromBuilder.group({
      emailCpf: [null, Validators.required]
    });

    this.autenticaTokenCodeForm = this.fromBuilder.group({
      tokenAutorizacao: [null, Validators.required],
      tokenCode: [null, Validators.required]
    });
  }

  async login() {
    if (!this.credenciais.valid)
      return;

      this.loginService.login(this.credenciais.value).then(response => {
        this.nomeUsuario = response.data.nome;
        this.usuarioModel = response.data;
        if (response.data.duplaAutenticacao) {
          this.snackBar.open(`Um sms foi enviado para o telefone  ${response.data.telefone} com o código de autorização!`, `Ok`, { duration: 5000 });
          this.habilitarAutenticacaoTokenCode(response.data.token);
        }
        else {
          this.salvarLogin(response.data);
        }
      })
  }

  async abrirTermoAceite(usuarioModel: UsuarioModel) {

    const ref = this.dialog.open(TermoAceiteAnexoModalComponent, {
      width: '600px',
      disableClose: true,
      data: new TermoAceiteDataModel(usuarioModel.token, usuarioModel.termoAceitePendenteId) 
    });

    ref.afterClosed().subscribe(async value => {
      if (value) {
        await this.finalizarLogin(usuarioModel);
      } else {
        this.snackBar.open(`Termos não foram aceitos!`,
          `Ok`, { duration: 3000 });
      }
    });
}

  async salvarLogin(usuarioModel: UsuarioModel) {
    if (usuarioModel.termoAceitePendente) {
      await this.abrirTermoAceite(usuarioModel);
    }
    else {
      await this.finalizarLogin(usuarioModel);
    }
  }

  async finalizarLogin(usuarioModel: UsuarioModel) {

    
    if(this.subscriberId != null)
    {
      await this.loginService.autenticarChat(this.usuarioModel.token, this.subscriberId).then((s) => {
        this.router.navigate(['/sucesso-onboarding']);
      }, (e) => {
        this.router.navigate(['/erro-onboarding']);
      });
    }
    else{

      this.authenticationService.authenticate(this.usuarioModel);
    
      var login = await this.loginService.recuperarLogin();

      this.authenticationService.authenticate(login.data);

      this.authenticationService.authenticatedEvent.emit(true);

      this.router.navigate(["/home"]);
    }
  }


  habilitarLogin(email: string) {
    this.exibirLogin = true;
    this.exibirRecuperarLogin = false;

    this.credenciais.get("emailCpf").setValue(email);
  }

  habilitarRecuperarLogin() {
    this.exibirLogin = false;
    this.exibirRecuperarLogin = true;
    this.recuperaLoginForm.get("emailCpf").setValue(this.credenciais.value.emailCpf);
  }

  async recuperarLogin() {
    if (!this.recuperaLoginForm.valid)
      return;

    var response = await this.usuarioService.resetarSenha(this.recuperaLoginForm.value);

    this.habilitarLogin(this.recuperaLoginForm.value.emailCpf);

    this.snackBar.open(`Uma nova senha de acesso foi enviada para o seu email.`,
    `Ok`, { duration: 5000 });
  }

  habilitarAutenticacaoTokenCode(tokenAutorizacao: string) {
    this.exibirLogin = false;
    this.exibirAutenticacaoTokenCode = true;
    this.exibirRecuperarLogin = false;

    this.autenticaTokenCodeForm.get("tokenAutorizacao").setValue(tokenAutorizacao);
  }

  onSubmit() {
    this.login();
  }

  autenticarTokenCode() {
    if (this.autenticaTokenCodeForm.invalid)
      return;

    let request = new AutenticacaoTokenCodeRequest(this.autenticaTokenCodeForm.value.tokenCode);

    this.loginService.autenticarTokenCode(this.autenticaTokenCodeForm.value.tokenAutorizacao, request).then(response => {
      if (response.data.duplaAutenticacaoVerificada) {
        this.finalizarLogin(response.data);
      }
      else {

      }
    })
  }

  reenviarTokenCode() {
    this.loginService.reenviarTokenCode(this.autenticaTokenCodeForm.value.tokenAutorizacao).then(response => {
      this.snackBar.open(`Um sms foi enviado para o telefone  ${response.data.telefone} com o novo código de autorização!`, `Ok`, { duration: 5000 });
    })
  }
}
