import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import {
    ErrorStateMatcher,
    MAT_DATE_LOCALE,
    MatNativeDateModule,
    MatOptionModule,
    ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgProgressModule } from 'ngx-progressbar';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { BENEFICIO_URL, BENEFICIO_ADDRESS, BENEFICIO_URI } from './app.config';
import { AppRoutingModule } from './app.routing.module';
import { ComponentesModule } from './comum/componentes/componentes.module';
import { PtBrPaginator } from './comum/default-paginator';
import { CnpjSefazService } from './comum/services/cnpj-sefaz.service';
import { LocalStorageService } from './comum/services/local-storage.service';
import { QueryParamsService } from './comum/services/queryParams.service';
import { HomeModule } from './home/home.module';
import { MaterialModule } from './material/material.module';
import { MessageDialogService } from './message-dialog/message-dialog.service';
import { GeneralRouteGuard } from './seguranca/general-route-guard';
import { LoginRouteGuard } from './seguranca/login-route-guard';
import { SegurancaModule } from './seguranca/seguranca.module';
import { SubCategoriaModule } from './sub-categoria/sub-categoria-module';
import { TelefoneModule } from './telefone/telefone.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { NgxMaskModule } from 'ngx-mask';
import { HttpClientJsonpModule } from '@angular/common/http';
import { ViaCepService } from './comum/services/viacep.service';
import { ProdutoModule } from './produto/produto.module';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatBottomSheetModule }from '@angular/material/bottom-sheet';
import { MatBadgeModule }from '@angular/material/badge';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { RouterService } from './seguranca/router-service';

registerLocaleData(localePt, localePtExtra);


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FlexLayoutModule,
        HomeModule,
        MaterialModule,
        ToolbarModule,
        MatOptionModule,
        ComponentesModule,
        TelefoneModule,
        SubCategoriaModule,
        MatChipsModule,
        NgProgressModule,
        NgProgressModule,
        SegurancaModule,
        NgProgressModule.withConfig({
            color: '#ff5722'
        }),
        NgxMaskModule.forRoot(),
        HttpClientJsonpModule,
        MatDialogModule,
        ProdutoModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatBottomSheetModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        MatTabsModule,
        MatBadgeModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: "rgba(0,0,0,0.1)",
            backdropBorderRadius: "4px",
            primaryColour: "#ffffff",
            secondaryColour: "#ffffff",
            tertiaryColour: "#ffffff",
        }),
    ],
    providers: [
        LocalStorageService,
        QueryParamsService,
        CnpjSefazService,
        MessageDialogService,
        ViaCepService,
        RouterService,
        MatNativeDateModule, {
            provide: MatPaginatorIntl,
            useClass: PtBrPaginator
        },
        {
            provide: ErrorStateMatcher,
            useClass: ShowOnDirtyErrorStateMatcher
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'pt-BR'
        },
        {   
            provide: LOCALE_ID, 
            useValue: 'pt' 
        },
        {
            provide: BENEFICIO_URL,
            useValue: environment.beneficioUrl
        },
        {
            provide: BENEFICIO_ADDRESS,
            useValue: environment.beneficioAddress
        },
        {
            provide: BENEFICIO_URI,
            useValue: environment.beneficioUri
        },
        {   
            provide: MAT_DIALOG_DEFAULT_OPTIONS, 
            useValue: 
            {
                hasBackdrop: true,
                disableClose: true,
                maxWidth: '95vw'
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
