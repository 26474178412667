import { Injectable } from '@angular/core';
import { UsuarioService } from '../usuario.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class UsuarioProfileResolver {

  constructor(private usuarioService: UsuarioService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.usuarioService.recuperarDadosPessoais().then(registro => {
      return registro;
    });
  }
}
