import { HttpParams } from '@angular/common/http';
import { TipoOrdenacaoEnum } from 'src/app/comum/enuns/tipoOrdenacao.enum';

export class ProdutoFiltroRequest {

    constructor(public nome: string,
        public codigo: number,
        public referencia: string,
        public categoriaId: string,
        public grupoId: string,
        public subGrupoId: string,
        public fabricanteId: string,
        public ncm: string,
        public unidadeMedidaId: string,
        public empresaId: string,
        public tipoOrdenacao: TipoOrdenacaoEnum,
        public campoOrdenacao: string,
        public pular: number,
        public quantidade: number) { }

    alterarNome(nome: string) {
        this.nome = nome;
    }
    alterarCodigo(codigo: number) {
        this.codigo = codigo;
    }
    alterarReferencia(referencia: string) {
        this.referencia = referencia;
    }
    alterarCategoriaId(categoriaId: string) {
        this.categoriaId = categoriaId;
    }
    alterarGrupoId(grupoId: string) {
        this.grupoId = grupoId;
    }
    alterarSubGrupoId(subGrupoId: string) {
        this.subGrupoId = subGrupoId;
    }
    alterarFabricanteId(fabricanteId: string) {
        this.fabricanteId = fabricanteId;
    }
    alterarNcm(ncm: string) {
        this.ncm = ncm;
    }
    alterarUnidadeMedidaId(unidadeMedidaId: string) {
        this.unidadeMedidaId = unidadeMedidaId;
    }
    alterarEmpresaId(empresaId: string) {
        this.empresaId = empresaId;
    }
    alterarPular(pular: number) {
        this.pular = pular;
    }
    alterarQuantidade(quantidade: number) {
        this.quantidade = quantidade;
    }

    queryString() {
        return new HttpParams({ fromString: JSON.stringify(this) });
    }

        
}
