import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Campanha } from './models/campanha';
import { CampanhaFiltro } from './models/campanha-filtro';
import { BENEFICIO_URI, BENEFICIO_ADDRESS } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { InserirCampanhaRequest } from './models/inserirCampanhaRequest';
import { ResponseData } from '../comum/models/responseData';
import { ComboBoxItem } from '../comum/models/comboBoxItem';
import { AlterarCampanhaRequest } from './models/alterarCampanhaRequest';
import { CampanhaFiltroRequest } from './models/campanha-filtro-request';
import { ArquivoBlob } from '../campanha-totalizacao/models/arquivoBlob';

import { CampanhaListaUsuario } from './models/campanha-lista-usuario';
import { CampanhaFuncionarioSaldo } from '../campanha-funcionario-saldo/models/campanhaFuncionarioSaldo';

@Injectable({ providedIn: 'root' })
export class CampanhaService {

  constructor(private http: HttpClient,
    private queryParamsService: QueryParamsService,
    @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
    @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

  public recuperarPorFiltro(filtro: CampanhaFiltroRequest) {
    return this.http.get<ResponseData<CampanhaListaUsuario[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/campanhas-por-usuario`, { params: this.queryParamsService.gerarQuery(filtro) }).toPromise();
  }

  public recuperarPorCampanhaECampanhaFuncionario(id: string, campanhaFuncionarioId: string) {
    return this.http.get<ResponseData<CampanhaListaUsuario>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/campanhas-por-usuario/${id}/campanhas-funcionarios/${campanhaFuncionarioId}`).toPromise();
  }

  public incluir(campanha: InserirCampanhaRequest) {
    return this.http.post<ResponseData<Campanha>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas`, campanha).toPromise();
  }

  public alterar(id: any, campanha: AlterarCampanhaRequest) {
    return this.http.put<ResponseData<Campanha>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}`, campanha).toPromise();
  }

  public interromperCampanha(id: string) {
    return this.http.put<ResponseData<Campanha>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}/interrompe-campanha`, {}).toPromise();
  }

  public iniciarCampanha(id: string) {
    return this.http.put<ResponseData<Campanha>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}/inicia-campanha`, {}).toPromise();
  }

  public encerrarCampanha(id: string) {
    return this.http.put<ResponseData<Campanha>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}/encerra-campanha`, {}).toPromise();
  }

  public gerarCobranca(id: string) {
    return this.http.put<ResponseData<Campanha>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}/gera-cobranca`, {}).toPromise();
  }

  public baixarBoleto(url: string) {
    return this.http.get(url, { observe: 'response', responseType: "arraybuffer"});
  }

  public verificarCobranca(id: string) {
    return this.http.put<ResponseData<Campanha>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}/verifica-cobranca`, {}).toPromise();
  }

  public recuperarCobrancas(id: string) {
    return this.http.get<ResponseData<any>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}/cobrancas`, {}).toPromise();
  }

  public gerarRelatorioTotalizacaoItem(id: string) {
    return this.http.get(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}/gera-relatorio-totalizacao-item`, { observe: 'response', responseType: "arraybuffer"});
  }

  public gerarRelatorioTotalizacao(id: string) {
    return this.http.get(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}/gera-relatorio-totalizacao`, { observe: 'response', responseType: "arraybuffer"});
  }

  public gerarRelatorioTotalizacaoRemuneracao(id: string) {
    return this.http.get(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}/gera-relatorio-totalizacao-remuneracao`, { observe: 'response', responseType: "arraybuffer"});
  }

  public recuperarTermoAceiteCampanhaPorUsuario(campanhaId: string, campanhaFuncionarioId: string) {
    return this.http.get(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${campanhaId}/campanhas-funcionarios/${campanhaFuncionarioId}/gera-termo-aceite`, { observe: 'response', responseType: "arraybuffer"});
  }

  public aceitarTermo(campanhaId: string, campanhaFuncionarioId: string) {
    return this.http.put(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${campanhaId}/campanhas-funcionarios/${campanhaFuncionarioId}/aceita-termo`, {});
  }

  public recusarTermo(campanhaId: string, campanhaFuncionarioId: string) {
    return this.http.put(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${campanhaId}/campanhas-funcionarios/${campanhaFuncionarioId}/recusa-termo`, {});
  }

  public deletar(id: any) {
    return this.http.delete<any>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${id}`).toPromise();
  }

  public listarAutoComplete(contem: string, empresaId: string) {
    return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/combobox`, { params: { conteudoPesquisa: contem, empresaId: empresaId } }).toPromise();
  }

  retirarSaldo(idCampanha: string, campanhaFuncionarioId: string) {
    return this.http.put<ResponseData<CampanhaFuncionarioSaldo>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/retira-saldo/${campanhaFuncionarioId}`, {}).toPromise();
}
}
