import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BENEFICIO_ADDRESS, BENEFICIO_URI } from '../app.config';
import { QueryParamsService } from '../comum/services/queryParams.service';
import { ResponseData } from '../comum/models/responseData';
import { CampanhaEmpresa } from './models/campanhaEmpresa';
import { InserirCampanhaEmpresaRequest } from './models/inserirCampanhaEmpresaRequest';
import { ComboBoxItem } from '../comum/models/comboBoxItem';

@Injectable({
  providedIn: 'root'
})
export class CampanhaEmpresaService {

    constructor(private readonly http: HttpClient, 
        private queryParamsService: QueryParamsService,
        @Inject(BENEFICIO_URI) private readonly beneficioUri: string,
        @Inject(BENEFICIO_ADDRESS) private readonly beneficioAddress: string) { }

    recuperarPorCampanha(idCampanha: string) {
        return this.http.get<ResponseData<CampanhaEmpresa[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/empresas`);
    }

    incluir(idCampanha: string, inserirCampanhaEmpresaRequest: InserirCampanhaEmpresaRequest) {
        return this.http.post<ResponseData<CampanhaEmpresa>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/empresas`, inserirCampanhaEmpresaRequest);
    }

    excluir(idCampanha: string, idCampanhaEmpresa: string) {
        return this.http.delete(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/empresas/${idCampanhaEmpresa}`);
    }

    public listarAutoComplete(idCampanha: string, contem: string) {
        return this.http.get<ResponseData<ComboBoxItem[]>>(`${this.beneficioAddress}${this.beneficioUri}/remuneracao/campanhas/${idCampanha}/empresas/combobox`, {params: {conteudoPesquisa : contem}}).toPromise();
    }
}
