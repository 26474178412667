<mat-toolbar fxLayoutGap="20px" class="mat-toolbar-no-color">
    <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon>phonelink_lock</mat-icon>
        <span class="p6n-action-bar-title">Nova senha</span>
    </div>
</mat-toolbar>
<form [formGroup]="this.senhaForm" fxLayout="column">
    <mat-form-field fxFlex="100">
        <input matInput type="password" placeholder="Senha antiga" formControlName="senhaAntiga">
    </mat-form-field>
    <mat-form-field fxFlex="100">
        <input matInput type="password" placeholder="Nova senha" formControlName="senhaNova">
    </mat-form-field>
    <mat-form-field fxFlex="100">
        <input matInput type="password" placeholder="Confirmação de senha" formControlName="confirmacaoSenha">
    </mat-form-field>
</form>
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <button mat-button (click)="this.cancelar()">
        Cancelar</button>
    <button mat-button color="primary" [disabled]="this.senhaForm.invalid" (click)="this.alterarSenha()">
        Alterar senha</button>
</div>