import { EnumValue } from "src/app/comum/models/enumValue";
import { ContratanteSessaoModel } from "./ContratanteSessaoModel";

export class UsuarioModel {
    constructor(public id:string,
        public email:string,
        public nome:string,
        public sobrenome:string,
        public ativo:boolean,
        public token:string,
        public telefone:string,
        public cpf:string,
        public expiracaoTokenCodeSms: Date,
        public expiracaoTokenCodeEmail: Date,
        public expiracao: Date,
        public metodoAutenticacao: number,
        public modalidadeRetirada: number,
        public perfil:string,
        public duplaAutenticacao:boolean,
        public duplaAutenticacaoVerificada:boolean,
        public termoAceitePendente:boolean,
        public termoAceitePendenteId:string,
        public tipoAutenticacao:EnumValue,
        public contratantesSessoes: ContratanteSessaoModel[],
        public menuFixado: boolean) {

    }
}

        