import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Usuario } from 'src/app/usuario/models/usuario';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { TipoEmpresaConst } from 'src/app/comum/consts/tipoEmpresa.const';
import { TipoPessoaConst } from 'src/app/comum/consts/tipoPessoa.const';
import { ViaCepService } from 'src/app/comum/services/viacep.service';
import { Endereco } from 'src/app/comum/models/cep';
import { TipoPessoaEnum } from 'src/app/comum/enuns/tipoPessoa.enum';
import { StatusContaDigitalEnum } from 'src/app/comum/enuns/statusContaDigital.enum';
import { StatusContaDigitalConst } from 'src/app/comum/consts/statusContaDigital.const';
import { DialogComponent } from 'src/app/comum/componentes/upload/dialog/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/usuario/usuario.service';
import { UsuarioDetalhado } from 'src/app/usuario/models/usuarioDetalhado';
import { AuthenticationService } from 'src/app/seguranca/authentication-service';
import { TermoAceiteService } from 'src/app/termo-aceite/termo-aceite.service';
import { TipoTermoAceiteEnum } from 'src/app/comum/enuns/tipoTermoAceite.enum';
import { TermoAceiteDataModel } from 'src/app/termo-aceite/models/termoAceiteDataModel';
import { TermoAceiteAnexoModalComponent } from 'src/app/termo-aceite/termo-aceite-anexo-modal/termo-aceite-anexo-modal.component';
import { UsuarioContaBancariaService } from 'src/app/usuario-conta-bancaria/usuario-conta-bancaria.service';
import { ContaBancaria } from 'src/app/usuario-conta-bancaria/models/contaBancaria';
import { RouterService } from 'src/app/seguranca/router-service';
import { DrawerMenuItemAtual } from 'src/app/comum/componentes/drawer-menu/drawer-menu-item-atual';
import { TipoChavePixEnum } from 'src/app/comum/enuns/tipoChavePix.enum';
import { StatusChavePixConst } from 'src/app/comum/consts/statusChavePix.const';
import { StatusChavePixEnum } from 'src/app/comum/enuns/statusChavePix.enum';
import { LocalStorageService } from 'src/app/comum/services/local-storage.service';
import { NOME_FILTRO_CHAVES_PIX } from 'src/app/app.config';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MessageDialogService } from 'src/app/message-dialog/message-dialog.service';
import { DialogActionYesAndNo, DialogResponseYes } from 'src/app/message-dialog/message-dialog-action';
import {Clipboard} from '@angular/cdk/clipboard';
import { UsuarioContaDigitalService } from '../usuario-conta-digital.service';
import { InserirContaDigitalRequest } from '../models/inserirContaDigitalRequest';

@Component({
    selector: 'app-usuario-conta-digital-senha',
    templateUrl: './usuario-conta-digital-senha.component.html',
    styleUrls: ['./usuario-conta-digital-senha.component.scss']
})
export class UsuarioContaDigitalSenhaComponent implements OnInit {

    senhaEletronicaFormGroup: UntypedFormGroup;
    public viewPassword: boolean;
    public viewConfirmPassword: boolean;

    constructor(
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        public localStorageService: LocalStorageService,
        private ref: MatBottomSheetRef<UsuarioContaDigitalSenhaComponent>,
        private dialogService: MessageDialogService,
        private readonly formBuilder: UntypedFormBuilder,
        private usuarioContaDigitalService: UsuarioContaDigitalService,
        private clipboard: Clipboard) {
    }

    ngOnInit() {
        this.senhaEletronicaFormGroup = this.formBuilder.group({
            senhaEletronica: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
            senhaEletronicaConfirmacao: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
            senhasConferem: [null, [Validators.required, Validators.requiredTrue]]
        });

        this.senhaEletronicaFormGroup
      .get('senhaEletronica')
      .valueChanges
      .pipe()
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }

        if (value == this.senhaEletronicaFormGroup.controls['senhaEletronicaConfirmacao'].value) {
          this.senhaEletronicaFormGroup.controls['senhasConferem'].setValue(true);
        }
        else {
          this.senhaEletronicaFormGroup.controls['senhasConferem'].setValue(false);
        }
      });

    this.senhaEletronicaFormGroup
      .get('senhaEletronicaConfirmacao')
      .valueChanges
      .pipe()
      .subscribe(value => {
        if (!value || typeof (value) !== 'string') {
          return;
        }

        if (value == this.senhaEletronicaFormGroup.controls['senhaEletronica'].value) {
          this.senhaEletronicaFormGroup.controls['senhasConferem'].setValue(true);
        }
        else {
          this.senhaEletronicaFormGroup.controls['senhasConferem'].setValue(false);
        }
      });
    }

    criaContaDigital() {
        this.usuarioContaDigitalService.criaContaDigital().subscribe(response => {
            this.ref.dismiss(response.data);
        });
    }

    fechar(): void {
        this.ref.dismiss();
    }
}
