import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonpClientBackend } from '@angular/common/http';

@Injectable()
export class CnpjSefazService {
  constructor(private http: HttpClient, private jsonp: JsonpClientBackend) { }

  public recuperarCnpjOld(cnpj: string) {
    return this.http.get<any>(`https://receitaws.com.br/v1/cnpj/${cnpj}`).toPromise();
  }

  public recuperarCnpj(cnpj: string) {
    const apiURL = `https://receitaws.com.br/v1/cnpj/${cnpj}?&callback=JSONP_CALLBACK`;
    return this.http.jsonp<any>(apiURL, 'callback').toPromise();
  }
}
