
export class AlterarUsuarioRequest {
    constructor(
        public nome: string,
        public sobrenome: string,
        public nascimento: Date,
        public dddTelefone: number,
        public telefone: number,
        public cep: string,
        public logradouro: string,
        public tipoLogradouro: number,
        public numero: string,
        public complemento: string,
        public bairro: string,
        public tipoBairro: number,
        public codigoMunicipio: number,
        public municipio: string,
        public uf: string,
        public codigoPais: number,
        public pais: string)
    {
    }
}
