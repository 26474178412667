export enum TipoBairroEnum {
    Bairro = 1,
    Bosque = 2,
    Chácara = 3,
    Conjunto = 4,
    Desmembramento = 5,
    Distrito = 6,
    Favela = 7,
    Fazenda = 8,
    Gleba = 9,
    Horto = 10,
    Jardim = 11,
    Loteamento = 12,
    Núcleo = 13,
    Parque = 14,
    Residencial = 15,
    Sítio = 16,
    Tropical = 17,
    Vila = 18,
    Zona = 19,
    Centro = 20,
    Setor = 21,
}
