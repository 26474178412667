import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CampanhaFuncionarioAlterarModalComponent } from './campanha-funcionario-alterar-modal/campanha-funcionario-alterar-modal.component';
import { CampanhaFuncionarioListaComponent } from './campanha-funcionario-lista/campanha-funcionario-lista.component';
import { CampanhaFuncionarioNovoModalComponent } from './campanha-funcionario-novo-modal/campanha-funcionario-novo-modal.component';
import { CampanhaFuncionarioService } from './campanha-funcionario.service';

@NgModule({
    declarations: [CampanhaFuncionarioListaComponent, CampanhaFuncionarioAlterarModalComponent, CampanhaFuncionarioNovoModalComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatInputModule,
        FlexLayoutModule,
        MatTooltipModule,
        MatToolbarModule,
        MatMenuModule,
        CurrencyMaskModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule
    ],
    providers: [
        CampanhaFuncionarioService
    ],
    exports: [CampanhaFuncionarioListaComponent, CampanhaFuncionarioNovoModalComponent, CampanhaFuncionarioAlterarModalComponent]
})
export class CampanhaFuncionarioModule { }
