
export class AlterarCampanhaFuncionarioMetaRequest {
    constructor(
        public tipoMeta: number,
        public meta: number,
        public metaMinima: number)
    {

    }
}

        