
export class InserirUnidadeMedidaRequest {
    constructor(
    public nome: string,
    public abreviacao: string,
    public empresaId: string)
    {

    }
}
