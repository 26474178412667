import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlterarCampanhaFuncionarioMetaRequest } from '../models/alterarCampanhaFuncionarioMetaRequest';
import { CampanhaFuncionarioMeta } from '../models/campanhaFuncionarioMeta';
import { CampanhaFuncionarioMetaService } from '../campanha-funcionario-meta.service';
import { TipoMetaEnum } from 'src/app/comum/enuns/tipoMeta.enum';
import { TipoMetaConst } from 'src/app/comum/consts/tipoMeta.const';
import { ComboBoxItem } from 'src/app/comum/models/comboBoxItem';
import { CampanhaFuncionarioMetaAgrupada } from '../models/campanhaFuncionarioMetaAgrupada';

@Component({
    selector: 'app-campanha-funcionario-meta-alterar-modal',
    templateUrl: './campanha-funcionario-meta-alterar-modal.component.html',
    styleUrls: ['./campanha-funcionario-meta-alterar-modal.component.scss']
})
export class CampanhaFuncionarioMetaAlterarModalComponent implements OnInit {
    campanhaFuncionarioMetaForm: UntypedFormGroup;
    campanhaFuncionarioMetaAgrupada: CampanhaFuncionarioMetaAgrupada;
    tiposMetas: Array<object>;
    campanhasFuncionarios: ComboBoxItem[];
    remuneracoes: ComboBoxItem[];

    constructor(private readonly formBuilder: UntypedFormBuilder,
        private readonly campanhaFuncionarioMetaService: CampanhaFuncionarioMetaService,
        @Inject(MAT_DIALOG_DATA) private readonly data: CampanhaFuncionarioMetaAgrupada,
        private readonly ref: MatDialogRef<CampanhaFuncionarioMetaAlterarModalComponent>,
        private dialog: MatDialog) {
        this.campanhaFuncionarioMetaForm = this.formBuilder.group({
            id: [null, [Validators.required]],
            tipoMeta: [null, [Validators.required]],
            meta: [null, [Validators.required]],
            metaMinima: [null, [Validators.required]],
            campanhaRemuneracao: [{ disabled: true, value: null }, [Validators.required]],
            campanhaFuncionario: [{ disabled: true, value: null }, [Validators.required]]
        });
        this.campanhaFuncionarioMetaAgrupada = this.data;
        this.tiposMetas = TipoMetaConst;
    }

    ngOnInit() {
        this.atribuirValores(this.data);
    }

    salvar() {
        let alterarCampanhaFuncionarioMetaRequest = new AlterarCampanhaFuncionarioMetaRequest(
            this.campanhaFuncionarioMetaForm.value.tipoMeta, this.campanhaFuncionarioMetaForm.value.meta,
            this.campanhaFuncionarioMetaForm.value.metaMinima);

        this.campanhaFuncionarioMetaService
            .alterar(this.campanhaFuncionarioMetaAgrupada.campanhaId, this.campanhaFuncionarioMetaAgrupada.campanhaFuncionarioId, this.campanhaFuncionarioMetaAgrupada.campanhaFuncionarioMetaId, alterarCampanhaFuncionarioMetaRequest)
            .subscribe(response => {
                if (!response) {
                    return;
                }

                if (!response.success) {
                    return;
                }

                this.ref.close(true);
            });
    }


    atribuirValores(campanhaFuncionarioMetaAgrupada: CampanhaFuncionarioMetaAgrupada) {
        if (campanhaFuncionarioMetaAgrupada) {
            this.campanhaFuncionarioMetaForm.controls['id'].setValue(campanhaFuncionarioMetaAgrupada.campanhaFuncionarioId);
            this.campanhaFuncionarioMetaForm.controls['tipoMeta'].setValue(TipoMetaEnum[campanhaFuncionarioMetaAgrupada.tipoMeta.value]);
            this.campanhaFuncionarioMetaForm.controls['meta'].setValue(campanhaFuncionarioMetaAgrupada.meta);
            this.campanhaFuncionarioMetaForm.controls['metaMinima'].setValue(campanhaFuncionarioMetaAgrupada.metaMinima);
            this.campanhaFuncionarioMetaForm.controls['campanhaFuncionario'].setValue({ id: campanhaFuncionarioMetaAgrupada.campanhaFuncionarioId, descricao: campanhaFuncionarioMetaAgrupada.funcionarioNome });
            this.campanhaFuncionarioMetaForm.controls['campanhaRemuneracao'].setValue({ id: campanhaFuncionarioMetaAgrupada.campanhaRemuneracaoId, descricao: campanhaFuncionarioMetaAgrupada.campanhaRemuneracaoNome });
        }
    }

    displayFn(option: any): string {
        if (option === null || option === undefined) {
            return;
        }
        if (option.valor === undefined) {
            return option.descricao;
        }
        return option.valor;
    }
}
