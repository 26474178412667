export const StatusCampanhaConst =
[
    { descricao: "Rascunho", value: 1},
    { descricao: "Em execução", value: 2},
    { descricao: "Interrompida", value: 3},
    { descricao: "Encerrando", value: 4},
    { descricao: "Totalizada", value: 5},
    { descricao: "Aguardando fundos", value: 6},
    { descricao: "Aguardando liquidação", value: 7},
    { descricao: "Liquidando", value: 8},
    { descricao: "Finalizada", value: 9},
    { descricao: "Cancelada", value: 99},
];
