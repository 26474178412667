
export class InserirUsuarioContaBancariaRequest {
    constructor(
        public codigoBanco: string,
        public agencia: string,
        public agenciaDigito: string,
        public conta: string,
        public contaDigito: string,
        public tipoContaBancaria: number,
        public contaTerceiros: boolean,
        public nomeTitular: string,
        public cpfCnpj: string
    ) {

    }
}


